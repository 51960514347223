import { IconSvgProps } from './svgtype';

const CloseRounded = ({ color, ...props }: IconSvgProps) => (
  <svg width={30} height={30} fill='none' {...props}>
    <path
      stroke={color}
      strokeWidth={1.5}
      d='M5 15c0-4.714 0-7.071 1.464-8.536C7.93 5 10.286 5 15 5c4.714 0 7.071 0 8.535 1.464C25 7.93 25 10.286 25 15c0 4.714 0 7.071-1.465 8.535C22.072 25 19.714 25 15 25s-7.071 0-8.536-1.465C5 22.072 5 19.714 5 15Z'
    />
    <path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='m17.5 12.5-5 5m0-5 5 5' />
  </svg>
);

export { CloseRounded };
