import { IconSvgProps } from './svgtype';

const HamburgerMenu = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={2}
      d='M24.533 10.667H7.467M24.533 16H7.467M24.533 21.333H7.467'
    />
  </svg>
);

export { HamburgerMenu };
