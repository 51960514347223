import { embed } from '@dintero/checkout-web-sdk';
import { NoddiCircularLoader } from 'noddi-ui';
import { useEffect, useRef, useState } from 'react';

export default function PaymentForm({ sessionId }: { sessionId: string }) {
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setIsLoading(true);
    embed({
      //@ts-ignore
      container: ref.current,
      sid: sessionId
    }).then(() => {
      setIsLoading(false);
      // Scroll to the embedded container once loading is complete
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    });
  }, []);

  return (
    <div ref={ref} style={{ marginTop: 24 }}>
      {isLoading && <NoddiCircularLoader />}
    </div>
  );
}
