import { IconSvgProps } from './svgtype';

const MenuDots = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} fill='none' {...props}>
    <circle cx={8.533} cy={16} r={2.133} stroke={color} strokeWidth={1.5} />
    <circle cx={16} cy={16} r={2.133} stroke={color} strokeWidth={1.5} />
    <circle cx={23.467} cy={16} r={2.133} stroke={color} strokeWidth={1.5} />
  </svg>
);
export { MenuDots };
