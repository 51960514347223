import { useAuthContext } from 'noddi-provider';
import { BookingTimeWindowPicker, ErrorPage } from 'noddi-ui';
import { getTomorrow } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import { useBookingContext } from '../../contexts/BookingContext';

interface TimeWindowPickerProps {
  isPublicBookingFlow?: boolean;
}
const TimeWindowPicker = ({ isPublicBookingFlow }: TimeWindowPickerProps) => {
  const { bookingInputData: inputData, salesItems, updateBookingInputData } = useBookingContext();
  const navigate = useNavigate();

  const { isSuperUser } = useAuthContext();

  const { serviceAreaId } = inputData;

  if (!serviceAreaId) {
    return <ErrorPage />;
  }

  const navigateToSummaryStep = () => {
    setTimeout(() => {
      navigate(routes.newBookingSummaryStep.getPath());
    }, 250);
  };

  return (
    <BookingTimeWindowPicker
      serviceAreaId={serviceAreaId}
      salesItemIds={salesItems.map((salesItem) => salesItem.id)}
      initialFromDate={getTomorrow() ?? new Date()}
      onUnavailableSelect={(timeWindow) => {
        if (!isSuperUser) {
          return;
        }
        updateBookingInputData({
          time: timeWindow
        });

        isPublicBookingFlow && navigateToSummaryStep();
      }}
      selectedTimeWindowId={inputData.time?.id}
      onAvailableSelect={(timeWindow) => {
        updateBookingInputData({
          time: timeWindow
        });

        isPublicBookingFlow && navigateToSummaryStep();
      }}
    />
  );
};

export default TimeWindowPicker;
