import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { RegisteredCar } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardIndent = styled.div`
  background-color: ${colors.primary.darkPurple30};
  width: 13px;
  height: 45px;
  border-radius: 1rem;
`;

const CarCard = (props: { car: RegisteredCar }) => {
  const { car } = props;

  return (
    <Link to={`${car.id}`} style={{ textDecoration: 'none' }}>
      <Card>
        <Stack direction='row' padding={2} justifyContent='space-between' gap={4}>
          <Stack alignItems='center' direction='row' spacing={2}>
            <CardIndent />
            <Box paddingY={0.5}>
              <Typography variant='h6'>
                {car.make} {car.model} {car.alias && ` (${car.alias})`}
              </Typography>
              {car.licensePlateNumber && (
                <Typography color='text.secondary' gutterBottom variant='body2'>
                  {car.licensePlateNumber}
                </Typography>
              )}
            </Box>
          </Stack>
          <IconButton>
            <NoddiIcon name='AltArrowRight' size='medium' />
          </IconButton>
        </Stack>
      </Card>
    </Link>
  );
};

export default CarCard;
