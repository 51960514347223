import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import CarSelector from './CarSelector';

const Car = () => {
  const navigate = useNavigate();
  const { carStepOk } = useStepValidator();
  const enableNextButton = carStepOk();

  function onNextButtonClicked() {
    navigate(routes.newBookingServicesStep.getPath());
  }

  return (
    <BookingScreen
      onNextClicked={onNextButtonClicked}
      disableNextButton={!enableNextButton}
      title={t`Add at least one car`}
    >
      <CarSelector />
    </BookingScreen>
  );
};

export default Car;
