import { URLKeys, getAllCachedDataFromUrlKey, noddiAsync } from 'noddi-async';
import { AvailableSalesItem } from 'noddi-async/src/types';

import { useBookingContext } from '../contexts/BookingContext';
import { SelectedSalesItem } from '../pages/BookingFlow/interfaces';

export const useSalesItems = () => {
  const { salesItems: selectedSalesItems } = useBookingContext();

  const getSelectedSalesItem = (selectedSalesItem: SelectedSalesItem) => {
    return noddiAsync.useGet({
      type: selectedSalesItem.urlKey,
      input: selectedSalesItem.queryInput,
      queryConfig: {
        staleTime: Infinity,
        select: (data) => {
          return data.find((item) => item.id === selectedSalesItem.id);
        }
      }
    });
  };

  const getAllAvailableSalesItemsOrUndefined = () => {
    const allGeneralItems = getAllCachedDataFromUrlKey<AvailableSalesItem>({
      urlKey: URLKeys.getSalesItemsForBooking
    });
    const specificWheelStorageItems = getAllCachedDataFromUrlKey<AvailableSalesItem>({
      urlKey: URLKeys.getWheelStorageSalesItemsOptions
    });

    const addons = getAllCachedDataFromUrlKey<AvailableSalesItem>({
      urlKey: URLKeys.getAddonsForBooking
    });

    const wheelStorageUnique = specificWheelStorageItems.filter(
      (item, index, self) => self.findIndex((i) => i.id === item.id) === index
    );

    const generalUnique = allGeneralItems.filter(
      (item, index, self) => self.findIndex((i) => i.id === item.id) === index
    );

    const addonsUnique = addons.filter((item, index, self) => self.findIndex((i) => i.id === item.id) === index);

    // also make sure that we don't have duplicates here, even though we should'nt
    return wheelStorageUnique
      .concat(generalUnique)
      .concat(addonsUnique)
      .filter((item, index, self) => self.findIndex((i) => i.id === item.id) === index);
  };

  /**
   * only does a read from the cache, which is not persisted in session storage. Make sure that whenever this is
   * used that the salesItems are already in the cache - otherwise we will have broken references in
   * session storage to sales items where we don't have data.
   */
  // This has caused some bugs where data was lacking in bookingflow on refresh, fix was to use state directly from context/session storage instead.
  const getSelectedSalesItemsFromCache = () => {
    const allSalesItems = getAllAvailableSalesItemsOrUndefined();

    if (!allSalesItems || allSalesItems.length === 0) {
      return [];
    }

    return selectedSalesItems
      .filter((item) => allSalesItems.find((i) => i.id === item.id))
      .map((item) => {
        const selectedSalesItem = allSalesItems.find((i) => i.id === item.id);

        if (!selectedSalesItem) {
          throw new Error('Could not find selected sales item, which cannot happen');
        }
        return {
          ...item,
          ...selectedSalesItem
        };
      });
  };

  const getSelectedSalesItemFromCache = (selectedSalesItem: SelectedSalesItem) => {
    const allSelected = getSelectedSalesItemsFromCache();
    return allSelected.find((item) => item.id === selectedSalesItem.id);
  };

  return {
    getSelectedSalesItem,
    getSelectedSalesItemFromCache,
    getSelectedSalesItemsFromCache,
    getAllAvailableSalesItemsOrUndefined
  };
};
