import { Trans } from '@lingui/macro';
// eslint-disable-next-line
import { groupBy } from 'lodash';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui';
import { Fragment } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { SelectedCar } from '../../interfaces';
import { ServiceCategoryBlock } from '../Services';
import {
  SERVICE_CATEGORY_TYPE,
  getNumberOfSelectedSalesItems,
  serviceLabelToServiceEnum,
  translateSalesCategories
} from './utils';

type Props = {
  car: SelectedCar;
  availableSalesItems: AvailableSalesItem[];
};

const SalesItemCar = ({ car, availableSalesItems }: Props) => {
  const { salesItems: selectedSalesItems } = useBookingContext();

  const salesCategories = groupBy(availableSalesItems, 'category.slug');

  return (
    <div>
      <h6 className='text-bold mb-10 flex w-full gap-2 text-5'>
        <NoddiIcon name='Car' size='large' />
        {car.carName} - {car.licensePlateNumber}
      </h6>

      {Object.keys(salesCategories)?.length > 0 ? (
        <div className='flex flex-col gap-4'>
          {Object.entries(salesCategories).map(([category, salesItems]) => {
            const priceFrom = salesItems.reduce((acc, item) => Math.min(acc, item.price), Infinity);
            return (
              <Fragment key={category}>
                <ServiceCategoryBlock
                  type={serviceLabelToServiceEnum(category)}
                  serviceName={translateSalesCategories(category)}
                  priceFrom={priceFrom}
                  car={car}
                  isPopular={serviceLabelToServiceEnum(category) === SERVICE_CATEGORY_TYPE.WHEEL_SERVICES}
                  salesItems={salesItems}
                  selectedItemsCount={getNumberOfSelectedSalesItems({
                    selectedSalesItems,
                    category,
                    salesItems,
                    licensePlateNumber: car.licensePlateNumber
                  })}
                  isAddon={false}
                />
              </Fragment>
            );
          })}
        </div>
      ) : (
        <p className='mt-2'>
          <Trans>Unfortunately, we do not offer any services for this type of car</Trans>
        </p>
      )}
    </div>
  );
};

export default SalesItemCar;
