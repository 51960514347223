import { Trans } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { NoddiAsyncError, NoddiButton } from 'noddi-ui';

interface CancelDialogProps {
  open: boolean;
  onClose: () => void;
  bookingIdNumber: number;
  cancelBooking: UseMutateAsyncFunction<
    undefined,
    NoddiAsyncError,
    {
      bookingId: number;
    },
    unknown
  >;
}

export const CancelDialog = ({ open, onClose, cancelBooking, bookingIdNumber }: CancelDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='xs'>
      <DialogContent>
        <h6 className='text-5 font-semibold'>
          <Trans>Are you sure you want to cancel the agreement?</Trans>
        </h6>

        <DialogActions>
          <div className='mt-8 flex gap-3'>
            <NoddiButton
              onClick={() => {
                cancelBooking({
                  bookingId: bookingIdNumber
                });
                handleClose();
              }}
            >
              <Trans>Yes</Trans>
            </NoddiButton>
            <NoddiButton variant='secondary' onClick={handleClose}>
              <Trans>No</Trans>
            </NoddiButton>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
