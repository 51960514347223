import { Trans } from '@lingui/macro';
import { Grid } from '@mui/material';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { getServiceCategoryTranslations } from './utils';

type UnavailableServiceCategoryProps = {
  serviceCategoryName: string;
};

export const UnavailableServiceCategory = ({ serviceCategoryName }: UnavailableServiceCategoryProps) => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategoryName });
  return (
    <Grid item xs={12} md={4}>
      <div className='mt-2 flex flex-wrap items-center'>
        <NoddiIcon name='Sad' color={colors.primary.black} />
        <span className='ml-2 text-4 sm:text-6'>{data.name}</span>
      </div>
      <p className='ml-10 text-3 sm:text-3.5'>
        <Trans>We do not offer this service at this address</Trans>
      </p>
    </Grid>
  );
};
