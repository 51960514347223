import { storage } from 'noddi-util';
import { Dispatch, SetStateAction } from 'react';

type SupportedLocales = Record<string, LanguageDetails>;

type LanguageDetails = {
  label: string;
  flag: string;
};

type LoadLocalesProps = {
  setIsLanguageLoaded: Dispatch<SetStateAction<boolean>>;
  defaultLanguage?: 'en' | 'nb';
  dynamicActivateLocale: (locale: string) => Promise<void>;
  supportedLocales: SupportedLocales;
};

export const localeStorageLocaleKey = 'locale';

export async function initLocales({
  setIsLanguageLoaded,
  defaultLanguage = 'en',
  dynamicActivateLocale,
  supportedLocales
}: LoadLocalesProps) {
  const locale = storage.local.getItem<string>(localeStorageLocaleKey) || defaultLanguage;

  // set default locale if locale is not supported
  if (!Object.keys(supportedLocales).includes(locale)) {
    storage.local.setItem(localeStorageLocaleKey, defaultLanguage);
    return dynamicActivateLocale(defaultLanguage).then(() => setIsLanguageLoaded(true));
  }

  return dynamicActivateLocale(locale).then(() => setIsLanguageLoaded(true));
}
