import { useIsMobile } from 'noddi-ui';
import { Outlet, useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import { CustomerBookingHeaderNav } from './CustomerBookingHeaderNav';

export const PublicRoutesLayout = () => {
  const { pathname } = useLocation();
  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());
  const isMobile = useIsMobile();

  const applyTimePickerMobileGradient = isTimePickerStep && isMobile;

  return (
    <div
      className={`min-h-screen ${applyTimePickerMobileGradient ? 'bg-pinto-to-white-gradient' : 'bg-pint-to-lighterPint-gradient'}`}
    >
      <CustomerBookingHeaderNav /> <Outlet />
    </div>
  );
};
