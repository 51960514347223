import { t } from '@lingui/macro';
import { Grid } from '@mui/material';
import { ServiceCategory } from 'noddi-async/src/types';
import { formatNorwegianCurrencyAmount } from 'noddi-util';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { getServiceCategoryIcon, getServiceCategoryTranslations } from './utils';

type Props = {
  serviceCategory: ServiceCategory;
};

export const AvailableServiceCategory = ({ serviceCategory }: Props): React.ReactElement => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategory.name });
  const iconName = getServiceCategoryIcon({ serviceCategoryName: serviceCategory.name });

  return (
    <Grid item xs={12} md={4}>
      <div className='mt-2 flex flex-wrap items-center'>
        <NoddiIcon name={iconName} color={colors.primary.black} size='large' />
        <span className='ml-2 text-4 sm:text-6'>{data.name}</span>
      </div>
      <span className='ml-10 text-3 sm:text-3.5'>
        {t`from`} {formatNorwegianCurrencyAmount(serviceCategory.price, 0)}
      </span>
    </Grid>
  );
};
