import { useEffect, useRef } from 'react';

export const useScrollIntoView = (shouldScroll: boolean) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (shouldScroll && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [shouldScroll]); // Effect will run when shouldScroll changes

  return elementRef; // Return the ref to be attached to the element
};
