/* eslint-disable no-prototype-builtins */
import { AvailableBookingTimeWindowsByDateNew } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

export const getUniqueTimeSlots = (data: AvailableBookingTimeWindowsByDateNew): string[] => {
  const timeSlotsSet = new Set<string>();

  for (const date in data) {
    if (data.hasOwnProperty(date)) {
      const slots = data[date];
      for (const slot in slots) {
        if (slots.hasOwnProperty(slot)) {
          // Extract start and end times from the slot string
          const start = slot.slice(0, 5); // "13:00"
          const end = slot.slice(5); // "16:00"

          const today = new Date();
          const startUtc = new Date(`${today.toISOString().slice(0, 10)}T${start}:00Z`);
          const endUtc = new Date(`${today.toISOString().slice(0, 10)}T${end}:00Z`);

          const startLocal = format(startUtc, DateFormats.HOURS);
          const endLocal = format(endUtc, DateFormats.HOURS);

          // Format the time slot as required (e.g., "13-16")
          const formattedSlot = `${startLocal.slice(0, 2)}-${endLocal.slice(0, 2)}`;

          const hasData = checkIfSlotHasData(data, slot);

          if (hasData) {
            timeSlotsSet.add(formattedSlot);
          }
        }
      }
    }
  }

  // Convert the Set to an Array and return
  return Array.from(timeSlotsSet);
};

export const checkIfSlotHasData = (input: AvailableBookingTimeWindowsByDateNew, slot: string): boolean => {
  for (const date in input) {
    if (input.hasOwnProperty(date)) {
      const dateTimeWindows = input[date];
      if (dateTimeWindows && dateTimeWindows.hasOwnProperty(slot) && dateTimeWindows[slot] !== null) {
        return true;
      }
    }
  }
  return false;
};

export const parseTimeWindowData = (input: AvailableBookingTimeWindowsByDateNew) => {
  const result = [];

  for (const date in input) {
    if (input.hasOwnProperty(date)) {
      const timeWindows = [];

      // Extract year, month, and day from the string
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);

      // Format it as "YYYY-MM-DD"
      const formattedDate = `${year}-${month}-${day}`;

      // Ensure that date exists in the input
      const dateTimeWindows = input[date];
      if (dateTimeWindows) {
        for (const timeWindow in dateTimeWindows) {
          const hasData = checkIfSlotHasData(input, timeWindow);

          if (hasData) {
            if (dateTimeWindows.hasOwnProperty(timeWindow)) {
              timeWindows.push(dateTimeWindows[timeWindow] ?? null); // Use nullish coalescing to handle undefined
            }
          }
        }
      }

      result.push({
        date: formattedDate,
        timeWindows: timeWindows
      });
    }
  }

  return result;
};
