import { t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { NoddiIcon, colors } from 'noddi-ui';
import { NavItem } from 'noddi-ui/src/types/navItem';

import routes from '../appRoutes';

const useNavItems = () => {
  const { hasCoupons, hasMemberships, hasTireHotel } = useAuthContext();

  const navItems: NavItem[] = [
    {
      items: [
        {
          path: routes.homePageBookings.getPath(),
          title: t`Bookings`,
          icon: <NoddiIcon name='Calendar' color={colors.primary.white} size='medium' />
        }
      ]
    },
    {
      items: [
        {
          path: routes.myCars.getPath(),
          title: t`Cars`,
          icon: <NoddiIcon name='Car' size='medium' color={colors.primary.white} />
        }
      ]
    },
    {
      items: [
        {
          path: routes.myAddresses.getPath(),
          title: t`Addresses`,
          icon: <NoddiIcon name='LocationPin' size='medium' color={colors.primary.white} />
        }
      ]
    },
    {
      items: [
        {
          path: routes.myReferrals.getPath(),
          title: t`Referrals`,
          icon: <NoddiIcon size='medium' color={colors.primary.white} name='Users' />
        }
      ]
    },
    {
      items: [
        {
          path: routes.myCoupons.getPath(),
          title: t`Coupons`,
          icon: <NoddiIcon size='medium' color={colors.primary.white} name='Bill' />,
          shouldShow: () => hasCoupons ?? false
        }
      ]
    },
    {
      items: [
        {
          path: routes.myMembershipPrograms.getPath(),
          title: t`Membership`,
          icon: <NoddiIcon name='Star' size='medium' color={colors.primary.white} />,
          shouldShow: () => hasMemberships ?? false
        }
      ]
    },
    {
      items: [
        {
          path: routes.tireStorage.getPath(),
          title: t`Tire hotel`,
          icon: <NoddiIcon name='Home' size='medium' color={colors.primary.white} />,
          shouldShow: () => hasTireHotel ?? false
        }
      ]
    }
  ];

  const getNavItems = () => navItems;

  return { getNavItems };
};

export default useNavItems;
