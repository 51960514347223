/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import queryString from 'query-string';
import { Nullable } from 'vitest';

import { ServerTypes } from '..';
import { InputDataOrVoid } from '../NoddiAsync';
import {
  AddressProps,
  Availability,
  AvailabilityUpdateInput,
  AvailableBookingTimeWindowsByDateNew,
  AvailableBookingTimeWindowsInput,
  AvailableSalesItem,
  BookingListItem,
  BookingListItemWithSalesItems,
  BookingMessage,
  BookingMessageCreateInput,
  BookingProps,
  BookingReceipt,
  BookingStatusDto,
  CapacityContribution,
  CapacityContributionDetails,
  CapacityDay,
  CarDetailType,
  CarGeneration,
  CarGenerationCreateInput,
  CarGenerationListInput,
  CarGenerationListItem,
  CarGenerationUpdateInput,
  CarListItem,
  CarMake,
  CarMakeCreateInput,
  CarMakeListItem,
  CarMakeUpdateInput,
  CarModel,
  CarModelCreateInput,
  CarModelListItem,
  CarModelUpdateInput,
  CarWheelPosition,
  CarWheelSetSpecifications,
  CarWheelSetSpecificationsBulkUpdateInput,
  CarWheelSetSpecificationsCreateInput,
  CarWheelSetSpecificationsListInput,
  CarWheelSetSpecificationsUpdateInput,
  CarWheelSetType,
  Competence,
  CompetencePatchInput,
  CompetencePostInput,
  ContactPerson,
  ContactPersonAvailability,
  ContactPersonAvailabilityCreateInput,
  ContactPersonCreateInput,
  ContactPersonDepartmentAddress,
  ContactPersonDepartmentAddressCreateInput,
  ContactPersonListItem,
  ContactPersonUpdateInput,
  CreateBookingParams,
  CreateCapacityContributionDto,
  EditBookingPermissions,
  EditBookingTimeWindowData,
  GetSalesItemForBooking,
  GroupAdminType,
  Invoice,
  InvoiceCreateInput,
  InvoiceListItem,
  InvoiceUpdateInput,
  Language,
  LicenseAreaListItem,
  LicensePlateNumberData,
  ListAvailabilitiesInput,
  ListContactPersonDepartmentAddressInput,
  ListContactPersonInput,
  MembershipDiscount,
  MembershipDiscountCreateInput,
  MembershipDiscountListItem,
  MembershipDiscountUpdateInput,
  MembershipProgram,
  MembershipProgramCreateInput,
  MembershipProgramListItem,
  MembershipProgramUpdateInput,
  OptionsTypeString,
  PaginatedInput,
  PaginatedResponse,
  PublicCoupon,
  RouteItem,
  ServiceAreaListItem,
  ServiceAreaWithH3Indexes,
  Skill,
  SkillListItem,
  SkillPatchInput,
  SkillPostInput,
  StorageUnit,
  StorageUnitInput,
  UpdateCapacityContributionDto,
  User,
  UserDataProps,
  UserGroupAddress,
  UserGroupBooking,
  UserGroupMemberNotificationSettings,
  UserGroupType,
  UserGroupsType,
  UserListItem
} from '../types';
import { CarWheelSetToPickUp, CarWheelSetToPickUpInput } from '../types/shared/pickUpList';
import { CarWheelSetForLabelPrint, RouteLabel, RouteLabelInput } from '../types/workerapp/routeLabels';
import { convertObjectKeysToSnakeCase, downloadToExcel } from '../utils';
import {
  AdminExternalServerRequests,
  AdminExternalURLKeys,
  AdminExternalURLKeysType,
  getAdminExternalUrls
} from './adminExternalResources';
import { AdminServerRequests, AdminURLKeys, AdminURLKeysType, getAdminUrls } from './adminResources';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CustomerServerRequests, CustomerURLKeys, CustomerURLKeysType, getCustomerUrls } from './customerResources';
import {
  OrganizationServerRequests,
  OrganizationURLKeys,
  OrganizationURLKeysType,
  getOrganizationUrls
} from './organizationResources';
import { WorkerServerRequests, WorkerURLKeys, WorkerURLKeysType, getWorkerUrls } from './workerResources';

export function getNoddiUrl<Type extends URLKeysTypes>({
  queryParams,
  path
}: {
  queryParams?: Partial<InputData<Type>>;
  path: string;
}): string {
  // filter out empty params
  const filteredQueryParams = Object.entries(queryParams || {}).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>
  );

  const _queryParams = queryString.stringify(convertObjectKeysToSnakeCase(filteredQueryParams || {}), {
    arrayFormat: 'comma'
  });

  const queryParamString = _queryParams ? `?${_queryParams}` : '';

  return `/${path}${queryParamString}`;
}

// Base urls that are used across multiple apps
const BaseUrlKeys = {
  getTimeZones: 'getTimeZones',
  getRouteItemStatusById: 'getRouteItemStatusById',
  getUserGroupMembersNotificationSettings: 'getUserGroupMembersNotificationSettings',
  getUserGroupMemberNotificationSettings: 'getUserGroupMemberNotificationSettings',
  postTokenLogin: 'postTokenLogin',
  getPaginatedLicenseAreas: 'getPaginatedLicenseAreas',
  getPaginatedServiceAreas: 'getPaginatedServiceAreas',
  getExistingServiceAreas: 'getExistingServiceAreas',
  postVerifyPhoneNumber: 'postVerifyPhoneNumber',
  postUser: 'postUser',
  postEmailLogin: 'postEmailLogin',
  getPhoneNumberVerification: 'getPhoneNumberVerification',
  getPaginatedUsers: 'getPaginatedUsers',
  getUser: 'getUser',
  getGroupsOfUser: 'getGroupsOfUser',
  getDataFromLicensePlateNumber: 'getDataFromLicensePlateNumber',
  getSalesItemsForBooking: 'getSalesItemsForBooking',
  getWheelStorageSalesItemsOptions: 'getWheelStorageSalesItemsOptions',
  getAddonsForBooking: 'getAddonsForBooking',
  getUserGroupCoupons: 'getUserGroupCoupons',
  getUserGroupAddresses: 'getUserGroupAddresses',
  postRegisterCar: 'postRegisterCar',
  getPaginatedBookings: 'getPaginatedBookings',
  getBookingMessage: 'getBookingMessage',
  postBookingMessage: 'postBookingMessage',
  postAddUserToUserGroup: 'postAddUserToUserGroup',
  getPaginatedMembershipDiscounts: 'getPaginatedMembershipDiscounts',
  getMembershipDiscount: 'getMembershipDiscount',
  postMembershipDiscount: 'postMembershipDiscount',
  patchMembershipDiscount: 'patchMembershipDiscount',
  deleteMembershipDiscount: 'deleteMembershipDiscount',
  getMembershipProgram: 'getMembershipProgram',
  getPaginatedMembershipPrograms: 'getPaginatedMembershipPrograms',
  postMembershipProgram: 'postMembershipProgram',
  patchMembershipProgram: 'patchMembershipProgram',
  deleteMembershipProgram: 'deleteMembershipProgram',
  getPaginatedDriverName: 'getPaginatedDriverName',
  postBooking: 'postBooking',
  getCar: 'getCar',
  getCarWheelSetTypes: 'getCarWheelSetTypes',
  getCarWheelPositions: 'getCarWheelPositions',
  getAvailableBookingTimeWindowsByDateNew: 'getAvailableBookingTimeWindowsByDateNew',
  getLatestCreatedBookingTimeWindowDate: 'getLatestCreatedBookingTimeWindowDate',
  getLanguages: 'getLanguages',
  postMakeGroupAdmin: 'postMakeGroupAdmin',
  postRemoveUserFromGroup: 'postRemoveUserFromGroup',
  patchUser: 'patchUser',
  getCapacityDays: 'getCapacityDays',
  getLatestCapacityDay: 'getLatestCapacityDay',
  getRegisteredWorkerCapacityContributions: 'getRegisteredWorkerCapacityContributions',
  postCapacityContribution: 'postCapacityContribution',
  patchCapacityContribution: 'patchCapacityContribution',
  getCapacityContribution: 'getCapacityContribution',
  getBookingsForCar: 'getBookingsForCar',
  getNumBookings: 'getNumBookings',
  getBookingsCosts: 'getBookingsCosts',
  patchBookingItem: 'patchBookingItem',
  postCancelBookingItem: 'postCancelBookingItem',
  getBookingStatuses: 'getBookingStatuses',
  getPaginatedUserGroupCars: 'getPaginatedUserGroupCars',
  downloadBookingReceipt: 'downloadBookingReceipt',
  getPaginatedInvoices: 'getPaginatedInvoices',
  getInvoice: 'getInvoice',
  deleteInvoice: 'deleteInvoice',
  postInvoiceToExcel: 'postInvoiceToExcel',
  postInvoice: 'postInvoice',
  patchInvoice: 'patchInvoice',
  getPaginatedCarMakes: 'getPaginatedCarMakes',
  getCarMake: 'getCarMake',
  postCarMake: 'postCarMake',
  patchCarMake: 'patchCarMake',
  getPaginatedCarModels: 'getPaginatedCarModels',
  getPaginatedCarModelsForMake: 'getPaginatedCarModelsForMake',
  getCarModel: 'getCarModel',
  postCarModel: 'postCarModel',
  patchCarModel: 'patchCarModel',
  getPaginatedCarGenerations: 'getPaginatedCarGenerations',
  getPaginatedCarGenerationsForModel: 'getPaginatedCarGenerationsForModel',
  getCarGeneration: 'getCarGeneration',
  postCarGeneration: 'postCarGeneration',
  patchCarGeneration: 'patchCarGeneration',
  getCarGenerationSearchNameApiProviders: 'getCarGenerationSearchNameApiProviders',
  getCarWheelSetSpecifications: 'getCarWheelSetSpecifications',
  postCarWheelSetSpecifications: 'postCarWheelSetSpecifications',
  patchCarWheelSetSpecifications: 'patchCarWheelSetSpecifications',
  patchBulkCarWheelSetSpecifications: 'patchBulkCarWheelSetSpecifications',
  getPaginatedCarWheelSetSpecifications: 'getPaginatedCarWheelSetSpecifications',
  getPaginatedCarWheelSetSpecificationsForGeneration: 'getPaginatedCarWheelSetSpecificationsForGeneration',
  getPaginatedContactPersons: 'getPaginatedContactPersons',
  getContactPerson: 'getContactPerson',
  postContactPerson: 'postContactPerson',
  patchContactPerson: 'patchContactPerson',
  getPaginatedAvailabilities: 'getPaginatedAvailabilities',
  getAvailability: 'getAvailability',
  patchAvailability: 'patchAvailability',
  deleteAvailability: 'deleteAvailability',
  getPaginatedContactPersonDepartmentAddresses: 'getPaginatedContactPersonDepartmentAddresses',
  postContactPersonDepartmentAddress: 'postContactPersonDepartmentAddress',
  postContactPersonAvailability: 'postContactPersonAvailability',
  getPaginatedSkills: 'getPaginatedSkills',
  getSkill: 'getSkill',
  postSkill: 'postSkill',
  patchSkill: 'patchSkill',
  deleteSkill: 'deleteSkill',
  getPaginatedCompetences: 'getPaginatedCompetences',
  getCompetence: 'getCompetence',
  postCompetence: 'postCompetence',
  patchCompetence: 'patchCompetence',
  deleteCompetence: 'deleteCompetence',
  getStorageUnits: 'getStorageUnits',
  getRouteLabels: 'getRouteLabels',
  getPaginatedPrintingLabels: 'getPaginatedPrintingLabels',
  getUserGroupBookings: 'getUserGroupBookings',
  getCarWheelSetToPickUp: 'getCarWheelSetToPickUp',
  getEditBookingPersmissions: 'getEditBookingPersmissions',
  getUpdateBookingTimeWindowData: 'getUpdateBookingTimeWindowData'
} as const;

export const URLKeys = {
  ...BaseUrlKeys,
  ...WorkerURLKeys,
  ...AdminURLKeys,
  ...CustomerURLKeys,
  ...AdminExternalURLKeys,
  ...OrganizationURLKeys
};

type BaseUrlKeysType = keyof typeof BaseUrlKeys;

export type URLKeysTypes =
  | BaseUrlKeysType
  | CustomerURLKeysType
  | AdminURLKeysType
  | WorkerURLKeysType
  | AdminExternalURLKeysType
  | OrganizationURLKeysType;

export type InputData<urlKey extends URLKeysTypes> = ServerRequests[urlKey]['input'];
export type OutputData<urlKey extends URLKeysTypes> = ServerRequests[urlKey]['output'];

export type ReturnType<Type extends URLKeysTypes, _> = OutputData<Type>;

export type CommonAxiosResType<key extends URLKeysTypes> = AxiosResponse<
  OutputData<key>,
  {
    status: number;
    statusText: string;
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
    config: unknown;
    request?: unknown;
  }
>;

export const getUrls: {
  [key in URLKeysTypes]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>> | FormData;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
    skipBodyTransformation?: boolean;
  };
} = {
  getTimeZones: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}time-zones/`
      }),
    method: FetchMethods.get
  },
  getRouteLabels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-label-printing/`,
        queryParams: queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedPrintingLabels: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/for-label-printing/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getRouteItemStatusById: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/status-details/`
      }),
    method: FetchMethods.get
  },
  getUserGroupMembersNotificationSettings: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-group-members/`
      }),
    method: FetchMethods.get
  },
  getUserGroupMemberNotificationSettings: {
    getUrl: ({ userGroupMemberId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-group-members/${userGroupMemberId}`
      }),
    method: FetchMethods.get
  },
  getPaginatedLicenseAreas: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}license-areas/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceAreas: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getExistingServiceAreas: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/existing/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postTokenLogin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}login-token/`
      }),
    getBody: (args) => args,
    getHeaders: ({ token }) => ({
      Authorization: `Token ${token}`
    }),
    method: FetchMethods.post
  },
  postVerifyPhoneNumber: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/verify-phone-number/`
      }),
    getBody: ({ code, phoneNumber }) => ({
      code,
      phoneNumber
    }),
    method: FetchMethods.post
  },
  postUser: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  patchUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${id}`
      }),
    method: FetchMethods.patch,
    getBody: (args) => args
  },
  postEmailLogin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}login/`
      }),
    getBody: ({ email, password }) => ({
      email,
      password
    }),
    method: FetchMethods.post
  },
  getPhoneNumberVerification: {
    getUrl: ({ phoneNumber }) => {
      return getNoddiUrl({
        path: `${apiVersion}users/send-phone-number-verification/?phone_number=${phoneNumber}`
      });
    },
    method: FetchMethods.get
  },
  getPaginatedUsers: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}users/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get,
    useSuperUserToken: true
  },
  getUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${id}`
      }),
    method: FetchMethods.get
  },
  postAddUserToUserGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/add-user-to-group/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getGroupsOfUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/get-user-groups/${id}`
      }),
    method: FetchMethods.get
  },
  getPaginatedCarGenerations: {
    getUrl: ({ page, pageSize, search, bookingFromDate, bookingToDate, isMissingWheelLugNutTorqueNm }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/`,
        queryParams: { page, pageSize, search, bookingFromDate, bookingToDate, isMissingWheelLugNutTorqueNm }
      }),
    method: FetchMethods.get
  },
  getPaginatedCarGenerationsForModel: {
    getUrl: ({ page, pageSize, search, id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/car-generations/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getDataFromLicensePlateNumber: {
    getUrl: ({ licensePlateNumber, countryCode }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/data-from-license-plate-number/`,
        queryParams: { licensePlateNumber, countryCode }
      }),
    method: FetchMethods.get
  },
  getSalesItemsForBooking: {
    getUrl: (params) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/for-booking`,
        queryParams: {
          ...params
        }
      }),
    method: FetchMethods.get
  },
  getWheelStorageSalesItemsOptions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/wheel-storage-options`
      }),
    method: FetchMethods.get
  },
  getAddonsForBooking: {
    getUrl: ({ carSizeIds, salesItemIds }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/addons`,
        queryParams: {
          carSizeIds,
          salesItemIds
        }
      }),
    method: FetchMethods.get
  },
  getUserGroupCoupons: {
    getUrl: ({ id, includeUsed, includeUsedOnBookingIds }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/coupons/`,
        queryParams: {
          includeUsed,
          includeUsedOnBookingIds
        }
      }),
    method: FetchMethods.get
  },
  postRegisterCar: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/cars/`
      }),
    getBody: (props) => {
      const newProps = { ...props };
      delete newProps.userGroupId;
      return newProps;
    },
    method: FetchMethods.post
  },
  getPaginatedBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupBookings: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/bookings/`
      }),
    method: FetchMethods.get
  },
  getBookingMessage: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-messages/${id}`
      }),
    method: FetchMethods.get
  },
  postBookingMessage: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-messages/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getUserGroupAddresses: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/addresses?user_group_id=${userGroupId}`
      }),
    method: FetchMethods.get
  },
  getPaginatedMembershipDiscounts: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}`
      }),
    method: FetchMethods.get
  },
  postMembershipDiscount: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.delete
  },
  getMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}`
      }),
    method: FetchMethods.get
  },
  getPaginatedMembershipPrograms: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  postMembershipProgram: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.delete
  },
  getPaginatedDriverName: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-feedbacks/drivers_name/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  postBooking: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/create/`
      }),
    getBody: ({
      address,
      bookingTimeWindowId,
      userGroupId,
      userId,
      notifications,
      wheelStorage,
      salesItems,
      ...rest
    }) => {
      const commonPayload = {
        address,
        bookingTimeWindowId,
        userGroupId,
        userId,
        notifications,
        wheelStorage,
        salesItems
      };
      if ('comments' in rest) {
        return {
          ...commonPayload,
          comments: rest.comments
        };
      } else {
        return commonPayload;
      }
    },
    useSuperUserToken: true,
    method: FetchMethods.post
  },
  getAvailableBookingTimeWindowsByDateNew: {
    getUrl: (args) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/for-booking/`,
        queryParams: args
      }),
    useSuperUserToken: true,
    method: FetchMethods.get
  },
  getCar: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-types/`
      }),
    method: FetchMethods.get
  },
  getCarWheelPositions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-positions/`
      }),
    method: FetchMethods.get
  },
  patchBookingItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${id}`
      }),
    getHeaders: () => ({
      'Content-Type': 'multipart/form-data'
    }),
    getBody: (args) => args.formData,
    skipBodyTransformation: true,
    method: FetchMethods.patch
  },
  postCancelBookingItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/cancel/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  postMakeGroupAdmin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/change-member-permission/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  postRemoveUserFromGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/remove-user-from-group/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getLatestCreatedBookingTimeWindowDate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/latest-created-date/`
      }),
    method: FetchMethods.get
  },
  getLanguages: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}languages/`
      }),
    method: FetchMethods.get
  },
  getCapacityDays: {
    getUrl: ({ fromDate, toDate }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-days/`,
        queryParams: { fromDate, toDate }
      }),
    method: FetchMethods.get
  },
  getLatestCapacityDay: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-days/latest/`
      }),
    method: FetchMethods.get
  },
  getRegisteredWorkerCapacityContributions: {
    getUrl: ({ id, fromDate }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}/capacity-contributions/`,
        queryParams: { fromDate }
      }),
    method: FetchMethods.get
  },
  postCapacityContribution: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchCapacityContribution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getCapacityContribution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/${id}`
      }),
    method: FetchMethods.get
  },
  getBookingsForCar: {
    getUrl: ({ carId, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${carId}/bookings/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getNumBookings: {
    getUrl: ({ userGroupIds, fromDate, toDate }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/num-bookings/`,
        queryParams: { userGroupIds, fromDate, toDate }
      }),
    method: FetchMethods.get
  },
  getBookingsCosts: {
    getUrl: ({ userGroupIds, fromDate, toDate, vatIncluded }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/costs/`,
        queryParams: { userGroupIds, fromDate, toDate, vatIncluded }
      }),
    method: FetchMethods.get
  },
  getBookingStatuses: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/statuses`
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupCars: {
    getUrl: ({ id, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/cars/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  downloadBookingReceipt: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/download-receipt/`
      }),
    responseType: 'arraybuffer',
    handleRes: async (res) => {
      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition.split('filename=')[1].split(';')[0];

      const bytes = new Uint8Array(res.data);
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      return res.data;
    },
    method: FetchMethods.get
  },
  getPaginatedInvoices: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/`,
        queryParams: queryParams
      }),
    method: FetchMethods.get
  },
  getInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}`
      }),
    method: FetchMethods.get
  },
  deleteInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}`
      }),
    method: FetchMethods.delete
  },
  postInvoiceToExcel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/to-excel`
      }),
    method: FetchMethods.post,
    handleRes: async (res) => downloadToExcel(res),
    responseType: 'arraybuffer'
  },
  postInvoice: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}invoices/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedCarMakes: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCarMake: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarMake: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchCarMake: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedCarModels: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedCarModelsForMake: {
    getUrl: ({ page, pageSize, search, id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/car-models/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCarModel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarModel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-models/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchCarModel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getCarGeneration: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarGeneration: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchCarGeneration: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getCarGenerationSearchNameApiProviders: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-generation-search-names/api-providers/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetSpecifications: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarWheelSetSpecifications: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchCarWheelSetSpecifications: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/${id}/`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  patchBulkCarWheelSetSpecifications: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/bulk-update/`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedCarWheelSetSpecifications: {
    getUrl: ({ page, pageSize, search, bookingFromDate, bookingToDate, isMissingTirePressurePsi }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/`,
        queryParams: {
          page,
          pageSize,
          search,
          bookingFromDate,
          bookingToDate,
          isMissingTirePressurePsi
        }
      }),
    method: FetchMethods.get
  },
  getPaginatedCarWheelSetSpecificationsForGeneration: {
    getUrl: ({ page, pageSize, search, id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/car-wheel-set-specifications/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedContactPersons: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/`,
        queryParams: args
      }),
    method: FetchMethods.get
  },
  getContactPerson: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/${id}`
      }),
    method: FetchMethods.get
  },
  postContactPerson: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchContactPerson: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/${id}/`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedAvailabilities: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/`,
        queryParams: args
      }),
    method: FetchMethods.get
  },
  getAvailability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/${id}`
      }),
    method: FetchMethods.get
  },
  patchAvailability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteAvailability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/${id}`
      }),
    method: FetchMethods.delete
  },
  getPaginatedContactPersonDepartmentAddresses: {
    getUrl: (params) =>
      getNoddiUrl({
        path: `${apiVersion}contact-person-department-addresses/`,
        queryParams: params
      }),
    method: FetchMethods.get
  },
  postContactPersonDepartmentAddress: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}contact-person-department-addresses/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  postContactPersonAvailability: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}contact-person-availabilities/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getPaginatedSkills: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}skills/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}`
      }),
    method: FetchMethods.get
  },
  postSkill: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}skills/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}`
      }),
    method: FetchMethods.delete
  },
  getPaginatedCompetences: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}competences/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getStorageUnits: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCompetence: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}competences/${id}`
      }),
    method: FetchMethods.get
  },
  postCompetence: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}competences/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchCompetence: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}competences/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteCompetence: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}competences/${id}`
      }),
    method: FetchMethods.delete
  },
  getCarWheelSetToPickUp: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/to-pickup`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getEditBookingPersmissions: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/edit-permissions/`
      }),
    method: FetchMethods.get
  },
  getUpdateBookingTimeWindowData: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/update-time-window-data/`
      }),
    method: FetchMethods.get
  },
  ...getAdminUrls,
  ...getCustomerUrls,
  ...getWorkerUrls,
  ...getOrganizationUrls,
  ...getAdminExternalUrls
} as const;

export interface ServerRequests
  extends CustomerServerRequests,
    AdminServerRequests,
    WorkerServerRequests,
    AdminExternalServerRequests,
    OrganizationServerRequests {
  getTimeZones: {
    input: undefined;
    output: string[];
  };
  getRouteItemStatusById: {
    input: {
      routeItemId: number;
    };
    output: RouteItem;
  };
  getUserGroupMembersNotificationSettings: {
    input: undefined;
    output: PaginatedResponse<UserGroupMemberNotificationSettings[]>;
  };
  getUserGroupMemberNotificationSettings: {
    input: { userGroupMemberId?: number | null | undefined };
    output: UserGroupMemberNotificationSettings;
  };
  getPaginatedLicenseAreas: {
    input: PaginatedInput;
    output: PaginatedResponse<LicenseAreaListItem[]>;
  };
  getPaginatedPrintingLabels: {
    input: PaginatedInput;
    output: PaginatedResponse<CarWheelSetForLabelPrint[]>;
  };
  getPaginatedServiceAreas: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceAreaListItem[]>;
  };
  getExistingServiceAreas: {
    input: { excludeIds?: number[] };
    output: ServiceAreaWithH3Indexes[];
  };
  postTokenLogin: {
    input: {
      token: string;
      userGroupsType: UserGroupsType;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postVerifyPhoneNumber: {
    input: {
      code: string;
      phoneNumber: string;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postUser: {
    input: {
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      password?: string;
      confirmPassword?: string;
      isWorker?: boolean;
      startAddress?: AddressProps;
      endAddress?: AddressProps;
      licenseAreaIds?: number[];
      languageCodes?: string[];
    };
    output: AxiosResponse<UserDataProps>;
  };
  patchUser: {
    input: Omit<Partial<User>, 'id'> & { id: number };
    output: User;
  };
  postEmailLogin: {
    input: {
      email: string;
      password: string;
    };
    output: AxiosResponse<UserDataProps>;
  };
  getPhoneNumberVerification: {
    input: {
      phoneNumber: string;
    };
    output: unknown;
  };
  getPaginatedUsers: {
    input: PaginatedInput;
    output: PaginatedResponse<UserListItem[]>;
  };
  getUser: {
    input: { id: number | string | undefined | null };
    output: User;
  };
  postAddUserToUserGroup: {
    input: { userId: number; groupId: number };
    output: { message: string };
  };
  getGroupsOfUser: {
    input: { id: number };
    output: UserGroupType[];
  };
  getPaginatedCarGenerations: {
    input: PaginatedInput & CarGenerationListInput;
    output: PaginatedResponse<CarGenerationListItem[]>;
  };
  getPaginatedCarGenerationsForModel: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarGenerationListItem[]>;
  };
  getDataFromLicensePlateNumber: {
    input: {
      licensePlateNumber: string;
      countryCode: string;
    };
    output: LicensePlateNumberData;
  };
  patchBookingItem: {
    input: { formData: FormData; id: number };
    output: unknown;
  };
  postCancelBookingItem: {
    input: { id: number };
    output: unknown;
  };
  getSalesItemsForBooking: {
    input: GetSalesItemForBooking;
    output: AvailableSalesItem[];
  };
  getWheelStorageSalesItemsOptions: {
    input: undefined;
    output: AvailableSalesItem[];
  };
  getAddonsForBooking: {
    input: {
      carSizeIds: number[];
      salesItemIds: number[];
    };
    output: AvailableSalesItem[];
  };
  getUserGroupCoupons: {
    input: {
      id: number;
      includeUsed?: boolean;
      includeUsedOnBookingIds: number[];
    };
    output: PublicCoupon[];
  };
  postRegisterCar: {
    input: LicensePlateNumberData & {
      userGroupId: number | undefined | null;
      departmentId?: number;
    };
    output: unknown;
  };
  getPaginatedBookings: {
    input: PaginatedInput & {
      userGroupId?: string;
      fromDate?: string | null;
      toDate?: string | null;
      invoiceId?: number | null;
    };
    output: PaginatedResponse<BookingListItem[]>;
  };
  getBookingMessage: {
    input: {
      id: number;
    };
    output: BookingMessage;
  };
  postBookingMessage: {
    input: BookingMessageCreateInput;
    output: BookingMessage;
  };
  getUserGroupAddresses: {
    input: {
      userGroupId: number;
    };
    output: UserGroupAddress[];
  };
  getPaginatedMembershipDiscounts: {
    input: PaginatedInput;
    output: PaginatedResponse<MembershipDiscountListItem[]>;
  };
  getMembershipDiscount: {
    input: { id: number };
    output: MembershipDiscount;
  };
  postMembershipDiscount: {
    input: MembershipDiscountCreateInput;
    output: MembershipDiscount;
  };
  patchMembershipDiscount: {
    input: MembershipDiscountUpdateInput;
    output: MembershipDiscount;
  };
  deleteMembershipDiscount: {
    input: { id: number };
    output: undefined;
  };
  getMembershipProgram: {
    input: { id: number };
    output: MembershipProgram;
  };
  getPaginatedMembershipPrograms: {
    input: PaginatedInput;
    output: PaginatedResponse<MembershipProgramListItem[]>;
  };
  postMembershipProgram: {
    input: MembershipProgramCreateInput;
    output: MembershipProgram;
  };
  patchMembershipProgram: {
    input: MembershipProgramUpdateInput;
    output: MembershipProgram;
  };
  deleteMembershipProgram: {
    input: { id: number };
    output: undefined;
  };
  getPaginatedDriverName: {
    input: PaginatedInput;
    output: PaginatedResponse<ServerTypes.UserListItem[]>;
  };
  postBooking: {
    input: CreateBookingParams;
    output: { data: BookingProps };
  };
  getCar: {
    input: { id: number };
    output: CarDetailType;
  };
  getCarWheelSetTypes: {
    input: undefined;
    output: CarWheelSetType[];
  };
  getCarWheelPositions: {
    input: undefined;
    output: CarWheelPosition[];
  };
  getAvailableBookingTimeWindowsByDateNew: {
    input: AvailableBookingTimeWindowsInput;
    output: AvailableBookingTimeWindowsByDateNew[];
  };
  postMakeGroupAdmin: {
    input: GroupAdminType;
    output: { message: string };
  };
  postRemoveUserFromGroup: {
    input: GroupAdminType;
    output: { message: string };
  };
  getLatestCreatedBookingTimeWindowDate: {
    input: undefined;
    output: string;
  };
  getLanguages: {
    input: undefined;
    output: Language[];
  };
  getCapacityDays: {
    input: { fromDate?: string; toDate?: string };
    output: CapacityDay[];
  };
  getLatestCapacityDay: {
    input: undefined;
    output: CapacityDay;
  };
  getRegisteredWorkerCapacityContributions: {
    input: { id: number; fromDate?: string };
    output: CapacityContribution[];
  };
  postCapacityContribution: {
    input: CreateCapacityContributionDto;
    output: CapacityContributionDetails;
  };
  patchCapacityContribution: {
    input: UpdateCapacityContributionDto;
    output: CapacityContributionDetails;
  };
  getCapacityContribution: {
    input: { id: number };
    output: CapacityContributionDetails;
  };
  getBookingsForCar: {
    input: { carId: number; page: number; pageSize: number; search: string };
    output: PaginatedResponse<BookingListItemWithSalesItems[]>;
  };
  getNumBookings: {
    input: { userGroupIds?: number[]; fromDate?: string; toDate?: string };
    output: number;
  };
  getBookingsCosts: {
    input: { userGroupIds?: number[]; fromDate?: string; toDate?: string; vatIncluded: boolean };
    output: number;
  };
  getBookingStatuses: {
    input: undefined;
    output: BookingStatusDto[];
  };
  getPaginatedUserGroupCars: {
    input: PaginatedInput & { id: number | string | undefined | null };
    output: PaginatedResponse<CarListItem[]>;
  };
  downloadBookingReceipt: {
    input: { bookingId: number };
    output: BookingReceipt;
  };
  getPaginatedInvoices: {
    input: PaginatedInput & {
      userGroupId?: number;
      fromDate: Nullable<string>;
      toDate: Nullable<string>;
    };
    output: PaginatedResponse<InvoiceListItem[]>;
  };
  getInvoice: {
    input: { id: number };
    output: Invoice;
  };
  deleteInvoice: {
    input: { id: number };
    output: undefined;
  };
  postInvoiceToExcel: {
    input: { id: number };
    output: ArrayBufferLike;
  };
  postInvoice: {
    input: InvoiceCreateInput;
    output: Invoice;
  };
  patchInvoice: {
    input: InvoiceUpdateInput;
    output: Invoice;
  };
  getPaginatedCarMakes: {
    input: PaginatedInput;
    output: PaginatedResponse<CarMakeListItem[]>;
  };
  getCarMake: {
    input: { id: number };
    output: CarMake;
  };
  postCarMake: {
    input: CarMakeCreateInput;
    output: CarMake;
  };
  patchCarMake: {
    input: CarMakeUpdateInput;
    output: CarMake;
  };
  getPaginatedCarModels: {
    input: PaginatedInput;
    output: PaginatedResponse<CarModelListItem[]>;
  };
  getPaginatedCarModelsForMake: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarModelListItem[]>;
  };
  getCarModel: {
    input: { id: number };
    output: CarModel;
  };
  postCarModel: {
    input: CarModelCreateInput;
    output: CarModel;
  };
  patchCarModel: {
    input: CarModelUpdateInput;
    output: CarModel;
  };
  getCarGeneration: {
    input: { id: number };
    output: CarGeneration;
  };
  postCarGeneration: {
    input: CarGenerationCreateInput;
    output: CarGeneration;
  };
  patchCarGeneration: {
    input: CarGenerationUpdateInput;
    output: CarGeneration;
  };
  getCarGenerationSearchNameApiProviders: {
    input: undefined;
    output: OptionsTypeString[];
  };
  getCarWheelSetSpecifications: {
    input: { id: number };
    output: CarWheelSetSpecifications;
  };
  postCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsCreateInput;
    output: CarWheelSetSpecifications;
  };
  patchCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsUpdateInput;
    output: CarWheelSetSpecifications;
  };
  patchBulkCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsBulkUpdateInput;
    output: unknown;
  };
  getPaginatedCarWheelSetSpecifications: {
    input: PaginatedInput & CarWheelSetSpecificationsListInput;
    output: PaginatedResponse<CarWheelSetSpecifications[]>;
  };
  getPaginatedCarWheelSetSpecificationsForGeneration: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelSetSpecifications[]>;
  };
  getPaginatedContactPersons: {
    input: PaginatedInput & ListContactPersonInput;
    output: PaginatedResponse<ContactPersonListItem[]>;
  };
  getContactPerson: {
    input: { id: number };
    output: ContactPerson;
  };
  postContactPerson: {
    input: ContactPersonCreateInput;
    output: ContactPerson;
  };
  patchContactPerson: {
    input: ContactPersonUpdateInput;
    output: ContactPerson;
  };
  getPaginatedAvailabilities: {
    input: ListAvailabilitiesInput & PaginatedInput;
    output: PaginatedResponse<Availability[]>;
  };
  getAvailability: {
    input: { id: number };
    output: Availability;
  };
  patchAvailability: {
    input: AvailabilityUpdateInput;
    output: Availability;
  };
  deleteAvailability: {
    input: { id: number };
    output: unknown;
  };
  getPaginatedContactPersonDepartmentAddresses: {
    input: ListContactPersonDepartmentAddressInput & PaginatedInput;
    output: PaginatedResponse<ContactPersonDepartmentAddress[]>;
  };
  postContactPersonDepartmentAddress: {
    input: ContactPersonDepartmentAddressCreateInput;
    output: ContactPersonDepartmentAddress;
  };
  postContactPersonAvailability: {
    input: ContactPersonAvailabilityCreateInput;
    output: ContactPersonAvailability;
  };
  getPaginatedSkills: {
    input: PaginatedInput;
    output: PaginatedResponse<SkillListItem[]>;
  };
  getSkill: {
    input: { id: number };
    output: Skill;
  };
  postSkill: {
    input: SkillPostInput;
    output: Skill;
  };
  patchSkill: {
    input: SkillPatchInput;
    output: Skill;
  };
  deleteSkill: {
    input: { id: number };
    output: unknown;
  };
  getPaginatedCompetences: {
    input: PaginatedInput;
    output: PaginatedResponse<Competence[]>;
  };
  getCompetence: {
    input: { id: number };
    output: Competence;
  };
  postCompetence: {
    input: CompetencePostInput;
    output: Competence;
  };
  patchCompetence: {
    input: CompetencePatchInput;
    output: Competence;
  };
  deleteCompetence: {
    input: { id: number };
    output: unknown;
  };
  getStorageUnits: {
    input: StorageUnitInput;
    output: StorageUnit[];
  };
  getRouteLabels: {
    input: RouteLabelInput;
    output: RouteLabel[];
  };
  getCarWheelSetToPickUp: {
    input: CarWheelSetToPickUpInput;
    output: CarWheelSetToPickUp[];
  };
  getEditBookingPersmissions: {
    input: { id: string };
    output: EditBookingPermissions;
  };
  getUpdateBookingTimeWindowData: {
    input: { id: string };
    output: EditBookingTimeWindowData;
  };
  getUserGroupBookings: {
    input: { userGroupId?: number | null };
    output: UserGroupBooking[];
  };
}
