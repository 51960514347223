import { IconSvgProps } from './svgtype';

const ArrowLeft = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M24.533 16H7.467m0 0 6.4-6.4m-6.4 6.4 6.4 6.4'
    />
  </svg>
);
export { ArrowLeft };
