import { t } from '@lingui/macro';

import AddressPickerComponent from '../../../../components/BookingFlow/AddressPickerComponent';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import useOnNextButtonClick from './useOnNextButtonClick';

const Address = () => {
  const { navigateToNextStep } = useOnNextButtonClick();
  const { addressStepOk } = useStepValidator();
  const enableNextButton = addressStepOk();

  return (
    <BookingScreen
      title={t`Where is your car parked?`}
      onNextClicked={navigateToNextStep}
      disableNextButton={!enableNextButton}
    >
      <AddressPickerComponent />
    </BookingScreen>
  );
};

export default Address;
