import { Trans, t } from '@lingui/macro';
import { WheelStorageOptionType, wheelStorageOption } from 'noddi-async/src/types';
import { AvailableAddressPicker, NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { formatNorwegianCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { useWheelStore } from '../../../../stores/WheelStore';

type WheelStorageOptionProps = {
  name: string;
  price: number;
  description?: string;
  handleClick: () => void;
  selectedStorageType: WheelStorageOptionType | undefined;
  currentStorageType: WheelStorageOptionType | undefined;
  licensePlateNumber: string;
};

const WheelStorageOption = ({
  name,
  description,
  price,
  handleClick,
  selectedStorageType,
  currentStorageType,
  licensePlateNumber
}: WheelStorageOptionProps) => {
  const isSelected = selectedStorageType === currentStorageType;
  const textColorClass = isSelected ? 'text-primary-white' : 'text-primary-black';
  const { addSelectedWheelStorageOptions } = useWheelStore();
  const { bookingInputData } = useBookingContext();
  const address = bookingInputData?.address;
  const isFree = price === 0;
  const priceFormatted = isFree ? t`Free` : formatNorwegianCurrencyAmount(price);
  return (
    <div
      className={`relative flex min-h-40 w-full gap-4 rounded-lg px-4 py-6 transition-all sm:min-h-[170px] sm:p-8 ${
        isSelected ? 'bg-primary-purple' : 'bg-primary-white'
      }`}
    >
      <div className={`flex flex-col gap-1 ${textColorClass} w-full`}>
        <p className='text-5'>{name}</p>
        <p className={`text-4 ${isFree && !isSelected ? 'text-signal-success' : ''}`}>{priceFormatted}</p>
        <p className='mt-2 text-4 opacity-70'>{description}</p>

        {currentStorageType === wheelStorageOption.customer && (
          <p className='mt-2 text-4 '>
            <div className='relative left-[-6px] flex w-full items-center'>
              <NoddiIcon name='LocationPin' color={isSelected ? colors.primary.white : colors.primary.black} />
              {address?.name ?? address?.fullName} {address?.streetNumber}
            </div>
          </p>
        )}

        {isSelected && selectedStorageType && selectedStorageType !== wheelStorageOption.customer && (
          <div className='mt-2 max-w-100'>
            <AvailableAddressPicker
              clearAddress={() => {
                addSelectedWheelStorageOptions({
                  licensePlateNumber,
                  pickUpAddress: undefined
                });
              }}
              onSelectAddress={(address) => {
                if (address) {
                  addSelectedWheelStorageOptions({
                    licensePlateNumber,
                    pickUpAddress: address
                  });
                }
              }}
              addresses={[]}
            />
          </div>
        )}

        <div className='mt-4 flex w-full justify-end' onClick={handleClick}>
          {isSelected ? (
            <NoddiButton variant='secondary' size='small' startIcon='Minus'>
              <Trans>Remove</Trans>
            </NoddiButton>
          ) : (
            <NoddiButton variant='secondary' size='small' startIcon='Plus'>
              <Trans>Choose</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { WheelStorageOption };
