import { IconSvgProps } from './svgtype';

const Factory = ({ color, ...props }: IconSvgProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <g stroke={color} strokeWidth={1.5} clipPath='url(#a)'>
      <path strokeLinecap='round' d='M22.333 22.333H1' />
      <path d='M21.267 22.333V5.267c0-2.012 0-3.017-.625-3.642S19.012 1 17 1h-2.133c-2.012 0-3.017 0-3.642.625-.503.503-.601 1.253-.62 2.575' />
      <path d='M14.867 22.333V8.467c0-2.012 0-3.017-.625-3.642S12.612 4.2 10.6 4.2H6.333c-2.011 0-3.017 0-3.642.625-.624.625-.624 1.63-.624 3.642v13.866' />
      <path strokeLinecap='round' d='M8.467 22.333v-3.2M5.267 7.4h6.4M5.267 10.6h6.4M5.267 13.8h6.4' />
    </g>
    <defs>
      <clipPath id='a'>
        <rect width={24} height={24} fill='#fff' rx={4} />
      </clipPath>
    </defs>
  </svg>
);
export { Factory };
