import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { NoddiAsyncError } from 'noddi-async/src/types';

import { NoddiButton } from '../Buttons';
import { mapErrorCodeToUiMessage } from './ErrorMessage';

type ErrorPageProps = {
  errorTitle?: string;
  errorMessage?: string;
  apiError?: NoddiAsyncError | Array<NoddiAsyncError | null>;
  customCallback?: { ctaText: string; callback: () => void };
};

const ErrorPage = ({ errorMessage, errorTitle, apiError, customCallback }: ErrorPageProps) => {
  const errorToRender = Array.isArray(apiError) ? apiError.find((e) => e !== null) : apiError;
  const apiErrorMessage = errorToRender && mapErrorCodeToUiMessage({ error: errorToRender });

  const customErrorMessage = apiErrorMessage ?? errorMessage;

  function handleCustomCallback() {
    customCallback?.callback() || window.location.reload();
  }

  return (
    <Stack
      direction='column'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        textAlign: 'center'
      }}
    >
      <Typography variant='h3'>{errorTitle ?? <Trans>We're sorry, an error has occurred</Trans>}</Typography>
      <Typography variant='subtitle1'>
        {customErrorMessage ?? <Trans>We are working on solving the issue, please try again later</Trans>}
      </Typography>

      <NoddiButton variant='primary' onClick={handleCustomCallback} style={{ marginTop: '20px' }}>
        {customCallback?.ctaText ?? <Trans>Try again</Trans>}
      </NoddiButton>
    </Stack>
  );
};

export { ErrorPage };
