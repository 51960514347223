import { IconSvgProps } from './svgtype';

const Cross = ({ color, ...rest }: IconSvgProps) => {
  return (
    <svg width={32} height={32} fill='none' {...rest}>
      <path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='m10 10 11.782 11.782M22 10 10.218 21.782' />
    </svg>
  );
};
export { Cross };
