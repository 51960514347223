import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiIcon } from '../atoms';
import { NoddiButton } from './NoddiButton';

type NoddiSelectableItemProps = {
  title: string;
  description?: string;
  isSelected: boolean;
  iconName: IconName;
  action: () => void;
  actionText: string;
};

const NoddiSelectableItem = ({
  title,
  iconName,
  isSelected,
  description,
  action,
  actionText
}: NoddiSelectableItemProps) => {
  return (
    <div
      className={`flex min-w-[300px] justify-between gap-4 rounded-lg p-4 transition-all ${
        isSelected ? 'bg-primary-purple text-primary-white' : 'bg-primary-white'
      } `}
    >
      <div className='flex gap-2'>
        <NoddiIcon name={iconName} color={isSelected ? colors.primary.white : colors.primary.black} />
        <div className='flex flex-col'>
          <p>{title}</p>

          {description && <p className='max-w-xs opacity-[50%]'>{description}</p>}
        </div>
      </div>
      <div className='flex items-center'>
        <NoddiButton
          className={isSelected ? '' : '!text-primary-black'}
          variant='secondary'
          size='small'
          onClick={action}
        >
          {actionText}
        </NoddiButton>
      </div>
    </div>
  );
};

export { NoddiSelectableItem };
