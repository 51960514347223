import { Trans } from '@lingui/macro';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { Referral } from 'noddi-async/src/types';
import { CouponCard, NoddiCollapseCard, NoddiIcon, colors, useIsMobile } from 'noddi-ui';

const ReferredUsers = ({ referrals }: { referrals: Referral[] }) => {
  const isMobile = useIsMobile();

  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          pt: '20px',
          mt: '20px'
        }}
      >
        <div>
          <h2 className='mb-3 font-bold'>
            <Trans>Users you have referred</Trans>
          </h2>
          {referrals.map((referralDetails, index) => {
            return (
              <NoddiCollapseCard
                borderColor={colors.primary.white}
                key={referralDetails.referredUserId}
                sx={{ marginTop: index === 0 ? 0 : 2 }}
                backgroundColor={colors.primary.darkPurple30}
                header={
                  <Stack direction='row' alignItems='center'>
                    <NoddiIcon name='Users' size='small' className='mr-1' />
                    <Typography color='text.primary' variant='h6'>
                      {referralDetails.referredUserName}
                    </Typography>
                  </Stack>
                }
                collapseBody={
                  <Grid container spacing={2}>
                    {referralDetails.receivedCoupons.map((referralCoupon, index) => {
                      const coupon = referralCoupon.coupon;
                      const amount = coupon.amount;
                      const amountPercentage = coupon.amountPercentage;
                      return amount || amountPercentage ? (
                        <Grid item xs={isMobile ? 12 : 6} key={index}>
                          <CouponCard
                            amount={amount}
                            amountPercentage={amountPercentage}
                            descriptionPublic={coupon.descriptionPublic}
                            namePublic={coupon.namePublic}
                            isUsed={Boolean(referralCoupon.usedAt)}
                            containerSx={{ marginTop: '22px' }}
                          />
                        </Grid>
                      ) : null;
                    })}
                  </Grid>
                }
              />
            );
          })}
          {referrals.length === 0 && (
            <p className='mt-2'>
              <Trans>You have not referred any users yet</Trans>
            </p>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default ReferredUsers;
