import { t } from '@lingui/macro';
import { Stack } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen, NoddiContainer, useIsMobile } from 'noddi-ui';
import { useEffect } from 'react';

import TireHotelBookingFlow from '../BookingFlow/Components/TireHotelBookingFlow';
import { useTireHotelContext } from '../BookingFlow/context';
import { TireStorageBookingSteps } from '../BookingFlow/tireStorageBookingSteps';

const stickyBottomDivHeight = '20vh';

type TireAddressDetailsI = {
  isCancel?: boolean;
};
const TireAddressDetails = ({ isCancel }: TireAddressDetailsI) => {
  const { currentUserGroupId } = useAuthContext();
  const { setCurrentStep, currentStep } = useTireHotelContext();

  useEffect(() => {
    if (isCancel) {
      setCurrentStep(TireStorageBookingSteps.returnChoices);
    }
  }, [isCancel]);

  const {
    data: tireHotelContracts,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelContracts,
    input: { userGroupId: currentUserGroupId as number, isActive: true },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity
    }
  });

  const vinNumbers =
    tireHotelContracts?.map((contract) => contract.car.vinNumber).filter((x): x is string => Boolean(x)) ?? [];

  const { isLoading: optionsIsLoading, error: optionsError } = noddiAsync.useGet({
    type: URLKeys.getTireHotelOptions,
    input: { vinNumbers, userGroupId: currentUserGroupId },
    queryConfig: {
      enabled: vinNumbers.length > 0,
      staleTime: Infinity
    }
  });

  const isMobile = useIsMobile();

  if (isPending || optionsIsLoading) {
    return <LoadingScreen />;
  }

  if (error || optionsError || !currentUserGroupId) {
    return <ErrorPage apiError={[error, optionsError]} />;
  }

  return (
    <NoddiContainer
      sx={{ marginTop: '2rem' }}
      header={t`Tire storage`}
      description={t`Manage your tire storage subscriptions`}
      contentContainerProps={{
        sx: { paddingBottom: isMobile ? stickyBottomDivHeight : 'none', marginBottom: '0px' }
      }}
    >
      <Stack
        maxWidth={currentStep === TireStorageBookingSteps.timePicker ? 'lg' : 'sm'}
        sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '-50px' }}
      >
        <TireHotelBookingFlow />
      </Stack>
    </NoddiContainer>
  );
};

export default TireAddressDetails;
