import { Trans, t } from '@lingui/macro';
import { OutlinedInput } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'noddi-provider';
import {
  LoadingScreen,
  NoddiIcon,
  NoddiIconButton,
  NoddiProgressBar,
  SMSLogin,
  SectionSpacer,
  StickyBottomBarWrapper,
  UserRegistrationForm,
  colors
} from 'noddi-ui';
import { useEffect } from 'react';

import StickyServiceSummary from '../../../../components/StickyServiceSummary';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useActivateCoupon from '../../../../hooks/useActivateCoupon';
import tracking from '../../../../tracking';
import BookingScreen, { useIsMobileViewInBookingFlow } from '../../BookingScreen';
import { useBackNavigation } from '../../hooks/useBackNavigation';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import ConfirmBooking from './ConfirmBooking';

const Summary = () => {
  const { isLoggedIn, isTokenLoginLoading } = useAuthContext();
  const isMobileViewInBookingFlow = useIsMobileViewInBookingFlow();
  const { bookingInputData: inputData, updateBookingInputData, salesItems } = useBookingContext();
  const { goBack } = useBackNavigation();

  const { activateCouponForSalesItem, couponsFromApi: fetchedCoupons } = useActivateCoupon();

  const { data: membershipData } = useMembershipPrograms();

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateBookingInputData({ comment: event.target.value });
  };

  useEffect(() => {
    if (isLoggedIn && fetchedCoupons?.length !== 0) {
      salesItems.forEach((salesItem) => {
        activateCouponForSalesItem({ salesItemId: salesItem.id, licensePlateNumber: salesItem.licensePlateNumber });
      });
    }
  }, [isLoggedIn, fetchedCoupons]);

  if (isTokenLoginLoading) {
    return (
      <BookingScreen hideNextButton title={t`Does this look right?`}>
        <LoadingScreen />
      </BookingScreen>
    );
  }

  return (
    <BookingScreen
      title={t`Does this look right?`}
      hideNextButton
      CustomBottomBar={
        isLoggedIn ? (
          <StickyBottomBarWrapper>
            <div className='flex w-full justify-between'>
              <div className='flex items-center gap-2'>
                <NoddiIconButton iconName='ArrowLeft' iconSize='medium' onClick={goBack} variant='secondary' />
                <div className='w-full max-w-32'>
                  <NoddiProgressBar value={100} />
                </div>
              </div>
            </div>
            <ConfirmBooking membershipPrograms={membershipData} />
          </StickyBottomBarWrapper>
        ) : null
      }
    >
      <StickyServiceSummary />

      <SectionSpacer>
        <Typography marginBottom={1} variant='h5'>
          <Trans>Comment</Trans>
        </Typography>

        <OutlinedInput
          rows={2}
          onChange={handleCommentChange}
          fullWidth={true}
          type='textarea'
          value={inputData.comment ?? ''}
          title='Delivery Code'
          placeholder={t`Instructions to the technician at arrival (e.g. where the car is parked)`}
          multiline
        />
      </SectionSpacer>

      <SectionSpacer>
        <Typography variant='subtitle2' style={{ display: 'flex', alignItems: 'center' }}>
          <NoddiIcon name='Info' size='medium' color={colors.primary.darkPurple} className='mr-2' />
          {t`You will receive a payment link after the job has been completed`}
        </Typography>
      </SectionSpacer>

      {!isLoggedIn && (
        <SMSLogin
          userRegistrationForm={(phoneNumber) => (
            <UserRegistrationForm
              phoneNumber={phoneNumber}
              skipPasswordCreation
              trackUserIdentity={(user) => {
                tracking.trackUserIdentity(user);
              }}
            />
          )}
          callBackObject={isMobileViewInBookingFlow ? undefined : { customCallback: goBack, title: t`Back` }}
        />
      )}
    </BookingScreen>
  );
};

export default Summary;
