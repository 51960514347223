import { Trans, plural } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { RouteItem } from 'noddi-async/src/types';
import { DateFormats, format, fromSecondsToMinutes } from 'noddi-util';
import { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../../../../atoms/NoddiIcon';
import { NoddiDrawer } from '../../../../../Elements';
import RouteDrawer from '../../Drawer/RouteDrawer';
import { EstimatedServiceTime } from '../EstimatedServiceTime';
import { CardCommonCss } from './commonCss';
import { RouteItemCardProps } from './types';

type CardProps = {
  isInProgress: boolean;
  isSelectedSolution: boolean;
};
const Card = styled.button<CardProps>`
  ${CardCommonCss};
  min-width: 280px;
  border: 2px solid #002f33;

  ${(props) => props.isInProgress && `background-color: #002f33;`}
  ${(props) => props.isInProgress && `color: white;`}
  ${(props) =>
    props.isInProgress &&
    `
    &:hover {
     color: black;
     
    }
  `}

  ${(props) => !props.isSelectedSolution && 'min-width: 180px;'}
  

  :hover {
    background-color: #c1edd3;
  }
`;

const getRouteItemDisplayProperties = (routeItem: RouteItem) => {
  const bookingTimeStart = routeItem?.bookingTimeWindow?.start;
  const bookingTimeEnd = routeItem?.bookingTimeWindow?.end;

  const numberOfCars = routeItem.numBookingItems;

  return {
    bookingTimeEnd: bookingTimeEnd ? format(bookingTimeEnd, DateFormats.TIME) : '',
    bookingTimeStart: bookingTimeStart ? format(bookingTimeStart, DateFormats.TIME) : '',
    numberOfCars,
    waitingTime: routeItem.estimatedWaitingTime
  };
};

const DefaultCard = ({
  routeItem,
  delayInMinutes,
  routeId,
  serviceTimeInMinutes,
  isSelectedSolution
}: RouteItemCardProps & { serviceTimeInMinutes: number }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { numberOfCars, bookingTimeEnd, bookingTimeStart, waitingTime } = getRouteItemDisplayProperties(routeItem);

  const customer = routeItem.userGroup.name;
  const carText = plural(numberOfCars, {
    one: 'car',
    other: 'cars'
  });

  const isInvalidRouteItem = !isSelectedSolution && routeItem.numSecondsLate > 0;

  return (
    <>
      <Card
        isInProgress={!!(routeItem.startedAt && !routeItem.completedAt)}
        isSelectedSolution={isSelectedSolution}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
          <EstimatedServiceTime
            estimatedServiceStart={routeItem.estimatedServiceStart}
            delayInMinutes={delayInMinutes}
            serviceTimeInMinutes={serviceTimeInMinutes}
          />
          <Typography fontSize={12}>{`${serviceTimeInMinutes} min`}</Typography>
        </Stack>
        <Typography>
          {customer ? (
            `${customer}: ${numberOfCars} ${carText} `
          ) : (
            <>
              <Trans>Cars:</Trans> <b>{numberOfCars}</b>
            </>
          )}
        </Typography>
        {isSelectedSolution && (
          <Typography>
            Booking:{' '}
            <b>
              {bookingTimeStart} - {bookingTimeEnd}
            </b>
          </Typography>
        )}
        {waitingTime > 0 && (
          <Typography>
            <Trans>
              Waiting time: <b>{fromSecondsToMinutes(waitingTime)} min</b>
            </Trans>
          </Typography>
        )}
        {isInvalidRouteItem && (
          <Stack direction='row' alignItems='center' gap={1}>
            <NoddiIcon name='Warning' color={colors.signal.danger} size='medium' />;
            <Typography>
              <Trans>Starts too late</Trans>
            </Typography>
          </Stack>
        )}
      </Card>
      <NoddiDrawer
        isOpen={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <RouteDrawer routeItemId={routeItem.id} routeId={routeId} />
      </NoddiDrawer>
    </>
  );
};

export default DefaultCard;
