import { Trans } from '@lingui/macro';
import { ServiceCategory } from 'noddi-async/src/types';

import { AvailableServiceCategory } from './AvailableServiceCategory';
import { UnavailableServiceCategory } from './UnavailableServiceCategory';

type Props = {
  address?: string;
  serviceCategories: ServiceCategory[];
  unavailableServiceCategories: string[];
};

const ServiceAvailabilityInfo = ({ address, serviceCategories, unavailableServiceCategories }: Props) => {
  if (!address) {
    return <></>;
  }

  return (
    <div className='flex flex-col gap-2 rounded-lg bg-primary-white px-4 py-6'>
      <p className='text-6 sm:text-8'>
        <Trans>Hooray! We deliver to this address</Trans>
      </p>
      <div className='flex flex-col gap-4'>
        {serviceCategories.map((serviceCategory, index) => (
          <AvailableServiceCategory key={index} serviceCategory={serviceCategory} />
        ))}
      </div>
      {unavailableServiceCategories.length > 0 && (
        <div className='mt-4'>
          <div className='h-0.25 w-full bg-primary-purple/90 opacity-45' />
          <div className='mt-4 flex flex-col gap-4'>
            {unavailableServiceCategories.map((serviceCategoryName, index) => (
              <UnavailableServiceCategory key={index} serviceCategoryName={serviceCategoryName} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { ServiceAvailabilityInfo };
