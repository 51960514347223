import { IconSvgProps } from './svgtype';

const UserCircle = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} fill='none' {...props}>
    <circle cx={16} cy={12.8} r={3.2} stroke={color} strokeWidth={1.5} />
    <circle cx={16} cy={16} r={10.667} stroke={color} strokeWidth={1.5} />
    <path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M22.367 24.533C22.197 21.45 21.253 19.2 16 19.2s-6.197 2.249-6.367 5.333'
    />
  </svg>
);
export { UserCircle };
