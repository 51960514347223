import { useState } from 'react';

import { colors } from '../../tailwind-design-preset';
import { NoddiIcon } from '../atoms/NoddiIcon';
import { IconName, IconSize } from '../atoms/types/icon';

type IconVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'ghost';

interface NoddiIconButtonProps {
  iconName: IconName;
  iconSize?: IconSize;
  variant?: IconVariant;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

//Created intro variants (will be redesigned once Rachid has time)

const variants = {
  primary: {
    wrapperClass: 'bg-primary-purple active:opacity-80',
    loadingClass: 'border-t-primary-pink',
    iconColor: colors.primary.white,
    hoverIconColor: colors.primary.pink
  },
  secondary: {
    wrapperClass: 'bg-primary-white active:bg-systemColors-snow',
    loadingClass: 'border-t-primary-purple',
    iconColor: colors.primary.black,
    hoverIconColor: colors.primary.purple
  },
  tertiary: {
    wrapperClass: 'bg-signal-danger50 active:opacity-80',
    loadingClass: 'border-t-primary-orange',
    iconColor: colors.primary.orange,
    hoverIconColor: colors.secondary.orange50
  },
  quaternary: {
    wrapperClass: 'bg-[#11192742] active:opacity-80',
    loadingClass: 'border-t-primary-black',
    iconColor: colors.primary.white,
    hoverIconColor: colors.primary.black
  },
  ghost: {
    wrapperClass: '',
    loadingClass: '',
    iconColor: colors.primary.black,
    hoverIconColor: colors.systemColors.grey
  }
};

export const NoddiIconButton = ({
  iconName,
  iconSize = 'large',
  variant = 'primary',
  onClick,
  isLoading,
  isDisabled
}: NoddiIconButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={`relative w-fit cursor-pointer rounded-full p-2 transition-all ${variants[variant].wrapperClass} ${
        isDisabled ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={onClick}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <NoddiIcon
        name={iconName}
        size={iconSize}
        color={isHovering ? variants[variant].hoverIconColor : variants[variant].iconColor}
      />
      {isLoading && (
        <div
          className={`absolute inset-0 box-border size-full animate-spin-slow rounded-full border-2 border-systemColors-transparent ${variants[variant].loadingClass}`}
        />
      )}
    </div>
  );
};
