import { Trans, t } from '@lingui/macro';
import FormControl from '@mui/material/FormControl';
import { NoddiSelectableItem } from 'noddi-ui/src/molecules/NoddiSelectableItem';
import { useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import { SelectedCar } from '../../interfaces';

type CarDropDownProps = {
  registeredCars: SelectedCar[];
  defaultCar?: SelectedCar;
  disabled?: boolean;
};

export default function CarDropDown({ registeredCars, defaultCar }: CarDropDownProps) {
  const { bookingInputData, updateBookingInputData } = useBookingContext();

  // we can't set default values at the first render since unfortunately as the cars in session storage is not
  // deterministically set at this point. Hence, we need some additional logic to set the default value

  const [_, setSelectedCar] = useState<SelectedCar | undefined>(defaultCar);

  const handleChange = (selectedLicensePlate: string | undefined) => {
    const newlySelectedCarId = selectedLicensePlate;
    if (!newlySelectedCarId) {
      return;
    }

    const newSelectedCar = registeredCars.find((car) => car.licensePlateNumber === newlySelectedCarId);

    if (!newSelectedCar) {
      return;
    }

    // don't add if already added
    const carExists = bookingInputData.selectedCars.some(
      (car) => car.licensePlateNumber === newSelectedCar.licensePlateNumber
    );

    if (carExists) {
      return;
    }

    // car to remove
    setSelectedCar(newSelectedCar);

    updateBookingInputData({
      selectedCars: [
        ...bookingInputData.selectedCars,
        {
          licensePlateNumber: newSelectedCar.licensePlateNumber,
          carName: newSelectedCar.carName,
          carSizeId: newSelectedCar.carSizeId,
          vinNumber: newSelectedCar.vinNumber
        }
      ]
    });
    GoogleTrackingService.trackEvent({ eventType: GtmEvents.addToCart });
  };

  return (
    <FormControl variant='filled' size='medium' fullWidth>
      <p>
        <Trans>My cars</Trans>
      </p>
      <div className='mb-4  mt-3 flex flex-col gap-3'>
        {registeredCars?.map((car) => (
          <NoddiSelectableItem
            title={car.carName}
            description={car.licensePlateNumber}
            iconName='Car'
            isSelected={false}
            action={() => {
              handleChange(car.licensePlateNumber);
            }}
            actionText={t`Add`}
            key={car.licensePlateNumber}
          />
        ))}
      </div>
    </FormControl>
  );
}
