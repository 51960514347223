import { Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserListItem } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { ApiErrorMessage, NoddiAsyncError, NoddiButton, NoddiIcon, NoddiTable, colors, usePagination } from 'noddi-ui';
import { useEffect, useState } from 'react';

interface Props {
  handleClose: () => void;
}

export default function ChooseImpersonateUser({ handleClose }: Props) {
  const [user, setUser] = useState<UserListItem | null>(null);
  const { updateImpersonatedData } = useAuthContext();
  const { page, pageSize, search, setPage, setPageSize, setSearch } = usePagination();

  const {
    data: users,
    refetch,
    isPending: isUsersPending
  } = noddiAsync.useGet({ type: URLKeys.getPaginatedUsers, input: { page, pageSize, search } });

  const {
    mutateAsync,
    isPending,
    isSuccess: impersonationSuccess,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postImpersonateUser,
    queryConfig: {
      onSuccess(data) {
        updateImpersonatedData(data.data);
      }
    }
  });

  useEffect(() => {
    refetch();
  }, [page, pageSize, search]);

  const tableItems = users?.results.map((item) => {
    return {
      renderItems: [item.name, item.email, item.phoneNumber],
      item
    };
  });

  return (
    <Stack>
      {impersonationSuccess ? (
        <Stack justifyContent='center' alignItems='center' mt={20}>
          <NoddiIcon name='Check' size='extraLarge' color={colors.primary.orange} />
          <Typography textAlign='center' mt={2} width='60%'>
            You are now entering <b>god mode 👨‍🦳</b> and impersonating {user?.name} ({user?.phoneNumber}). Good luck and
            be careful!
          </Typography>
          <NoddiButton style={{ marginTop: '24px' }} onClick={handleClose}>
            Close
          </NoddiButton>
        </Stack>
      ) : (
        <>
          {user && (
            <Stack px={4} mb={2} direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='row' alignItems='center'>
                <NoddiIcon name='Users' size='small' className='mr-1' /> Selected user: {user.name} ({user.phoneNumber})
              </Stack>
              {error && <ApiErrorMessage error={error as NoddiAsyncError} />}
              <NoddiButton loading={isPending} disabled={!user} onClick={() => mutateAsync({ userId: user.id })}>
                Impersonate user
              </NoddiButton>
            </Stack>
          )}
          <NoddiTable
            count={users?.count}
            items={tableItems}
            isLoading={isUsersPending}
            onRowClick={setUser}
            pageSize={pageSize}
            setPageSize={setPageSize}
            page={page}
            setPage={setPage}
            headers={['Name', 'Email', 'Phone number']}
            searchProps={{
              onSearch: (e) => {
                setPage(1);
                setSearch(e.target.value ?? '');
              },
              placeholder: 'Search user by name, email or phone number'
            }}
          />
        </>
      )}
    </Stack>
  );
}
