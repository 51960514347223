import { Trans } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';
import { NoddiButton, useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { formatNorwegianCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { SelectedCar } from '../../interfaces';

type ServiceItemProps = {
  name: string;
  price: number;
  description?: string;
  car: SelectedCar;
  salesItem: ServerTypes.AvailableSalesItem;
  onSalesItemClick: () => void;
};

const SalesItem = ({ name, description, price, car, salesItem, onSalesItemClick }: ServiceItemProps) => {
  const isMobile = useIsMobile();
  const [showFullDescription, setShowFullDescription] = useState(isMobile ? false : true);
  const { isSelected } = useSelectSalesItem();
  const isAlreadySelected = isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });

  const trimmedDescription = description && description?.length > 70 ? `${description.slice(0, 70)}...` : description;

  const ShowMoreLessDescriptionLabel = (
    <>
      {description && description?.length > 70 && isMobile && (
        <span
          className='ml-2 cursor-pointer text-4 underline opacity-70'
          onClick={() => setShowFullDescription((prev) => !prev)}
        >
          {!showFullDescription ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}
        </span>
      )}
    </>
  );

  return (
    <div
      className={cn(
        'relative flex min-h-40 w-full gap-4  rounded-lg p-5 px-10 transition-all sm:min-h-[170px]',
        isAlreadySelected ? 'bg-primary-purple' : 'bg-[#fafafa]'
      )}
    >
      <div
        className={cn('flex flex-col gap-1 w-full', isAlreadySelected ? 'text-primary-white' : 'text-primary-black')}
      >
        <p className='text-5'>{name}</p>
        <p className='text-4'>{formatNorwegianCurrencyAmount(price)}</p>
        <p className=' text-4 opacity-70 '>
          {showFullDescription ? description : trimmedDescription}
          {ShowMoreLessDescriptionLabel}
        </p>

        <div className='mt-4 w-fit'>
          <NoddiButton
            variant={isAlreadySelected ? 'secondary' : 'primary'}
            startIcon={isAlreadySelected ? 'Minus' : 'Plus'}
            size='small'
            onClick={onSalesItemClick}
          >
            {isAlreadySelected ? <Trans>Remove</Trans> : <Trans>Add</Trans>}
          </NoddiButton>
        </div>
      </div>
    </div>
  );
};

export { SalesItem };
