import { AtLeastOne, SelectedWheelStorageOption } from 'noddi-async/src/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { findWheelStorageSalesItems } from '../helper/salesItems';
import { SelectedSalesItem } from '../pages/BookingFlow/interfaces';

type UpdateSelectedWheelStorageOptions = AtLeastOne<
  Pick<SelectedWheelStorageOption, 'pickUpAddress' | 'storageType'>
> & { licensePlateNumber: SelectedWheelStorageOption['licensePlateNumber'] };
interface WheelStore {
  selectedWheelStorageOptions: SelectedWheelStorageOption[];
  reset: () => void;
  addSelectedWheelStorageOptions: (props: UpdateSelectedWheelStorageOptions) => void;
  removeSelectedWheelStorageOptions: (licensePlateNumber: string) => void;
  getWheelStorageItems: (salesItems: SelectedSalesItem[]) => SelectedSalesItem[];
}

const initialState = {
  selectedWheelStorageOptions: []
};

export const useWheelStore = create<WheelStore>()(
  persist(
    (set) => ({
      ...initialState,
      addSelectedWheelStorageOptions: (props) => {
        const { licensePlateNumber, ...updateArgs } = props;
        set((state) => {
          const alreadySelectedStorageOptions = state.selectedWheelStorageOptions.filter(
            (option) => option.licensePlateNumber !== licensePlateNumber
          );

          const exists = state.selectedWheelStorageOptions.find(
            (option) => option.licensePlateNumber === licensePlateNumber
          );

          return {
            selectedWheelStorageOptions: [
              ...alreadySelectedStorageOptions,
              {
                ...exists,
                ...updateArgs,
                licensePlateNumber
              }
            ]
          };
        });
      },
      removeSelectedWheelStorageOptions: (licensePlateNumber) => {
        set((state) => ({
          selectedWheelStorageOptions: state.selectedWheelStorageOptions.filter(
            (option) => option.licensePlateNumber !== licensePlateNumber
          )
        }));
      },
      getWheelStorageItems: (salesItems) => {
        return findWheelStorageSalesItems(salesItems);
      },
      reset: () => set(initialState)
    }),
    {
      name: 'WheelStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
