import { IconName, IconSize, iconMap, sizeMap } from './types';

interface NoddiIconProps extends Pick<React.SVGProps<SVGSVGElement>, 'color' | 'className' | 'onClick'> {
  name: IconName;
  size?: IconSize;
}

export const NoddiIcon = ({ name, size = 'medium', color, className, onClick }: NoddiIconProps) => {
  const IconComponent = iconMap[name];
  const iconSize = sizeMap[size];

  return (
    <IconComponent
      width={iconSize}
      height={iconSize}
      color={color ?? '#1C274C'}
      className={className}
      onClick={onClick}
      viewBox='0 0 32 32'
    />
  );
};
