import { Container, Stack, Typography } from '@mui/material';
import { ServerTypes, URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen, OrderSummary, colors, useIsMobile } from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import Feedback from './Feedback';
import PaymentInfo from './PaymentInfo';
import RelevantQuestionsForBooking from './RelevantQuestionsForBooking';

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BookingContainer = styled.div<{ isMobile: boolean }>`
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding: 48px 20px 64px 20px;
  height: 100%;
  min-height: ${(p) => (p.isMobile ? '92vh' : '90vh')};
  display: flex;
  flex-direction: column;
`;

const Circle = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  background-color: ${colors.primary.darkPurple};
  border-radius: 50%;
  position: relative;
`;

const rotate = keyframes`
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
`;

const Ringring = styled.div`
  border: 3px solid ${colors.primary.darkPurple};
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-animation: ${rotate} 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
`;

enum BookingStatus {
  //The customer has started the booking process, but is not booked yet.
  DRAFT = 'Draft',
  //The customer has successfully made a booking.
  CONFIRMED = 'Confirmed',
  //The booking has been assigned to a route.
  ASSIGNED_TO_ROUTE = 'Assigned',
  //The booking has been cancelled for some reason.
  CANCELLED = 'Cancelled',
  //We have completed the booking, meaning that the service has been performed at the customers address.
  COMPLETED = 'Completed',
  //We were unable to complete the booking for some reason.
  UNABLE_TO_COMPLETE = 'Unable to complete'
}

function ContentBasedOnStatus({
  status,
  bookingInfo,
  currentEstimatedArrivalTime
}: {
  status: BookingStatus;
  bookingInfo: ServerTypes.BookingInfo;
  currentEstimatedArrivalTime?: { start?: string; end?: string };
}) {
  const delayInMinutes = Math.floor(bookingInfo.delayInSeconds / 60);
  const showDelayedMessage = delayInMinutes > 30;
  const showAheadOfScheduleMessage = delayInMinutes < -30;
  const showCommunicatedTimes = !showAheadOfScheduleMessage && !showDelayedMessage;
  const hasLiveEstimate = currentEstimatedArrivalTime?.end && currentEstimatedArrivalTime?.start;

  const CommunicatedArrivalTimes = () => (
    <>
      {bookingInfo.communicatedArrivalWindow.start && bookingInfo.communicatedArrivalWindow.end ? (
        <Stack direction='row'>
          {hasLiveEstimate && (
            <Circle style={{ marginRight: 12, marginTop: 4 }}>
              <Ringring />
            </Circle>
          )}
          <Typography>
            {bookingInfo.serviceWorkerNickname
              ? `Vår tekniker ${bookingInfo.serviceWorkerNickname} forventes`
              : 'Vi for venter'}{' '}
            å komme til deg mellom <b>{format(bookingInfo.communicatedArrivalWindow.start, DateFormats.TIME)}</b> og{' '}
            <b>{format(bookingInfo.communicatedArrivalWindow.end, DateFormats.TIME)}</b>
          </Typography>
        </Stack>
      ) : null}

      {!hasLiveEstimate && (
        <Typography style={{ marginTop: 12 }}>Du vil få beskjed når vi er på vei til deg!</Typography>
      )}
    </>
  );
  const CurrentEstimatedTimes = () =>
    hasLiveEstimate ? (
      <Stack direction='row'>
        <Circle style={{ marginRight: 12, marginTop: 4 }}>
          <Ringring />
        </Circle>
        <Typography width='80%'>
          {showAheadOfScheduleMessage
            ? 'Vi er litt foran skjemaet og ankommer muligens litt før den opprinnlige tiden.'
            : 'Vi er litt bak skjemaet og ankommer muligens etter den opprinnlige tiden.'}{' '}
          {bookingInfo.serviceWorkerNickname
            ? `Vår tekniker ${bookingInfo.serviceWorkerNickname} forventes`
            : 'Vi for venter'}{' '}
          å komme til deg mellom{' '}
          <b>
            kl {currentEstimatedArrivalTime?.start ? format(currentEstimatedArrivalTime.start, DateFormats.TIME) : '-'}
          </b>{' '}
          og{' '}
          <b>kl {currentEstimatedArrivalTime?.end ? format(currentEstimatedArrivalTime.end, DateFormats.TIME) : '-'}</b>
        </Typography>
      </Stack>
    ) : (
      // fallback to communicated times if we dont have currentEstimatedArrivalTime
      <CommunicatedArrivalTimes />
    );

  switch (status) {
    case BookingStatus.DRAFT:
      return <></>;
    case BookingStatus.CANCELLED:
    case BookingStatus.UNABLE_TO_COMPLETE:
      return (
        <Stack>
          <Typography>
            {' '}
            {status === BookingStatus.UNABLE_TO_COMPLETE
              ? 'Vi hadde ikke mulighet til å fullføre denne bookingen. Ta kontakt med oss for mer informasjon'
              : 'Denne bookingen står som avlyst i våre systemer'}
          </Typography>
        </Stack>
      );
    case BookingStatus.ASSIGNED_TO_ROUTE:
    case BookingStatus.CONFIRMED:
      return (
        <>
          {showCommunicatedTimes ? <CommunicatedArrivalTimes /> : <CurrentEstimatedTimes />}

          <div style={{ marginTop: 22 }}>
            <RelevantQuestionsForBooking />
          </div>
        </>
      );

    case BookingStatus.COMPLETED:
      return (
        <>
          <Typography>
            Denne bookingen står nå markert som <b>ferdig</b> i våre systemer.
          </Typography>
          <Typography style={{ marginTop: 12 }}>Etter betalingen kan du laste ned kvittering fra Mine sider</Typography>
        </>
      );

    default:
      return <div />;
  }
}

const BookingInfo = () => {
  const data = useParams<{ slug: string }>();
  const isMobile = useIsMobile();
  const [orderId, setOrderId] = useState<number | null>(null);

  const {
    data: bookingInfo,
    isPending: isBookingInfoPending,
    isSuccess: isBookingInfoSuccess
  } = noddiAsync.useGet({
    type: URLKeys.getBookingInfo,
    input: { slug: data.slug ?? '' }
  });

  useEffect(() => {
    if (!bookingInfo) {
      return;
    }
    setOrderId(bookingInfo.order.id);
  }, [bookingInfo]);

  const shouldRefetch = isBookingInfoSuccess && !!bookingInfo.delayInSeconds;

  const { data: currentEstimatedArrivalTime } = noddiAsync.useGet({
    type: URLKeys.getCurrentEstimatedArrivalTime,
    input: { slug: data.slug ?? '' },
    queryConfig: {
      refetchInterval: shouldRefetch ? 60000 * 2 : false,
      refetchOnWindowFocus: shouldRefetch
    }
  });

  if (isBookingInfoPending) {
    return <LoadingScreen />;
  }

  if (!isBookingInfoSuccess) {
    return (
      <Container>
        <Typography>Obs noe gikk galt..</Typography>
      </Container>
    );
  }

  const isInvoiced = bookingInfo.order.status === 'Invoiced';
  const amountDue = bookingInfo.order.amountDue;
  const hasPayed = isInvoiced && amountDue <= 0;
  const hasPaymentLeft = isInvoiced && amountDue > 0;
  const showFeedbackContent = !bookingInfo.feedback && [BookingStatus.COMPLETED].includes(bookingInfo.status);
  const { carOrderLines, discountOrderLines, otherOrderLines } = bookingInfo.order.orderLines;

  return (
    <TopContainer>
      <InnerContainer>
        <BookingContainer isMobile={isMobile}>
          <Typography variant='h1' mb={2}>
            Din booking
          </Typography>

          {hasPaymentLeft && (
            <div style={{ marginBottom: '2rem' }}>
              <OrderSummary
                showHeader={false}
                carItemLines={carOrderLines}
                discountLines={discountOrderLines}
                otherOrderLines={otherOrderLines}
              />
            </div>
          )}
          <Stack direction='column' style={{ width: '100%' }}>
            <ContentBasedOnStatus
              status={bookingInfo.status}
              bookingInfo={bookingInfo}
              currentEstimatedArrivalTime={currentEstimatedArrivalTime}
            />
          </Stack>
          {showFeedbackContent ? <Feedback bookingId={bookingInfo.id} /> : null}
          {isInvoiced ? (
            <PaymentInfo
              hasPayed={hasPayed}
              orderId={orderId as number}
              paymentMethod={bookingInfo.order.paymentMethod}
            />
          ) : null}
        </BookingContainer>
      </InnerContainer>
    </TopContainer>
  );
};

export default BookingInfo;
