import { Trans } from '@lingui/macro';
import { NoddiButton, NoddiDialog, NoddiIconButton, useIsMobile } from 'noddi-ui';
import { formatNorwegianCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import BookingSummary from '../../../../components/BookingFlow/Summary';
import useBookingSummaryProps from '../../../../hooks/useBookingSummaryProps';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';

const ShoppingCart = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: membershipData } = useMembershipPrograms();
  const isMobile = useIsMobile();
  const { totalPrice } = useBookingSummaryProps({ membershipData });

  if (totalPrice === 0) {
    return <></>;
  }

  return (
    <>
      <NoddiDialog
        sx={{ zIndex: 1400, padding: '10px' }}
        contentProps={{ classes: { root: '!p-4 sm:!p-8' } }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        maxWidth='md'
        title='Service Area'
      >
        <div className='relative flex w-full flex-col gap-4 sm:min-w-100'>
          <div className='flex w-full items-center gap-5'>
            <h2 className='text-7  sm:text-8'>
              <Trans>Your shopping cart</Trans>
            </h2>

            <div className='ml-auto'>
              <NoddiIconButton
                iconName='Cross'
                onClick={() => setIsDialogOpen(false)}
                iconSize='medium'
                variant='tertiary'
              />
            </div>
          </div>
          <BookingSummary membershipData={membershipData} />
        </div>
      </NoddiDialog>

      <NoddiButton
        className={`${isMobile ? 'absolute bottom-20 left-1/2 -translate-x-1/2 -translate-y-1/2' : 'ml-auto'}`}
        variant='secondary'
        onClick={() => {
          setIsDialogOpen(true);
        }}
        startIcon='ShoppingCart'
      >
        <span className='ml-4'>{formatNorwegianCurrencyAmount(totalPrice, 0)}</span>
      </NoddiButton>
    </>
  );
};

export default ShoppingCart;
