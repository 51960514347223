import { IconSvgProps } from './svgtype';

const Cup = ({ color, ...rest }: IconSvgProps) => (
  <svg width={32} height={32} fill='none' {...rest}>
    <path
      stroke={color}
      strokeWidth={color}
      d='M16 20.267c-6.144 0-7.232-6.123-7.425-10.98-.054-1.351-.08-2.026.427-2.651s1.115-.728 2.33-.933A28.132 28.132 0 0 1 16 5.333c1.903 0 3.47.168 4.669.37 1.215.205 1.822.308 2.33.933.507.625.48 1.3.426 2.65-.192 4.858-1.28 10.98-7.425 10.98Z'
    />
    <path
      stroke={color}
      strokeWidth={2}
      d='m23.467 8.533 1.012.338c1.056.352 1.584.528 1.886.947.302.419.302.975.302 2.088v.078c0 .918 0 1.377-.221 1.753-.221.375-.623.598-1.425 1.044l-3.154 1.752M8.533 8.533l-1.012.338c-1.056.352-1.584.528-1.886.947-.302.419-.302.975-.302 2.088v.078c0 .918 0 1.377.221 1.753.221.375.623.598 1.425 1.044l3.154 1.752'
    />
    <path stroke={color} strokeLinecap='round' strokeWidth={2} d='M16 21.333v2.134' />
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M19.733 26.667h-7.466l.362-1.81c.1-.498.537-.857 1.046-.857h4.65c.509 0 .947.359 1.047.858l.361 1.809Z'
    />
    <path stroke={color} strokeLinecap='round' strokeWidth={2} d='M22.4 26.667H9.6' />
  </svg>
);
export { Cup };
