import { Trans, t } from '@lingui/macro';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { trimAllWhiteCharacters } from 'noddi-util';
import { KeyboardEvent, useEffect } from 'react';
import 'react-international-phone/style.css';
import styled from 'styled-components';

import { ApiErrorMessage, NoddiButton, SectionSpacer, colors } from '../../../';
import { NoddiPhoneInput } from '../../Elements/Form/NoddiFormPhoneInput';
import { CallBackObject } from './SMSLogin';
import { isPhoneNumberValid } from './isPhoneNumberValid';

const SmsVerificationCodeNotReceived = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

type PhoneNumberFormProps = {
  mobileNumber: string;
  setMobileNumber: (mobileNumber: string) => void;
  setCodeSentSuccessfully: (isSuccessful: true) => void;
  callBackObject?: CallBackObject;
};

const PhoneNumberForm = ({
  mobileNumber,
  setMobileNumber,
  setCodeSentSuccessfully,
  callBackObject
}: PhoneNumberFormProps) => {
  const phoneNumberDto = ({ urlEncode = false }: { urlEncode?: boolean }) => {
    const serializedMobileNumber = trimAllWhiteCharacters(mobileNumber);

    return urlEncode ? encodeURIComponent(serializedMobileNumber) : serializedMobileNumber;
  };

  const { isSuccess, isLoading, isRefetching, refetch, error } = noddiAsync.useGet({
    type: URLKeys.getPhoneNumberVerification,
    input: {
      phoneNumber: phoneNumberDto({ urlEncode: true })
    },
    queryConfig: {
      enabled: false
    }
  });

  useEffect(() => {
    if (isSuccess) {
      setCodeSentSuccessfully(true);
    }
  }, [isSuccess]);

  const sendPhoneNumberVerification = () => {
    refetch();
  };

  const phoneNumberIsValid = () => isPhoneNumberValid(mobileNumber);

  const isFetching = isLoading || isRefetching;

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (phoneNumberIsValid() && event.key === 'Enter') {
      sendPhoneNumberVerification();
    }
  };

  return (
    <Formik
      initialValues={{
        mobileNumber
      }}
      onSubmit={sendPhoneNumberVerification}
    >
      {() => (
        <Form>
          <Typography variant='body1'>
            <Trans>Type your phone number, and we'll send you an SMS containing a PIN for verification</Trans>
          </Typography>

          <SectionSpacer variant='small'>
            <NoddiPhoneInput fullWidth onChange={setMobileNumber} value={mobileNumber} onKeyDown={onKeyDown} />
          </SectionSpacer>

          {isSuccess ? (
            <SmsVerificationCodeNotReceived>
              <Typography style={{ margin: 'auto 0' }} variant='body1'>
                <Trans>Har du ikke mottatt koden?</Trans>
              </Typography>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: 'auto 0' }}>
                  {isFetching && <CircularProgress size={20} className='text-primary-darkPurple' />}
                </div>
                <LoadingButton
                  type='submit'
                  disabled={!phoneNumberIsValid() || isFetching}
                  style={{ fontSize: '12px', color: colors.primary.orange }}
                  fullWidth={false}
                >
                  {isLoading ? '' : t`Resend`}
                </LoadingButton>
              </div>
            </SmsVerificationCodeNotReceived>
          ) : (
            <>
              {callBackObject ? (
                <div className='mt-8 flex w-full justify-end gap-4'>
                  <NoddiButton
                    onClick={() => {
                      callBackObject.customCallback();
                    }}
                    variant='secondary'
                  >
                    {callBackObject.title}
                  </NoddiButton>
                  <NoddiButton type='submit' disabled={!phoneNumberIsValid()} loading={isLoading}>
                    <Trans>Send PIN</Trans>
                  </NoddiButton>
                </div>
              ) : (
                <div className='mt-8'>
                  <NoddiButton type='submit' disabled={!phoneNumberIsValid()} fullWidth loading={isLoading}>
                    <Trans>Send PIN</Trans>
                  </NoddiButton>
                </div>
              )}
              {error && (
                <div style={{ marginTop: 8 }}>
                  <ApiErrorMessage error={error} />
                </div>
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export { PhoneNumberForm };
