import { Trans } from '@lingui/macro';
import { Stack } from '@mui/material';
import { NoddiButton } from 'noddi-ui';

import { useTireHotelContext } from './BookingFlow/context';
import usePopulateStore from './BookingFlow/hooks/usePopulateStore';

const ConfirmStorageChangeButton = () => {
  const { prePopulateSessionStorageFromTireHotelContracts } = usePopulateStore();
  const { setDialogOpen, selectedTireHotelContracts, setSelectedAction } = useTireHotelContext();

  return (
    <Stack direction='row' gap={2}>
      <NoddiButton variant='secondary' onClick={() => setSelectedAction(null)}>
        <Trans>Cancel</Trans>
      </NoddiButton>
      <NoddiButton
        variant='primary'
        onClick={() => {
          setDialogOpen(true);
          prePopulateSessionStorageFromTireHotelContracts({ tireHotelContracts: selectedTireHotelContracts });
        }}
      >
        <Trans>Confirm</Trans>
      </NoddiButton>
    </Stack>
  );
};

export default ConfirmStorageChangeButton;
