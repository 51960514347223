/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import {
  BookingCalendarData,
  BookingGeneralDetails,
  BookingListItem,
  BookingMessage,
  BookingOverviewDetailsListType,
  BookingTimeWindow,
  BookingTimeWindowInput,
  BookingTimeWindowList,
  BookingTimeWindowStatus,
  BookingTimeWindowTemplate,
  BookingTimeWindowTemplatePostInput,
  BookingType,
  BulkUpdateBookingTimeWindowInput,
  Campaign,
  CampaignList,
  CapacityAndCancellationsInputType,
  CapacityContributionListItem,
  CapacityContributionStatus,
  CapacityContributionType,
  CapacityInterval,
  CarDetailType,
  CarPatchInputType,
  CarSize,
  CarWheel,
  CarWheelSet,
  CarWheelSetListItemType,
  CarWheelSetPatchInput,
  CarWheelSetStorageInformation,
  CarWheelStorageObject,
  CarWheelStorageObjectCreateInput,
  ClosedForCancellationsAtType,
  CommonRouteSettlementsInput,
  CouponGroup,
  CouponGroupList,
  CouponList,
  CouponTemplate,
  CouponTemplateList,
  DashboardData,
  GroupInfo,
  InternalCoupon,
  LicenseArea,
  LicenseAreaInput,
  LicenseAreaListItem,
  LicenseCategory,
  LicenseCategoryInputType,
  LicenseCategoryList,
  ListCapacityIntervalsInput,
  MarkWheelStoragePickupAsComplete,
  MembershipProgramListItem,
  Module,
  ModuleName,
  OptionsTypeInteger,
  Order,
  OrderDetailDataType,
  OrderTransaction,
  OrderTransactionDintero,
  Organization,
  OrganizationCreateInput,
  OrganizationUpdateInput,
  PaginatedBookingOverviewDetailsListInput,
  PaginatedBookingTimeWindowsInput,
  PaginatedCapacityContributionsInput,
  PaginatedInput,
  PaginatedResponse,
  PublicCoupon,
  RewardLevel,
  RewardLevelList,
  RouteOverview,
  RoutePlannerBookingsInputType,
  RoutePlannerCapacityContribution,
  RouteProblemsType,
  RouteSettlement,
  RouteSettlementInfo,
  RouteSolution,
  RouteSolutionResultsType,
  RouteStatusForAdmin,
  SalesItemCategory,
  SalesItemCreateInputType,
  SalesItemListItem,
  SalesItemProps,
  SalesItemUpdateInputType,
  SelectedRouteSolution,
  ServiceArea,
  ServiceAreaBaseInput,
  ServiceAreaInput,
  ServiceAreaLicenseCategory,
  ServiceAreaListItem,
  ServiceAreaNotificationType,
  ServiceAreaPatchInput,
  ServiceCategory,
  ServiceCategoryBaseInput,
  ServiceCategoryPatchInput,
  ServiceContractListItem,
  ServiceNotificationAreaType,
  ServiceOrganization,
  ServiceSchedulerTemplate,
  ServiceSchedulerTemplateCreateInput,
  ServiceSchedulerTemplateUpdateInput,
  ServiceWorkerAddressPair,
  ServiceWorkerAddressPairPatchInput,
  SingleRouteSolutionDataType,
  StartRoutePlannerInputType,
  StartRoutePlannerResponseType,
  StorageArea,
  StorageAreaCreateInput,
  StorageAreaListItem,
  StorageAreaUpdateInput,
  StorageFacility,
  StorageFacilityCreateInput,
  StorageFacilityUpdateInput,
  StorageUnit,
  StorageUnitCreateInput,
  StorageUnitListItem,
  StorageUnitType,
  StorageUnitUpdateInput,
  TireHotelContractListItem,
  UnpaidBooking,
  UserGroup,
  UserGroupListItem,
  UserGroupPatchInput,
  WheelStorageAgreementFilters,
  WheelStoragePickupBookingItem,
  WheelStoragePickupsParams
} from '../types';
import { DeepPartial, downloadToExcel } from '../utils';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const AdminURLKeys = {
  getRouteStatus: 'getRouteStatus',
  getRouteSettlement: 'getRouteSettlement',
  getRouteSettlements: 'getRouteSettlements',
  getRouteSettlementsForUpdate: 'getRouteSettlementsForUpdate',
  patchRouteSettlement: 'patchRouteSettlement',
  postRouteSettlementsToExcel: 'postRouteSettlementsToExcel',
  postSetPaidAtRouteSettlements: 'postSetPaidAtRouteSettlements',
  getUnpaidBookings: 'getUnpaidBookings',
  getTotalUnpaidBookingsAmount: 'getTotalUnpaidBookingsAmount',
  getRouteSolution: 'getRouteSolution',
  getCalendarBookings: 'getCalendarBookings',
  getLicenseAreas: 'getLicenseAreas',
  getLicenseArea: 'getLicenseArea',
  postLicenseArea: 'postLicenseArea',
  patchLicenseArea: 'patchLicenseArea',
  getServiceAreas: 'getServiceAreas',
  getServiceArea: 'getServiceArea',
  postServiceArea: 'postServiceArea',
  postServiceAreaNew: 'postServiceAreaNew',
  patchServiceArea: 'patchServiceArea',
  patchServiceAreaNew: 'patchServiceAreaNew',
  getLicenseCategories: 'getLicenseCategories',
  getPaginatedLicenseCategories: 'getPaginatedLicenseCategories',
  getPaginatedServiceAreaLicenseCategories: 'getPaginatedServiceAreaLicenseCategories',
  getServiceAreaLicenseCategories: 'getServiceAreaLicenseCategories',
  postServiceAreaLicenseCategory: 'postServiceAreaLicenseCategory',
  getLicenseCategory: 'getLicenseCategory',
  postLicenseCategory: 'postLicenseCategory',
  patchLicenseCategory: 'patchLicenseCategory',
  deleteLicenseCategory: 'deleteLicenseCategory',
  getBookingTimeWindowTemplates: 'getBookingTimeWindowTemplates',
  postBookingTimeWindowTemplate: 'postBookingTimeWindowTemplate',
  patchBookingTimeWindowTemplate: 'patchBookingTimeWindowTemplate',
  getServiceOrganizations: 'getServiceOrganizations',
  getPaginatedServiceOrganizations: 'getPaginatedServiceOrganizations',
  getServiceOrganization: 'getServiceOrganization',
  postServiceOrganization: 'postServiceOrganization',
  patchServiceOrganization: 'patchServiceOrganization',
  getBookingMessages: 'getBookingMessages',
  getBookingRouteDetails: 'getBookingRouteDetails',
  getBookingGeneralDetails: 'getBookingGeneralDetails',
  getBookingOrder: 'getBookingOrder',
  patchBookingDetails: 'patchBookingDetails',
  getGroupMembersById: 'getGroupMembersById',
  deleteBookingItem: 'deleteBookingItem',
  postAddDiscountToOrderLine: 'postAddDiscountToOrderLine',
  getModules: 'getModules',
  getModuleNames: 'getModuleNames',
  postModule: 'postModule',
  postPermission: 'postPermission',
  getCampaigns: 'getCampaigns',
  getCampaign: 'getCampaign',
  postCampaign: 'postCampaign',
  patchCampaign: 'patchCampaign',
  getCouponGroups: 'getCouponGroups',
  getPaginatedCouponGroups: 'getPaginatedCouponGroups',
  getCouponGroup: 'getCouponGroup',
  postCouponGroup: 'postCouponGroup',
  patchCouponGroup: 'patchCouponGroup',
  getCouponTemplates: 'getCouponTemplates',
  getPaginatedCouponTemplates: 'getPaginatedCouponTemplates',
  getCouponTemplate: 'getCouponTemplate',
  postCouponTemplate: 'postCouponTemplate',
  patchCouponTemplate: 'patchCouponTemplate',
  getPaginatedCoupons: 'getPaginatedCoupons',
  getPaginatedUserGroupCoupons: 'getPaginatedUserGroupCoupons',
  getCoupon: 'getCoupon',
  postCoupon: 'postCoupon',
  patchCoupon: 'patchCoupon',
  getCampaignRewardLevels: 'getCampaignRewardLevels',
  getPaginatedCampaignRewardLevels: 'getPaginatedCampaignRewardLevels',
  getCampaignRewardLevel: 'getCampaignRewardLevel',
  postCampaignRewardLevel: 'postCampaignRewardLevel',
  patchCampaignRewardLevel: 'patchCampaignRewardLevel',
  getPaginatedUserGroups: 'getPaginatedUserGroups',
  getUserGroup: 'getUserGroup',
  postUserGroup: 'postUserGroup',
  patchUserGroup: 'patchUserGroup',
  postOrganization: 'postOrganization',
  patchOrganization: 'patchOrganization',
  getSalesItems: 'getSalesItems',
  getPaginatedSalesItems: 'getPaginatedSalesItems',
  getSalesItem: 'getSalesItem',
  postSalesItem: 'postSalesItem',
  patchSalesItem: 'patchSalesItem',
  getServiceCategoryList: 'getServiceCategoryList',
  getCarSizesList: 'getCarSizesList',
  getUiCategories: 'getUiCategories',
  getSalesCategories: 'getSalesCategories',
  getSalesItemsTypes: 'getSalesItemsTypes',
  patchCarDetails: 'patchCarDetails',
  getOrderTransaction: 'getOrderTransaction',
  postCaptureOrderTransactionDintero: 'postCaptureOrderTransactionDintero',
  postRefundOrderTransactionDintero: 'postRefundOrderTransactionDintero',
  postPartialRefundOrderTransactionDintero: 'postPartialRefundOrderTransactionDintero',
  postVoidOrderTransactionDintero: 'postVoidOrderTransactionDintero',
  getServiceCategory: 'getServiceCategory',
  postServiceCategory: 'postServiceCategory',
  patchServiceCategory: 'patchServiceCategory',
  deleteServiceCategory: 'deleteServiceCategory',
  getPaginatedBookingTimeWindows: 'getPaginatedBookingTimeWindows',
  getBookingTimeWindow: 'getBookingTimeWindow',
  postBookingTimeWindow: 'postBookingTimeWindow',
  patchBookingTimeWindow: 'patchBookingTimeWindow',
  patchBulkUpdateBookingTimeWindow: 'patchBulkUpdateBookingTimeWindow',
  getBookingTimeWindowStatuses: 'getBookingTimeWindowStatuses',
  getPaginatedBookingTimeWindowBookings: 'getPaginatedBookingTimeWindowBookings',
  getCarWheel: 'getCarWheel',
  getCarWheelsByIds: 'getCarWheelsByIds',
  getCarWheelSet: 'getCarWheelSet',
  getCarWheelSetStorageInformation: 'getCarWheelSetStorageInformation',
  patchCarWheelSet: 'patchCarWheelSet',
  postCarWheelStorageObjects: 'postCarWheelStorageObjects',
  getPaginatedStorageObjectsForCarWheel: 'getPaginatedStorageObjectsForCarWheel',
  getPaginatedStorageFacilities: 'getPaginatedStorageFacilities',
  getStorageFacilities: 'getStorageFacilities',
  getStorageFacility: 'getStorageFacility',
  postStorageFacility: 'postStorageFacility',
  patchStorageFacility: 'patchStorageFacility',
  getPaginatedStorageAreasForFacility: 'getPaginatedStorageAreasForFacility',
  getStorageArea: 'getStorageArea',
  postStorageArea: 'postStorageArea',
  patchStorageArea: 'patchStorageArea',
  getPaginatedStorageUnits: 'getPaginatedStorageUnits',
  getStorageUnit: 'getStorageUnit',
  postStorageUnit: 'postStorageUnit',
  patchStorageUnit: 'patchStorageUnit',
  getPaginatedStorageUnitsForArea: 'getPaginatedStorageUnitsForArea',
  getStorageUnitTypes: 'getStorageUnitTypes',
  postPickUpCarWheelSet: 'postPickUpCarWheelSet',
  getPaginatedCarWheelSetForCar: 'getPaginatedCarWheelSetForCar',
  getPaginatedUserGroupMembershipProgram: 'getPaginatedUserGroupMembershipProgram',
  getPaginatedCapacityContributions: 'getPaginatedCapacityContributions',
  getCapacityContributionStatuses: 'getCapacityContributionStatuses',
  getCapacityContributionsForRoute: 'getCapacityContributionsForRoute',
  getPaginatedServiceCategories: 'getPaginatedServiceCategories',
  getOrderLine: 'getOrderLine',
  deleteOrderLine: 'deleteOrderLine',
  getPaginatedRouteProblems: 'getPaginatedRouteProblems',
  getSolution: 'getSolution',
  getRouteSolutions: 'getRouteSolutions',
  getPaginatedCapacityContributionsForRoutePlanner: 'getPaginatedCapacityContributionsForRoutePlanner',
  getRoutePlannerBookings: 'getRoutePlannerBookings',
  postCloseCapacity: 'postCloseCapacity',
  postCloseCancellations: 'postCloseCancellations',
  postStartRoutePlanner: 'postStartRoutePlanner',
  getCapacityLicenseAreaFromAreaAndDate: 'getCapacityLicenseAreaFromAreaAndDate',
  getPaginatedServiceAreaNotification: 'getPaginatedServiceAreaNotification',
  getServiceAreaNotification: 'getServiceAreaNotification',
  postServiceAreaNotification: 'postServiceAreaNotification',
  patchServiceAreaNotification: 'patchServiceAreaNotification',
  deleteServiceAreaNotification: 'deleteServiceAreaNotification',
  postPopulateDbWithExternalRouteSolutions: 'postPopulateDbWithExternalRouteSolutions',
  postSelectRouteSolution: 'postSelectRouteSolution',
  getPaginatedBookingOverviewDetailsList: 'getPaginatedBookingOverviewDetailsList',
  postUpdateAdminCommentForRouteItem: 'postUpdateAdminCommentForRouteItem',
  postUpdateUserCommentForRouteItem: 'postUpdateUserCommentForRouteItem',
  postUpdateWorkerCommentForRouteItem: 'postUpdateWorkerCommentForRouteItem',
  getAdminDashboardBookings: 'getAdminDashboardBookings',
  postCancelBooking: 'postCancelBooking',
  postSendRouteDelaySms: 'postSendRouteDelaySms',
  postChangeCapacityContribution: 'postChangeCapacityContribution',
  getPaginatedServiceContracts: 'getPaginatedServiceContracts',
  getPaginatedServiceSchedulerTemplates: 'getPaginatedServiceSchedulerTemplates',
  getServiceSchedulerTemplate: 'getServiceSchedulerTemplate',
  postServiceSchedulerTemplate: 'postServiceSchedulerTemplate',
  patchServiceSchedulerTemplate: 'patchServiceSchedulerTemplate',
  getSelectedRouteSolutionForLicenseArea: 'getSelectedRouteSolutionForLicenseArea',
  getCapacityNumberOfDaysAhead: 'getCapacityNumberOfDaysAhead',
  getWheelStoragePickupBookingItems: 'getWheelStoragePickupBookingItems',
  getWheelStorageAgreements: 'getWheelStorageAgreements',
  postMarkWheelsAsPickedUp: 'postMarkWheelsAsPickedUp',
  getCapacityIntervals: 'getCapacityIntervals',
  getPaginatedServiceWorkerAddressPairs: 'getPaginatedServiceWorkerAddressPairs',
  getServiceWorkerAddressPair: 'getServiceWorkerAddressPair',
  patchServiceWorkerAddressPair: 'patchServiceWorkerAddressPair',
  getSuggestedServiceAreasForAddressPair: 'getSuggestedServiceAreasForAddressPair'
} as const;

export type AdminURLKeysType = keyof typeof AdminURLKeys;

type InputDataOrVoid<Type extends AdminURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends AdminURLKeysType> = AdminServerRequests[urlKey]['input'];

export const getAdminUrls: {
  [key in AdminURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  getRouteStatus: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/status/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteSettlements: {
    getUrl: (queryParams) => {
      return getNoddiUrl({
        path: `${apiVersion}route-settlements/`,
        queryParams
      });
    },
    method: FetchMethods.get
  },
  getRouteSettlementsForUpdate: {
    getUrl: ({ page, pageSize, search, type, fromDate, toDate, paymentType }) => {
      return getNoddiUrl({
        path: `${apiVersion}route-settlements/`,
        queryParams: {
          page,
          pageSize,
          search,
          type,
          fromDate,
          toDate,
          paymentType
        }
      });
    },
    method: FetchMethods.get
  },
  getRouteSettlement: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/${id}`
      }),
    method: FetchMethods.get
  },
  patchRouteSettlement: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/${id}`
      }),
    getBody: ({ extraPayment, extraPaymentDescription }) => ({
      extraPayment,
      extraPaymentDescription
    }),
    method: FetchMethods.patch
  },
  postSetPaidAtRouteSettlements: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/update-paid-at`
      }),
    getBody: ({ routeSettlementIds, paidAt }) => ({
      routeSettlementIds,
      paidAt
    }),
    method: FetchMethods.post
  },
  postRouteSettlementsToExcel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/to-excel`
      }),
    getBody: ({ routeSettlementIds }) => ({ routeSettlementIds }),
    handleRes: async (res) => {
      await downloadToExcel(res);
    },
    method: FetchMethods.post,
    responseType: 'arraybuffer'
  },
  getUnpaidBookings: {
    getUrl: ({ fromDate, toDate, page, pageSize }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unpaid/`,
        queryParams: { fromDate, toDate, page, pageSize }
      }),
    method: FetchMethods.get
  },
  getTotalUnpaidBookingsAmount: {
    getUrl: ({ fromDate, toDate }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unpaid-amount/`,
        queryParams: { fromDate, toDate }
      }),
    method: FetchMethods.get
  },
  getRouteSolution: {
    getUrl: ({ routeSolutionId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-solutions/${routeSolutionId}`
      }),
    method: FetchMethods.get
  },
  getCalendarBookings: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/status-counts/`,
        queryParams: { ...args }
      }),
    skipConvertingToCamelCase: true,
    method: FetchMethods.get
  },
  getLicenseAreas: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}license-areas`
      }),
    method: FetchMethods.get
  },
  getLicenseArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}license-areas/${id}`
      }),
    method: FetchMethods.get
  },
  postLicenseArea: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}license-areas/`
      }),
    getBody: (licenseAreaInput) => licenseAreaInput,
    method: FetchMethods.post
  },
  patchLicenseArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}license-areas/${id}`
      }),
    getBody: (licenseAreaInput) => licenseAreaInput,
    method: FetchMethods.patch
  },
  getServiceAreas: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/`
      }),
    method: FetchMethods.get
  },
  getServiceArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceArea: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/`
      }),
    getBody: (serviceAreaInput) => serviceAreaInput,
    method: FetchMethods.post
  },
  postServiceAreaNew: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchServiceArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/${id}`
      }),
    getBody: (serviceAreaInput) => serviceAreaInput,
    method: FetchMethods.patch
  },
  patchServiceAreaNew: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-areas/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getLicenseCategories: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}license-categories/`
      }),
    method: FetchMethods.get
  },
  getPaginatedLicenseCategories: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}license-categories/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceAreaLicenseCategories: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-area-license-categories/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceAreaLicenseCategories: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-area-license-categories/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postServiceAreaLicenseCategory: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-area-license-categories/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getLicenseCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}license-categories/${id}`
      }),
    method: FetchMethods.get
  },
  postLicenseCategory: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}license-categories/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchLicenseCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}license-categories/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteLicenseCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}license-categories/${id}`
      }),
    method: FetchMethods.delete
  },
  getBookingTimeWindowTemplates: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postBookingTimeWindowTemplate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchBookingTimeWindowTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-window-templates/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getServiceOrganizations: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-organizations/`
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceOrganizations: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-organizations/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getServiceOrganization: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-organizations/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceOrganization: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-organizations/`
      }),
    getBody: ({ userGroupId }) => ({ userGroupId }),
    method: FetchMethods.post
  },
  patchServiceOrganization: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-organizations/${id}`
      }),
    getBody: ({ userGroupId }) => ({ userGroupId }),
    method: FetchMethods.patch
  },
  postMarkWheelsAsPickedUp: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/mark-wheels-as-picked-up/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getBookingGeneralDetails: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/general-details/`
      }),
    method: FetchMethods.get
  },
  getBookingMessages: {
    getUrl: ({ bookingId, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/messages/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getBookingRouteDetails: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/route-details/`
      }),
    method: FetchMethods.get
  },
  getBookingOrder: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/order/`
      }),
    method: FetchMethods.get
  },
  patchBookingDetails: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}`
      }),
    getBody: (partialBooking) => partialBooking,
    method: FetchMethods.patch
  },
  getGroupMembersById: {
    getUrl: ({ groupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/list-members/${groupId}`
      }),
    method: FetchMethods.get
  },
  deleteBookingItem: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}`
      }),
    method: FetchMethods.delete
  },
  postAddDiscountToOrderLine: {
    getUrl: ({ orderId }) =>
      getNoddiUrl({
        path: `${apiVersion}order/${orderId}/add-discount`
      }),
    getBody: ({ bookingItemId, salesItemId, description, price }) => ({
      bookingItemId,
      salesItemId,
      description,
      price
    }),
    method: FetchMethods.post
  },
  getModules: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}modules/`
      }),
    method: FetchMethods.get
  },
  getModuleNames: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}modules/names`
      }),
    method: FetchMethods.get
  },
  postModule: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}modules/`
      }),
    getBody: ({ name }) => ({ name }),
    method: FetchMethods.post
  },
  postPermission: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}permissions/`
      }),
    getBody: ({ name, description, moduleId }) => ({ name, description, moduleId }),
    method: FetchMethods.post
  },
  getCampaigns: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCampaign: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/${id}`
      }),
    method: FetchMethods.get
  },
  postCampaign: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/`
      }),
    getBody: ({ name, slug, userGroupId, couponGroupId, rewardLevelIds, isForNewUserOnly }) => ({
      name,
      slug,
      userGroupId,
      couponGroupId,
      rewardLevelIds,
      isForNewUserOnly
    }),
    method: FetchMethods.post
  },
  patchCampaign: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaigns/${id}`
      }),
    getBody: ({ name, slug, userGroupId, couponGroupId, rewardLevelIds, isForNewUserOnly }) => ({
      name,
      slug,
      userGroupId,
      couponGroupId,
      rewardLevelIds,
      isForNewUserOnly
    }),
    method: FetchMethods.patch
  },
  getCouponGroups: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCouponGroups: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCouponGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/${id}`
      }),
    method: FetchMethods.get
  },
  postCouponGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/`
      }),
    getBody: ({ nameInternal, descriptionPublic, expiresAt, couponTemplateIds }) => ({
      nameInternal,
      descriptionPublic,
      expiresAt,
      couponTemplateIds
    }),
    method: FetchMethods.post
  },
  patchCouponGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-groups/${id}`
      }),
    getBody: ({ nameInternal, descriptionPublic, expiresAt, couponTemplateIds }) => ({
      nameInternal,
      descriptionPublic,
      expiresAt,
      couponTemplateIds
    }),
    method: FetchMethods.patch
  },
  getCouponTemplates: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCouponTemplates: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCouponTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/${id}`
      }),
    method: FetchMethods.get
  },
  postCouponTemplate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/`
      }),
    getBody: ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiryDaysAfterReceiving,
      expiresAt,
      validForSalesItemIds
    }) => ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiryDaysAfterReceiving,
      expiresAt,
      validForSalesItemIds
    }),
    method: FetchMethods.post
  },
  patchCouponTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupon-templates/${id}`
      }),
    getBody: ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiryDaysAfterReceiving,
      expiresAt,
      validForSalesItemIds
    }) => ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiryDaysAfterReceiving,
      expiresAt,
      validForSalesItemIds
    }),
    method: FetchMethods.patch
  },
  getPaginatedCoupons: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}coupons/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupCoupons: {
    getUrl: ({ id, page, pageSize, search, includeUsed, includeUsedOnBookingIds }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/coupons/`,
        queryParams: { page, pageSize, search, includeUsed, includeUsedOnBookingIds }
      }),
    method: FetchMethods.get
  },
  getCoupon: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupons/${id}`
      }),
    method: FetchMethods.get
  },
  postCoupon: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}coupons/`
      }),
    getBody: ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiresAt,
      validForSalesItemIds,
      userGroupId
    }) => ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiresAt,
      validForSalesItemIds,
      userGroupId
    }),
    method: FetchMethods.post
  },
  patchCoupon: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}coupons/${id}`
      }),
    getBody: ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiresAt,
      validForSalesItemIds
    }) => ({
      nameInternal,
      namePublic,
      descriptionPublic,
      amount,
      amountPercentage,
      expiresAt,
      validForSalesItemIds
    }),
    method: FetchMethods.patch
  },
  getCampaignRewardLevels: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCampaignRewardLevels: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCampaignRewardLevel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/${id}`
      }),
    method: FetchMethods.get
  },
  postCampaignRewardLevel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/`
      }),
    getBody: ({ nameInternal, descriptionInternal, minNumResponses, couponGroupId }) => ({
      nameInternal,
      descriptionInternal,
      minNumResponses,
      couponGroupId
    }),
    method: FetchMethods.post
  },
  patchCampaignRewardLevel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}campaign-reward-levels/${id}`
      }),
    getBody: ({ nameInternal, descriptionInternal, minNumResponses, couponGroupId }) => ({
      nameInternal,
      descriptionInternal,
      minNumResponses,
      couponGroupId
    }),
    method: FetchMethods.patch
  },
  getPaginatedUserGroups: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getUserGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}`
      }),
    method: FetchMethods.get
  },
  postUserGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/`
      }),
    getBody: ({ name, userId }) => ({ name, userId }),
    method: FetchMethods.post
  },
  patchUserGroup: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  postOrganization: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}organizations/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchOrganization: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}organizations/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getSalesItems: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/`
      }),
    method: FetchMethods.get
  },
  getPaginatedSalesItems: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getSalesItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/${id}`
      }),
    method: FetchMethods.get
  },
  getWheelStoragePickupBookingItems: {
    getUrl: ({ page, pageSize, search, fromDate, toDate, bookingStatusIds }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/wheel-storage-pickups/`,
        queryParams: {
          page,
          pageSize,
          search,
          fromDate,
          toDate,
          bookingStatusIds
        }
      }),
    method: FetchMethods.get
  },
  getWheelStorageAgreements: {
    getUrl: (filters) =>
      getNoddiUrl({
        path: `${apiVersion}tire-hotel-contracts/`,
        queryParams: filters
      }),
    method: FetchMethods.get
  },
  postSalesItem: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchSalesItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getServiceCategoryList: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/`
      }),
    method: FetchMethods.get
  },
  getCarSizesList: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-sizes/`
      }),
    method: FetchMethods.get
  },
  getUiCategories: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/ui-categories/`
      }),
    method: FetchMethods.get
  },
  getSalesCategories: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-item-categories/`
      }),
    method: FetchMethods.get
  },
  getSalesItemsTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/types/`
      }),
    method: FetchMethods.get
  },
  patchCarDetails: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getOrderTransaction: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions/${id}`
      }),
    method: FetchMethods.get
  },
  postCaptureOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/capture`
      }),
    method: FetchMethods.post
  },
  postRefundOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/refund`
      }),
    method: FetchMethods.post
  },
  postPartialRefundOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/partial-refund`
      }),
    getBody: ({ amount }) => ({ amount }),
    method: FetchMethods.post
  },
  postVoidOrderTransactionDintero: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}order-transactions-dintero/${id}/void`
      }),
    method: FetchMethods.post
  },
  getServiceCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceCategory: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getPaginatedBookingTimeWindows: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/`,
        queryParams: { ...args }
      }),
    method: FetchMethods.get
  },
  getBookingTimeWindow: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/${id}`
      }),
    method: FetchMethods.get
  },
  postBookingTimeWindow: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchServiceCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  patchBookingTimeWindow: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  patchBulkUpdateBookingTimeWindow: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/bulk-update`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteServiceCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.delete
  },
  getBookingTimeWindowStatuses: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/statuses`
      }),
    method: FetchMethods.get
  },
  getPaginatedBookingTimeWindowBookings: {
    getUrl: ({ id, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/${id}/bookings/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getCarWheel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheels/${id}`
      }),
    method: FetchMethods.get
  },
  getCarWheelsByIds: {
    getUrl: ({ ids }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheels/`,
        queryParams: { ids }
      }),
    method: FetchMethods.get
  },
  getCarWheelSet: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetStorageInformation: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}/storage-information`
      }),
    method: FetchMethods.get
  },
  patchCarWheelSet: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  postCarWheelStorageObjects: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-storage-objects/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getPaginatedStorageObjectsForCarWheel: {
    getUrl: ({ id, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheels/${id}/storage-objects/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedStorageFacilities: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getStorageFacilities: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/`
      }),
    method: FetchMethods.get
  },
  getStorageFacility: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${id}`
      }),
    method: FetchMethods.get
  },
  postStorageFacility: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchStorageFacility: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedStorageAreasForFacility: {
    getUrl: ({ id, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-facilities/${id}/storage-areas/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getStorageArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}`
      }),
    method: FetchMethods.get
  },
  postStorageArea: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchStorageArea: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedStorageUnits: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getStorageUnit: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/${id}`
      }),
    method: FetchMethods.get
  },
  postStorageUnit: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchStorageUnit: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getPaginatedStorageUnitsForArea: {
    getUrl: ({ id, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/${id}/storage-units/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getStorageUnitTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-unit-types/`
      }),
    method: FetchMethods.get
  },
  postPickUpCarWheelSet: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/${id}/pick-up/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getPaginatedCarWheelSetForCar: {
    getUrl: ({ id, page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/wheel-sets/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupMembershipProgram: {
    getUrl: ({ id, page, pageSize }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/membership-programs/`,
        queryParams: { page, pageSize }
      }),
    method: FetchMethods.get
  },
  getPaginatedCapacityContributions: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/`,
        queryParams: { ...args }
      }),
    method: FetchMethods.get
  },
  getCapacityContributionStatuses: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/statuses`
      }),
    method: FetchMethods.get
  },
  getCapacityContributionsForRoute: {
    getUrl: ({ routeId, serviceOrganizationId }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/for-route/`,
        queryParams: { routeId, serviceOrganizationId }
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceCategories: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-categories/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getOrderLine: {
    getUrl: ({ orderLineId }) =>
      getNoddiUrl({
        path: `${apiVersion}order-lines/${orderLineId}`
      }),
    method: FetchMethods.get
  },
  deleteOrderLine: {
    getUrl: ({ orderLineId }) =>
      getNoddiUrl({
        path: `${apiVersion}order-lines/${orderLineId}`
      }),
    getBody: (args) => args,
    method: FetchMethods.delete
  },
  getPaginatedRouteProblems: {
    getUrl: ({ page, pageSize, date, licenseAreaId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/`,
        queryParams: { page, pageSize, date, licenseAreaId }
      }),
    method: FetchMethods.get
  },
  getSolution: {
    getUrl: ({ solutionId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/routes-by-solution/${solutionId}`
      }),
    method: FetchMethods.get
  },
  getRouteSolutions: {
    getUrl: ({ routeProblemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route_problems/${routeProblemId}`
      }),
    method: FetchMethods.get
  },
  getPaginatedCapacityContributionsForRoutePlanner: {
    getUrl: ({ page, pageSize, date, licenseArea }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/get-contributor-for-route-planner/`,
        queryParams: { page, pageSize, date, licenseArea }
      }),
    method: FetchMethods.get
  },
  getRoutePlannerBookings: {
    getUrl: ({ date, licenseArea }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/get-bookings-for-route-planner/`,
        queryParams: { date, licenseArea }
      }),
    method: FetchMethods.get
  },
  postCloseCapacity: {
    getUrl: ({ capacityLicenseAreaId }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-license-areas/${capacityLicenseAreaId}/close-for-bookings/`
      }),
    getBody: ({ date, licenseArea }) => ({
      date,
      licenseArea
    }),
    method: FetchMethods.post
  },
  postCloseCancellations: {
    getUrl: ({ capacityLicenseAreaId }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-license-areas/${capacityLicenseAreaId}/close-for-cancellations/`
      }),
    getBody: ({ date, licenseArea }) => ({
      date,
      licenseArea
    }),
    method: FetchMethods.post
  },
  postStartRoutePlanner: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/start-route-planner/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getCapacityLicenseAreaFromAreaAndDate: {
    getUrl: ({ date, licenseAreaId }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-license-areas/from-license-area-and-date/`,
        queryParams: { date, licenseAreaId }
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceAreaNotification: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-area-notifications/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getServiceAreaNotification: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-area-notifications/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceAreaNotification: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-area-notifications/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchServiceAreaNotification: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-area-notifications/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  deleteServiceAreaNotification: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-area-notifications/${id}`
      }),
    method: FetchMethods.delete
  },
  postPopulateDbWithExternalRouteSolutions: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-problems/${id}/fetch-route-planner-solutions/`
      }),
    method: FetchMethods.post
  },
  postSelectRouteSolution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}route-solutions/${id}/select/`
      }),
    method: FetchMethods.post
  },
  getPaginatedBookingOverviewDetailsList: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/for-calendar/`,
        queryParams: { ...args }
      }),
    method: FetchMethods.get
  },
  postUpdateAdminCommentForRouteItem: {
    getUrl: ({ routeId, routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/update-comments-admin/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  postUpdateUserCommentForRouteItem: {
    getUrl: ({ routeId, routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/update-comments-user/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  postUpdateWorkerCommentForRouteItem: {
    getUrl: ({ routeId, routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/update-comments-worker/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getAdminDashboardBookings: {
    getUrl: ({ ...args }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/admin-dashboard-data/`,
        queryParams: { ...args }
      }),
    method: FetchMethods.get
  },
  postCancelBooking: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/cancel/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getSelectedRouteSolutionForLicenseArea: {
    getUrl: ({ licenseAreaId, date }) =>
      getNoddiUrl({
        path: `${apiVersion}license-areas/${licenseAreaId}/selected-route-solution/`,
        queryParams: { date }
      }),
    method: FetchMethods.get
  },
  postSendRouteDelaySms: {
    getUrl: ({ routeId, routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/send-delay-sms/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  postChangeCapacityContribution: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/change-capacity-contribution/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  getPaginatedServiceContracts: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contracts/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceSchedulerTemplates: {
    getUrl: ({ page, pageSize, search }) =>
      getNoddiUrl({
        path: `${apiVersion}service-scheduler-templates/`,
        queryParams: { page, pageSize, search }
      }),
    method: FetchMethods.get
  },
  getServiceSchedulerTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-scheduler-templates/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceSchedulerTemplate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-scheduler-templates/`
      }),
    getBody: (args) => args,
    method: FetchMethods.post
  },
  patchServiceSchedulerTemplate: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-scheduler-templates/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getCapacityNumberOfDaysAhead: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-days/num-days-ahead/`
      }),
    method: FetchMethods.get
  },
  getCapacityIntervals: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-intervals/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedServiceWorkerAddressPairs: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceWorkerAddressPair: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/${id}`
      }),
    method: FetchMethods.get
  },
  patchServiceWorkerAddressPair: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/${id}`
      }),
    getBody: (args) => args,
    method: FetchMethods.patch
  },
  getSuggestedServiceAreasForAddressPair: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-address-pairs/${id}/suggested-service-areas/`
      }),
    method: FetchMethods.get
  }
} as const;

export interface AdminServerRequests {
  getRouteStatus: {
    input: {
      date?: string | null;
      licenseAreaId?: number | null;
      serviceOrganizationId?: number | null;
    };
    output: { routes: RouteStatusForAdmin[]; problemId: number; solutionId: number };
  };
  getRouteSettlements: {
    input: CommonRouteSettlementsInput;
    output: PaginatedResponse<RouteSettlementInfo[]>;
  };
  getRouteSettlementsForUpdate: {
    input: CommonRouteSettlementsInput;
    output: PaginatedResponse<RouteSettlementInfo[]>;
  };
  getRouteSettlement: {
    input: { id: string };
    output: RouteSettlement;
  };
  patchRouteSettlement: {
    input: {
      id: string;
      extraPayment: string;
      extraPaymentDescription: string;
    };
    output: RouteSettlement;
  };
  postRouteSettlementsToExcel: {
    input: { routeSettlementIds: number[] };
    output: ArrayBufferLike;
  };
  postSetPaidAtRouteSettlements: {
    input: { routeSettlementIds: number[]; paidAt: string };
    output: undefined;
  };
  postMarkWheelsAsPickedUp: {
    input: MarkWheelStoragePickupAsComplete;
    output: unknown;
  };
  getUnpaidBookings: {
    input: {
      fromDate?: string | null;
      toDate?: string | null;
      pageSize: number;
      page: number;
    };
    output: PaginatedResponse<UnpaidBooking[]>;
  };
  getTotalUnpaidBookingsAmount: {
    input: {
      fromDate?: string | null;
      toDate?: string | null;
    };
    output: number;
  };
  getRouteSolution: {
    input: {
      routeSolutionId?: string;
    };
    output: RouteSolution;
  };
  getSelectedRouteSolutionForLicenseArea: {
    input: {
      licenseAreaId: number;
      date: string;
    };
    output: SelectedRouteSolution | null;
  };
  getCalendarBookings: {
    input: {
      fromDate: string | null;
      toDate: string | null;
      licenseAreaIds: number | null;
      serviceOrganizationId?: number | null;
    };
    output: BookingCalendarData;
  };
  licenseArea: {
    input: {
      licenseAreaId: number;
    };
    output: LicenseArea;
  };
  getLicenseAreas: {
    input: undefined;
    output: LicenseAreaListItem[];
  };
  getLicenseArea: {
    input: { id: number | string | undefined };
    output: LicenseArea;
  };
  postLicenseArea: {
    input: LicenseAreaInput;
    output: LicenseArea;
  };
  patchLicenseArea: {
    input: LicenseAreaInput & { id: number | string | undefined };
    output: LicenseArea;
  };
  getServiceAreas: {
    input: undefined;
    output: ServiceAreaListItem[];
  };
  getServiceArea: {
    input: { id: number | string | undefined };
    output: ServiceArea;
  };
  postServiceArea: {
    input: ServiceAreaInput;
    output: ServiceArea;
  };
  postServiceAreaNew: {
    input: ServiceAreaBaseInput;
    output: AxiosResponse<ServiceArea>;
  };
  patchServiceArea: {
    input: ServiceAreaInput & { id: number | string | undefined };
    output: ServiceArea;
  };
  patchServiceAreaNew: {
    input: ServiceAreaPatchInput;
    output: ServiceArea;
  };
  getLicenseCategories: {
    input: undefined;
    output: LicenseCategory[];
  };
  getPaginatedLicenseCategories: {
    input: PaginatedInput;
    output: PaginatedResponse<LicenseCategoryList[]>;
  };
  getPaginatedServiceAreaLicenseCategories: {
    input: PaginatedInput & { serviceAreaId?: number };
    output: PaginatedResponse<ServiceAreaLicenseCategory[]>;
  };
  getServiceAreaLicenseCategories: {
    input: { serviceAreaId?: number };
    output: ServiceAreaLicenseCategory[];
  };
  postServiceAreaLicenseCategory: {
    input: { serviceAreaId: number; licenseCategoryIds: number[] };
    output: ServiceAreaLicenseCategory;
  };
  getLicenseCategory: {
    input: { id: number };
    output: LicenseCategory;
  };
  postLicenseCategory: {
    input: LicenseCategoryInputType;
    output: LicenseCategory;
  };
  patchLicenseCategory: {
    input: LicenseCategory;
    output: LicenseCategory;
  };
  deleteLicenseCategory: {
    input: { id: number };
    output: unknown;
  };
  getBookingTimeWindowTemplates: {
    input: { serviceAreaId?: number; licenseCategoryId?: number };
    output: BookingTimeWindowTemplate[];
  };
  postBookingTimeWindowTemplate: {
    input: BookingTimeWindowTemplatePostInput;
    output: BookingTimeWindowTemplate;
  };
  patchBookingTimeWindowTemplate: {
    input: BookingTimeWindowTemplate;
    output: BookingTimeWindowTemplate;
  };
  getServiceOrganizations: {
    input: undefined;
    output: ServiceOrganization[];
  };
  getPaginatedServiceOrganizations: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceOrganization[]>;
  };
  getServiceOrganization: {
    input: { id: number };
    output: ServiceOrganization;
  };
  postServiceOrganization: {
    input: { userGroupId: number };
    output: ServiceOrganization;
  };
  patchServiceOrganization: {
    input: { id: number; userGroupId: number };
    output: ServiceOrganization;
  };
  getBookingGeneralDetails: {
    input: { bookingId: number };
    output: BookingGeneralDetails;
  };
  getBookingMessages: {
    input: PaginatedInput & { bookingId: number };
    output: BookingMessage[];
  };
  getBookingRouteDetails: {
    input: { bookingId: number };
    output: RouteOverview;
  };
  getBookingOrder: {
    input: { bookingId: number };
    output: Order;
  };
  patchBookingDetails: {
    input: DeepPartial<BookingGeneralDetails>;
    output: unknown;
  };
  getGroupMembersById: {
    input: { groupId: number };
    output: GroupInfo;
  };
  deleteBookingItem: {
    input: { bookingItemId: number };
    output: unknown;
  };
  postAddDiscountToOrderLine: {
    input: {
      orderId: number;
      bookingItemId: number | null;
      salesItemId: number | null;
      description: string;
      price: number;
    };
    output: unknown;
  };
  getModules: {
    input: undefined;
    output: Module[];
  };
  getModuleNames: {
    input: undefined;
    output: PaginatedResponse<ModuleName[]>;
  };
  postModule: {
    input: { name: string };
    output: unknown;
  };
  postPermission: {
    input: { name: string; description: string; moduleId: number };
    output: unknown;
  };
  getCampaigns: {
    input: PaginatedInput;
    output: PaginatedResponse<CampaignList[]>;
  };
  getCampaign: {
    input: { id: number };
    output: Campaign;
  };
  postCampaign: {
    input: {
      name: string;
      slug: string;
      userGroupId: number;
      couponGroupId: number;
      rewardLevelIds: number[];
      isForNewUserOnly: boolean;
    };
    output: Campaign;
  };
  patchCampaign: {
    input: {
      id: number;
      name: string;
      slug: string;
      userGroupId: number;
      couponGroupId: number;
      rewardLevelIds: number[];
      isForNewUserOnly: boolean;
    };
    output: Campaign;
  };
  getCouponGroups: {
    input: undefined;
    output: CouponGroupList[];
  };
  getPaginatedCouponGroups: {
    input: PaginatedInput;
    output: PaginatedResponse<CouponGroupList[]>;
  };
  getCouponGroup: {
    input: { id: number };
    output: CouponGroup;
  };
  postCouponGroup: {
    input: {
      nameInternal: string;
      descriptionPublic: string;
      expiresAt?: string | null;
      couponTemplateIds: number[];
    };
    output: CouponGroup;
  };
  patchCouponGroup: {
    input: {
      id: number;
      nameInternal: string;
      descriptionPublic: string;
      expiresAt?: string | null;
      couponTemplateIds: number[];
    };
    output: CouponGroup;
  };
  getCouponTemplates: {
    input: undefined;
    output: CouponTemplateList[];
  };
  getPaginatedCouponTemplates: {
    input: PaginatedInput;
    output: PaginatedResponse<CouponTemplateList[]>;
  };
  getCouponTemplate: {
    input: { id: number };
    output: CouponTemplate;
  };
  postCouponTemplate: {
    input: {
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      amount?: number | null;
      amountPercentage?: number | null;
      expiryDaysAfterReceiving?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
    };
    output: CouponTemplate;
  };
  patchCouponTemplate: {
    input: {
      id: number;
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      amount?: number | null;
      amountPercentage?: number | null;
      expiryDaysAfterReceiving?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
    };
    output: CouponTemplate;
  };
  getPaginatedCoupons: {
    input: PaginatedInput;
    output: PaginatedResponse<CouponList[]>;
  };
  getPaginatedUserGroupCoupons: {
    input: PaginatedInput & { id: number; includeUsed?: boolean; includeUsedOnBookingIds: number[] };
    output: PaginatedResponse<PublicCoupon[]>;
  };
  getCoupon: {
    input: { id: number };
    output: InternalCoupon;
  };
  postCoupon: {
    input: {
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      amount?: number | null;
      amountPercentage?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
      userGroupId: number;
    };
    output: InternalCoupon;
  };
  patchCoupon: {
    input: {
      id: number;
      nameInternal: string;
      namePublic: string;
      descriptionPublic: string;
      amount?: number | null;
      amountPercentage?: number | null;
      expiresAt?: string | null;
      validForSalesItemIds?: number[] | null;
    };
    output: InternalCoupon;
  };
  getCampaignRewardLevels: {
    input: undefined;
    output: RewardLevelList[];
  };
  getPaginatedCampaignRewardLevels: {
    input: PaginatedInput;
    output: PaginatedResponse<RewardLevelList[]>;
  };
  getCampaignRewardLevel: {
    input: { id: number };
    output: RewardLevel;
  };
  postCampaignRewardLevel: {
    input: {
      nameInternal: string;
      descriptionInternal: string;
      minNumResponses: number;
      couponGroupId: number;
    };
    output: RewardLevel;
  };
  patchCampaignRewardLevel: {
    input: {
      id: number;
      nameInternal: string;
      descriptionInternal: string;
      minNumResponses: number;
      couponGroupId: number;
    };
    output: RewardLevel;
  };
  getPaginatedUserGroups: {
    input: PaginatedInput;
    output: PaginatedResponse<UserGroupListItem[]>;
  };
  getUserGroup: {
    input: { id: number };
    output: UserGroup;
  };
  postUserGroup: {
    input: { name: string; userId: number };
    output: AxiosResponse<UserGroupListItem>;
  };
  patchUserGroup: {
    input: UserGroupPatchInput;
    output: UserGroup;
  };
  postOrganization: {
    input: OrganizationCreateInput;
    output: Organization;
  };
  patchOrganization: {
    input: OrganizationUpdateInput;
    output: Organization;
  };
  getSalesItems: {
    input: undefined;
    output: SalesItemListItem[];
  };
  getPaginatedSalesItems: {
    input: PaginatedInput;
    output: PaginatedResponse<SalesItemListItem[]>;
  };
  getSalesItem: {
    input: { id: number };
    output: SalesItemProps;
  };
  postSalesItem: {
    input: SalesItemCreateInputType;
    output: SalesItemProps;
  };
  patchSalesItem: {
    input: SalesItemUpdateInputType;
    output: SalesItemProps;
  };
  getServiceCategoryList: {
    input: undefined;
    output: ServiceCategory[];
  };
  getCarSizesList: {
    input: undefined;
    output: CarSize[];
  };
  getUiCategories: {
    input: undefined;
    output: OptionsTypeInteger[];
  };
  getSalesCategories: {
    input: undefined;
    output: SalesItemCategory[];
  };
  getSalesItemsTypes: {
    input: undefined;
    output: OptionsTypeInteger[];
  };
  patchCarDetails: {
    input: CarPatchInputType;
    output: CarDetailType;
  };
  getOrderTransaction: {
    input: { id: string | undefined };
    output: OrderTransaction;
  };
  postCaptureOrderTransactionDintero: {
    input: { id: string | number | undefined };
    output: OrderTransactionDintero;
  };
  postRefundOrderTransactionDintero: {
    input: { id: string | number | undefined };
    output: OrderTransactionDintero;
  };
  postPartialRefundOrderTransactionDintero: {
    input: { id: string | number | undefined; amount: number };
    output: OrderTransactionDintero;
  };
  postVoidOrderTransactionDintero: {
    input: { id: string | number | undefined };
    output: OrderTransactionDintero;
  };
  getServiceCategory: {
    input: { id: number };
    output: ServiceCategory;
  };
  postServiceCategory: {
    input: ServiceCategoryBaseInput;
    output: ServiceCategory;
  };
  patchServiceCategory: {
    input: ServiceCategoryPatchInput;
    output: ServiceCategory;
  };
  deleteServiceCategory: {
    input: { id: number };
    output: undefined;
  };
  getPaginatedBookingTimeWindows: {
    input: PaginatedBookingTimeWindowsInput;
    output: PaginatedResponse<BookingTimeWindowList[]>;
  };
  getBookingTimeWindow: {
    input: { id: number };
    output: BookingTimeWindow;
  };
  postBookingTimeWindow: {
    input: BookingTimeWindowInput;
    output: BookingTimeWindow;
  };
  patchBookingTimeWindow: {
    input: BookingTimeWindowInput & { id: string | number | undefined };
    output: BookingTimeWindow;
  };
  patchBulkUpdateBookingTimeWindow: {
    input: BulkUpdateBookingTimeWindowInput;
    output: unknown;
  };
  getBookingTimeWindowStatuses: {
    input: undefined;
    output: BookingTimeWindowStatus[];
  };
  getPaginatedBookingTimeWindowBookings: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<BookingListItem[]>;
  };
  getCarWheel: {
    input: { id: number };
    output: CarWheel;
  };
  getCarWheelsByIds: {
    input: { ids: number[] };
    output: CarWheel[];
  };
  getCarWheelSet: {
    input: { id: number };
    output: CarWheelSet;
  };
  getCarWheelSetStorageInformation: {
    input: { id: number };
    output: CarWheelSetStorageInformation;
  };
  patchCarWheelSet: {
    input: CarWheelSetPatchInput;
    output: CarWheelSet;
  };
  postCarWheelStorageObjects: {
    input: CarWheelStorageObjectCreateInput;
    output: CarWheelStorageObject;
  };
  getPaginatedStorageObjectsForCarWheel: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelStorageObject[]>;
  };
  getPaginatedStorageFacilities: {
    input: PaginatedInput;
    output: PaginatedResponse<StorageFacility[]>;
  };
  getStorageFacilities: {
    input: undefined;
    output: StorageFacility[];
  };
  getStorageFacility: {
    input: { id: number | string };
    output: StorageFacility;
  };
  postStorageFacility: {
    input: StorageFacilityCreateInput;
    output: StorageFacility;
  };
  patchStorageFacility: {
    input: StorageFacilityUpdateInput & { id: number };
    output: StorageFacility;
  };
  getPaginatedStorageAreasForFacility: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<StorageAreaListItem[]>;
  };
  getStorageArea: {
    input: { id: number | string };
    output: StorageArea;
  };
  postStorageArea: {
    input: StorageAreaCreateInput;
    output: AxiosResponse<StorageArea>;
  };
  patchStorageArea: {
    input: StorageAreaUpdateInput & { id: number };
    output: AxiosResponse<StorageArea>;
  };
  getPaginatedStorageUnits: {
    input: PaginatedInput;
    output: PaginatedResponse<StorageUnit[]>;
  };
  getStorageUnit: {
    input: { id: number | string };
    output: StorageUnit;
  };
  postStorageUnit: {
    input: StorageUnitCreateInput;
    output: AxiosResponse<StorageUnit>;
  };
  patchStorageUnit: {
    input: StorageUnitUpdateInput;
    output: AxiosResponse<StorageUnit>;
  };
  getPaginatedStorageUnitsForArea: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<StorageUnitListItem[]>;
  };
  getStorageUnitTypes: {
    input: undefined;
    output: StorageUnitType[];
  };
  postPickUpCarWheelSet: {
    input: { id: number };
    output: CarWheelSet;
  };
  getPaginatedCarWheelSetForCar: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelSetListItemType[]>;
  };
  getPaginatedUserGroupMembershipProgram: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<MembershipProgramListItem[]>;
  };
  getPaginatedCapacityContributions: {
    input: PaginatedCapacityContributionsInput;
    output: PaginatedResponse<CapacityContributionListItem[]>;
  };
  getCapacityContributionStatuses: {
    input: undefined;
    output: CapacityContributionStatus[];
  };
  getCapacityContributionsForRoute: {
    input: { routeId: string; serviceOrganizationId?: number | null };
    output: CapacityContributionType[];
  };
  getPaginatedServiceCategories: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceCategory[]>;
  };
  getOrderLine: {
    input: { orderLineId: string };
    output: OrderDetailDataType;
  };
  deleteOrderLine: {
    input: { orderLineId: number };
    output: undefined;
  };
  getPaginatedRouteProblems: {
    input: PaginatedInput & { date: string | Date; licenseAreaId: string };
    output: PaginatedResponse<RouteProblemsType[]>;
  };
  getSolution: {
    input: { solutionId: string };
    output: SingleRouteSolutionDataType[];
  };
  getRouteSolutions: {
    input: { routeProblemId: string };
    output: RouteSolutionResultsType[];
  };
  getPaginatedCapacityContributionsForRoutePlanner: {
    input: PaginatedInput & RoutePlannerBookingsInputType;
    output: PaginatedResponse<RoutePlannerCapacityContribution[]>;
  };
  getRoutePlannerBookings: {
    input: RoutePlannerBookingsInputType;
    output: BookingType[];
  };
  postCloseCapacity: {
    input: CapacityAndCancellationsInputType;
    output: undefined;
  };
  postCloseCancellations: {
    input: CapacityAndCancellationsInputType;
    output: { capacityLicenseAreaId: number };
  };
  postStartRoutePlanner: {
    input: StartRoutePlannerInputType;
    output: StartRoutePlannerResponseType;
  };
  getWheelStoragePickupBookingItems: {
    input: WheelStoragePickupsParams & PaginatedInput;
    output: PaginatedResponse<WheelStoragePickupBookingItem[]>;
  };
  getWheelStorageAgreements: {
    input: WheelStorageAgreementFilters & PaginatedInput;
    output: PaginatedResponse<TireHotelContractListItem[]>;
  };
  getCapacityLicenseAreaFromAreaAndDate: {
    input: { date: string | Date; licenseAreaId: number };
    output: ClosedForCancellationsAtType;
  };
  getPaginatedServiceAreaNotification: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceNotificationAreaType[]>;
  };
  getServiceAreaNotification: {
    input: { id: number };
    output: ServiceAreaNotificationType;
  };
  postServiceAreaNotification: {
    input: Omit<ServiceAreaNotificationType, 'id'>;
    output: ServiceAreaNotificationType;
  };
  patchServiceAreaNotification: {
    input: ServiceAreaNotificationType;
    output: ServiceAreaNotificationType;
  };
  deleteServiceAreaNotification: {
    input: { id: number };
    output: undefined;
  };
  postPopulateDbWithExternalRouteSolutions: {
    input: { id: string };
    output: any;
  };
  postSelectRouteSolution: {
    input: { id: string };
    output: any;
  };
  getPaginatedBookingOverviewDetailsList: {
    input: PaginatedBookingOverviewDetailsListInput;
    output: PaginatedResponse<BookingOverviewDetailsListType[]>;
  };
  postUpdateAdminCommentForRouteItem: {
    input: { routeId: number; routeItemId: number; commentsAdmin: string };
    output: undefined;
  };
  postUpdateUserCommentForRouteItem: {
    input: { routeId: number; routeItemId: number; commentsUser: string };
    output: undefined;
  };
  postUpdateWorkerCommentForRouteItem: {
    input: { routeId: number; routeItemId: number; commentsWorker: string };
    output: undefined;
  };
  getAdminDashboardBookings: {
    input: { fromDate: string; toDate: string; bookingStatusIds: string };
    output: DashboardData;
  };
  postCancelBooking: {
    input: { id: number; notifyCustomer: boolean };
    output: undefined;
  };
  postSendRouteDelaySms: {
    input: { routeId: number; routeItemId: number; numMinutes: number };
    output: undefined;
  };
  postChangeCapacityContribution: {
    input: { routeId: number; capacityContributionId: number | null };
    output: undefined;
  };
  getPaginatedServiceContracts: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceContractListItem[]>;
  };
  getPaginatedServiceSchedulerTemplates: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceSchedulerTemplate[]>;
  };
  getServiceSchedulerTemplate: {
    input: { id: number };
    output: ServiceSchedulerTemplate;
  };
  postServiceSchedulerTemplate: {
    input: ServiceSchedulerTemplateCreateInput;
    output: ServiceSchedulerTemplate;
  };
  patchServiceSchedulerTemplate: {
    input: ServiceSchedulerTemplateUpdateInput;
    output: ServiceSchedulerTemplate;
  };
  getCapacityNumberOfDaysAhead: {
    input: never;
    output: number;
  };
  getCapacityIntervals: {
    input: ListCapacityIntervalsInput;
    output: CapacityInterval[];
  };
  getPaginatedServiceWorkerAddressPairs: {
    input: PaginatedInput & { serviceWorkerAttributesId?: number };
    output: PaginatedResponse<ServiceWorkerAddressPair[]>;
  };
  getServiceWorkerAddressPair: {
    input: { id: number };
    output: ServiceWorkerAddressPair;
  };
  patchServiceWorkerAddressPair: {
    input: ServiceWorkerAddressPairPatchInput;
    output: ServiceWorkerAddressPair;
  };
  getSuggestedServiceAreasForAddressPair: {
    input: { id: number };
    output: ServiceAreaListItem[];
  };
}
