import { useMediaQuery, useTheme } from '@mui/material';
import { colors } from 'noddi-ui';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../appRoutes';
import PublicServiceAnnouncement from './PublicServiceAnnoncement';
import StickyBottomBar from './StickyBottomBar';
import { useBackNavigation } from './hooks/useBackNavigation';
import { useStepValidator } from './hooks/useStepValidator';

const BookingContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding-top: 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 30px;

  //Because of the shopping
  @media (max-width: 640px) {
    padding-bottom: 50px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: 92vh;

  @media (min-height: 1000px) {
    min-height: 100vh;
  }
`;

const InnerContainer = styled.div<{ whiteBg?: boolean; isTimePickerStepAndMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${(p) => (p.whiteBg ? colors.primary.white : 'inherit')};

  ${(p) =>
    p.isTimePickerStepAndMobile &&
    `
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-top: 16px;
    background:transparent;
  `}
`;

export const StickyBottomContainer = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;

  z-index: 1337;

  display: flex;
  padding-bottom: 32px;
  padding-top: 24px;
  background-color: ${colors.primary.white};
  flex-direction: column;
`;
interface ScreenProps {
  title: string;
  children: React.ReactNode;
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  CustomBottomBar?: ReactNode;
  noMaxwidth?: boolean;
}

export function useIsMobileViewInBookingFlow() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}

const BookingScreen = ({
  title,
  children,
  onNextClicked,
  disableNextButton,
  CustomBottomBar,
  hideNextButton,
  noMaxwidth
}: PropsWithChildren<ScreenProps>) => {
  const { pathname } = useLocation();
  const { goBack } = useBackNavigation();

  const hideBackButton = pathname.includes(routes.newBookingAddressStep.getBasePath());

  const isMobileViewInBookingFlow = useIsMobileViewInBookingFlow();
  const { validateStep } = useStepValidator();

  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());

  useEffect(() => {
    validateStep();
  }, [pathname]);

  const isSummaryPage = pathname.includes(routes.newBookingSummaryStep.getBasePath());

  return (
    <TopContainer>
      <InnerContainer
        whiteBg={isTimePickerStep && isMobileViewInBookingFlow}
        isTimePickerStepAndMobile={isTimePickerStep && isMobileViewInBookingFlow}
      >
        <BookingContainer>
          <div className={`${noMaxwidth ? '' : 'mx-auto w-full max-w-2xl'} flex-col px-6 lg:px-0`}>
            <PublicServiceAnnouncement />
            <div className='flex-col'>
              <h1 className='text-7 sm:text-13 mb-5 sm:mb-10'>{title}</h1>
              {children}
            </div>
          </div>
        </BookingContainer>
      </InnerContainer>
      {isSummaryPage ? (
        CustomBottomBar
      ) : (
        <StickyBottomBar
          hideBackButton={hideBackButton}
          hideNextButton={hideNextButton}
          disableNextButton={disableNextButton}
          onNextClicked={onNextClicked}
          onPreviousClick={goBack}
        />
      )}
    </TopContainer>
  );
};

export default BookingScreen;
