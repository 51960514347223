import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useRef, useState } from 'react';

import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { SupportedLanguages } from '../types/lingui';

const ArrowTop = (
  <svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.5 0.5L10.2631 8.75L0.73686 8.75L5.5 0.5Z' fill='#1F1F1F' />
  </svg>
);

const ArrowDown = (
  <svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.5 8.5L0.736861 0.249999L10.2631 0.25L5.5 8.5Z' fill='#1F1F1F' />
  </svg>
);

type NoddiLanguageSelectorProps = {
  supportedLanguages: SupportedLanguages;
  currentLanguage: string;
  setCurrentLanguage: (locale: string) => void;
};

const NoddiLanguageSelector = ({
  supportedLanguages,
  currentLanguage,
  setCurrentLanguage
}: NoddiLanguageSelectorProps) => {
  const options = Object.entries(supportedLanguages).map(([locale, details]) => ({
    value: locale,
    label: details.label,
    flag: details.flag
  }));

  const currentLanguageDetails = supportedLanguages[currentLanguage];

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className='w-fit'>
      <div
        className='flex w-fit cursor-pointer items-center justify-between rounded-full bg-primary-white px-4 py-2'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex items-center'>
          <span>{currentLanguageDetails?.flag}</span>
          <span className='ml-4 hidden sm:block'>{currentLanguageDetails?.label}</span>
        </div>
        <div className='ml-2'>{isOpen ? ArrowTop : ArrowDown}</div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className='absolute z-[10000] mt-2 w-50 rounded-2xl bg-primary-white shadow-md'
          >
            {options.map((option, idx) => (
              <Fragment key={option.value}>
                <div
                  className={`flex cursor-pointer p-4 ${option.value === currentLanguage ? 'font-bold' : ''}`}
                  onClick={() => {
                    setCurrentLanguage(option.value);
                    setIsOpen(false);
                  }}
                >
                  <span className='mr-4'>{option.flag}</span>
                  <span>{option.label}</span>

                  <div className='ml-auto flex size-5 items-center justify-center rounded-full border border-systemColors-outlineStroke'>
                    {option.value === currentLanguage && <div className='size-4 rounded-full bg-primary-purple'></div>}
                  </div>
                </div>
                {idx !== options.length - 1 && <div className=' mx-3 h-0.25  bg-systemColors-outlineStroke' />}
              </Fragment>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { NoddiLanguageSelector };
