import { t } from '@lingui/macro';
import { ErrorPage, useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import TimeWindowPicker from '../../../../components/BookingFlow/TimeWindowPicker';
import { useBookingContext } from '../../../../contexts/BookingContext';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import useOnNextButtonClick from './useOnNextButtonClick';

const TimeWindowLayout = () => {
  const { bookingInputData: inputData } = useBookingContext();
  const { timeWindowsStepOk } = useStepValidator();
  const { navigateToNextStep } = useOnNextButtonClick();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  if (!inputData.serviceAreaId) {
    return (
      <ErrorPage
        customCallback={{
          ctaText: t`Start new booking`,
          callback: () => navigate(routes.newBookingAddressStep.getPath())
        }}
      />
    );
  }

  const enableNext = timeWindowsStepOk();

  return (
    <BookingScreen
      onNextClicked={navigateToNextStep}
      disableNextButton={!enableNext}
      title={t`When is it convenient for us to come to you?`}
      noMaxwidth
    >
      <div
        className={cn(
          'mb-4 h-fit rounded-lg bg-primary-white p-8 max-sm:p-0',
          isMobile && 'bg-systemColors-transparent'
        )}
      >
        <TimeWindowPicker isPublicBookingFlow={true} />
      </div>
    </BookingScreen>
  );
};

export default TimeWindowLayout;
