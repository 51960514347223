const HOME_PATH = 'home';
const NEW_BOOKING_PATH = '/bookings/new';

const appRoutesKeys = {
  home: HOME_PATH,
  myCars: 'myCars',
  myCarDetails: 'myCarDetails',
  myCarWheelMeasurements: 'myCarWheelMeasurements',
  myCoupons: 'myCoupons',
  myBookingDetails: 'myBookingDetails',
  login: 'login',
  confirmation: 'confirmation',
  homeError: 'homeError',
  profile: 'profile',
  homePageBookings: 'homePageBookings',
  myMembershipPrograms: 'myMembershipPrograms',
  myAddresses: 'myAddresses',
  myReferrals: 'myReferrals',
  tireStorage: 'tireStorage',
  tireStorageBooking: 'tireStorageBooking',
  tireStorageDetail: 'tireStorageDetail',
  tireStorageCancelBooking: 'tireStorageCancelBooking',
  nafMembership: 'nafMembership',
  paymentInfo: 'paymentInfo',
  referralsInfo: 'referralsInfo',
  campaignsInfo: 'campaignsInfo',
  bookingInfo: 'bookingInfo',
  newBooking: 'newBooking',
  newBookingAddressStep: 'newBookingAddressStep',
  newBookingCarStep: 'newBookingCarStep',
  newBookingServicesStep: 'newBookingServicesStep',
  newBookingAddonsStep: 'newBookingAddonsStep',
  newBookingWheelStorageStep: 'newBookingWheelStorageStep',
  newBookingTimeSlotStep: 'newBookingTimeSlotStep',
  newBookingSummaryStep: 'newBookingSummaryStep',
  newBookingConfirmationStep: 'newBookingConfirmationStep',
  editMyBookingTimeWindow: 'editMyBookingTimeWindow'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

type StringOrNumber = string | number;

// Define the parameters for the routes
interface PathConfig {
  myBookingDetails: { id: StringOrNumber };
  editMyBookingTimeWindow: { id: StringOrNumber };
  myCarDetails: { carId: StringOrNumber };
  myCarWheelMeasurements: { carId: StringOrNumber };
  tireStorageDetail: { id: StringOrNumber };
  paymentInfo: { slug: StringOrNumber };
  referralsInfo: { slug: StringOrNumber };
  campaignsInfo: { slug: StringOrNumber };
  bookingInfo: { slug: StringOrNumber };
  confirmation: { params: string };
}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

export type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
};
const routes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  home: {
    getPath: () => `/${HOME_PATH}`,
    getBasePath: () => `/${HOME_PATH}/*`
  },
  myBookingDetails: {
    getPath: ({ id }) => `/${HOME_PATH}/bookings/${id}`,
    getBasePath: () => '/bookings/:id'
  },
  editMyBookingTimeWindow: {
    getPath: ({ id }) => `/${HOME_PATH}/bookings/${id}/edit`,
    getBasePath: () => '/bookings/:id/edit'
  },
  myCars: {
    getPath: () => `/${HOME_PATH}/cars`,
    getBasePath: () => '/cars'
  },
  myCarDetails: {
    getPath: ({ carId }) => `/${HOME_PATH}/cars/${carId}`,
    getBasePath: () => '/cars/:carId'
  },
  myCarWheelMeasurements: {
    getPath: ({ carId }) => `/${HOME_PATH}/cars/${carId}/wheel-measurements`,
    getBasePath: () => '/cars/:carId/wheel-measurements'
  },
  myCoupons: {
    getPath: () => `/${HOME_PATH}/coupons`,
    getBasePath: () => '/coupons'
  },
  homeError: {
    getPath: () => `/${HOME_PATH}/error`,
    getBasePath: () => '/error'
  },
  profile: {
    getPath: () => `/${HOME_PATH}/profile`,
    getBasePath: () => '/profile'
  },
  myMembershipPrograms: {
    getPath: () => `/${HOME_PATH}/membership-programs`,
    getBasePath: () => '/membership-programs'
  },
  homePageBookings: {
    getPath: () => `/${HOME_PATH}/bookings`,
    getBasePath: () => '/bookings'
  },
  myAddresses: {
    getPath: () => `/${HOME_PATH}/addresses`,
    getBasePath: () => '/addresses'
  },
  myReferrals: {
    getPath: () => `/${HOME_PATH}/referrals`,
    getBasePath: () => '/referrals'
  },
  confirmation: {
    getPath: ({ params }) => `/confirmation?${params}`,
    getBasePath: () => '/confirmation'
  },
  tireStorage: {
    getPath: () => `/${HOME_PATH}/tire-storage`,
    getBasePath: () => '/tire-storage'
  },
  tireStorageBooking: {
    getPath: () => `/${HOME_PATH}/tire-storage/booking`,
    getBasePath: () => '/booking'
  },
  tireStorageDetail: {
    getPath: ({ id }) => `/${HOME_PATH}/tire-storage/${id}`,
    getBasePath: () => '/:id'
  },
  tireStorageCancelBooking: {
    getPath: () => `/${HOME_PATH}/tire-storage/booking/cancel`,
    getBasePath: () => '/booking/cancel'
  },
  nafMembership: {
    getPath: () => `/memberships/naf`,
    getBasePath: () => '/memberships/naf'
  },
  paymentInfo: {
    getPath: ({ slug }) => `/b/payment/${slug}`,
    getBasePath: () => '/b/payment/:slug'
  },
  referralsInfo: {
    getPath: ({ slug }) => `/referrals/${slug}`,
    getBasePath: () => '/referrals/:slug'
  },
  campaignsInfo: {
    getPath: ({ slug }) => `/campaigns/${slug}`,
    getBasePath: () => '/campaigns/:slug'
  },
  bookingInfo: {
    getPath: ({ slug }) => `/b/${slug}`,
    getBasePath: () => '/b/:slug'
  },
  newBooking: {
    getPath: () => `${NEW_BOOKING_PATH}/*`,
    getBasePath: () => `${NEW_BOOKING_PATH}/*`
  },
  newBookingAddressStep: {
    getPath: () => `${NEW_BOOKING_PATH}/address`,
    getBasePath: () => '/address'
  },
  newBookingCarStep: {
    getPath: () => `${NEW_BOOKING_PATH}/car`,
    getBasePath: () => '/car'
  },
  newBookingServicesStep: {
    getPath: () => `${NEW_BOOKING_PATH}/services`,
    getBasePath: () => '/services'
  },
  newBookingAddonsStep: {
    getPath: () => `${NEW_BOOKING_PATH}/addons`,
    getBasePath: () => '/addons'
  },
  newBookingWheelStorageStep: {
    getPath: () => `${NEW_BOOKING_PATH}/wheel-storage`,
    getBasePath: () => '/wheel-storage'
  },
  newBookingTimeSlotStep: {
    getPath: () => `${NEW_BOOKING_PATH}/timeSlots`,
    getBasePath: () => '/timeSlots'
  },
  newBookingSummaryStep: {
    getPath: () => `${NEW_BOOKING_PATH}/summary`,
    getBasePath: () => '/summary'
  },
  newBookingConfirmationStep: {
    getPath: () => `${NEW_BOOKING_PATH}/confirmation`,
    getBasePath: () => '/confirmation'
  }
};

export default routes;

export const noddiProductionHomepageRoute = 'https://noddi.no';
