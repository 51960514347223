import { Trans } from '@lingui/macro';
import { Grid, OutlinedInput, Stack, Typography } from '@mui/material';
import { NoddiAsyncError } from 'noddi-async/src/types/shared/error';
import { useEffect, useRef, useState } from 'react';

import { isLocalEvn } from '../../../../../../web/noddi-customer/src/environment';
import { NoddiButton } from '../../Elements/Buttons';
import { ApiErrorMessage } from '../../Elements/Errors';

const VerificationCodeForm = ({
  phoneNumber,
  onClick,
  isLoading,
  verificationSuccess,
  error
}: {
  isLoading: boolean;
  phoneNumber: string;
  onClick: (params: { code: string; phoneNumber: string }) => void;
  verificationSuccess: boolean;
  error: NoddiAsyncError | null;
}) => {
  const [verificationCode, setVerificationCode] = useState(isLocalEvn ? '123456' : '');

  //Send the login request for login immediately
  useEffect(() => {
    if (isLocalEvn) {
      onClick({ code: verificationCode, phoneNumber });
    }
  }, []);

  const verificationCodeIsValid = () => {
    return verificationCode.length === 6;
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  return (
    <>
      <Stack direction='column'>
        <Typography marginBottom={1} variant='h5'>
          <Trans>Kode fra mobil</Trans>
        </Typography>
        <Typography variant='body1' style={{ marginBottom: 4 }}>
          <Trans>Skriv inn koden du nettopp fikk på mobilen din fra oss</Trans>
        </Typography>
      </Stack>
      <OutlinedInput
        inputRef={ref}
        inputProps={{
          minLength: 6,
          maxLength: 6,
          inputMode: 'numeric',
          pattern: '[0-9]*' // Allow only numeric characters
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (verificationCodeIsValid() && event.key === 'Enter') {
            onClick({ code: verificationCode, phoneNumber });
          }
        }}
        onChange={(e) => {
          const newValue = e.target.value;

          if (newValue) {
            setVerificationCode(newValue);
          } else {
            setVerificationCode('');
          }
        }}
        value={verificationCode}
        type='text'
        placeholder='Kode fra mobil'
        fullWidth
      />
      {error && (
        <div style={{ marginTop: 8 }}>
          <ApiErrorMessage error={error} />
        </div>
      )}
      <Grid my={3} display='flex' style={{ width: '100%' }} alignItems='center' xs={12}>
        <NoddiButton
          style={{ marginTop: '24px' }}
          disabled={!verificationCodeIsValid() || isLoading || verificationSuccess}
          fullWidth
          loading={isLoading}
          onClick={() => onClick({ code: verificationCode, phoneNumber })}
        >
          <Trans>Bekreft kode</Trans>
        </NoddiButton>
      </Grid>
    </>
  );
};

export { VerificationCodeForm };
