import { LicensePlateNumberData, RegisteredCar } from 'noddi-async/src/types';
import { RegNumberSearch } from 'noddi-ui';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import CarDropDown from './CarDropDown';

type AddMoreCarsViewProps = {
  registeredCars?: RegisteredCar[];
};

const AddMoreCarsView = ({ registeredCars }: AddMoreCarsViewProps) => {
  const { bookingInputData, updateBookingInputData } = useBookingContext();

  const selectedCars = bookingInputData.selectedCars;

  const onAddRegistrationNumber = (selectedCar: LicensePlateNumberData) => {
    const alreadyExists = selectedCars.some((car) => car.licensePlateNumber === selectedCar.licensePlateNumber);
    if (alreadyExists) {
      return;
    }

    updateBookingInputData({
      selectedCars: [
        ...selectedCars,
        {
          licensePlateNumber: selectedCar.licensePlateNumber,
          carName: `${selectedCar.make} ${selectedCar.model}`,
          carSizeId: selectedCar.carSizeId,
          vinNumber: selectedCar.vinNumber
        }
      ]
    });
    GoogleTrackingService.trackEvent({ eventType: GtmEvents.addToCart });
  };

  const alreadyPickedLicensePlateNumbers = selectedCars.map((car) => car.licensePlateNumber);

  const addMoreCarsOptions =
    registeredCars
      ?.filter(
        (fetchedCar) => !selectedCars.map((car) => car.licensePlateNumber).includes(fetchedCar.licensePlateNumber)
      )
      .map((car) => ({
        licensePlateNumber: car.licensePlateNumber,
        carName: `${car.make} ${car.model}`,
        carSizeId: car.carSizeId,
        vinNumber: car.vinNumber
      })) ?? [];

  return (
    <>
      <RegNumberSearch
        onAddCar={onAddRegistrationNumber}
        alreadyPickedLicensePlateNumbers={alreadyPickedLicensePlateNumbers}
        registeredCars={registeredCars}
      />

      {addMoreCarsOptions.length !== 0 && <CarDropDown registeredCars={addMoreCarsOptions} />}
    </>
  );
};

export default AddMoreCarsView;
