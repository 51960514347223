import { Trans } from '@lingui/macro';
// eslint-disable-next-line
import { groupBy } from 'lodash';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui';
import { Fragment } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { SelectedCar, SelectedSalesItemQueryInput } from '../../interfaces';
import {
  getNumberOfSelectedSalesItems,
  serviceLabelToServiceEnum,
  translateSalesCategories
} from '../SalesItems/utils';
import { ServiceCategoryBlock } from '../Services';

type AddonPickerProps = {
  car: SelectedCar;
  addons: AvailableSalesItem[];
  queryParams: SelectedSalesItemQueryInput;
};

const AddonPicker = ({ car, addons }: AddonPickerProps) => {
  const { salesItems: selectedSalesItems } = useBookingContext();

  const salesCategories = groupBy(addons, 'category.slug');

  return (
    <>
      <h6 className='text-bold mb-6 flex w-full gap-2 text-5'>
        <NoddiIcon name='Car' size='large' />
        {car.carName} - {car.licensePlateNumber}
      </h6>

      {Object.keys(salesCategories)?.length > 0 ? (
        <div className='flex flex-col gap-4'>
          {Object.entries(salesCategories).map(([category, salesItems]) => {
            const selectedItemsCount = getNumberOfSelectedSalesItems({
              selectedSalesItems,
              category,
              salesItems,
              licensePlateNumber: car.licensePlateNumber
            });

            const priceFrom = salesItems.reduce((acc, item) => Math.min(acc, item.price), Infinity);
            return (
              <Fragment key={category}>
                <ServiceCategoryBlock
                  type={serviceLabelToServiceEnum(category)}
                  serviceName={translateSalesCategories(category)}
                  priceFrom={priceFrom}
                  isPopular={false}
                  isAddon
                  car={car}
                  salesItems={salesItems}
                  selectedItemsCount={selectedItemsCount}
                />
              </Fragment>
            );
          })}
        </div>
      ) : (
        <p className='mt-2'>
          <Trans> Unfortunately, we do not offer any addons for this type of car</Trans>
        </p>
      )}
    </>
  );
};

export default AddonPicker;
