import { IconSvgProps } from './svgtype';

const Check = ({ color, ...props }: IconSvgProps) => {
  return (
    <svg width={30} height={30} fill='none' {...props}>
      <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='m9 15.4 3.143 3.6L20 10' />
    </svg>
  );
};
export { Check };
