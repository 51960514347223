import { t } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';

import { SelectedSalesItem } from '../../interfaces';

export enum SalesCategory {
  WHEEL_SERVICES = 'wheel_services',
  WASH_SERVICES = 'wash_services',
  PACKAGES = 'packages'
}

export const translateSalesCategories = (salesCategoryFromApi: SalesCategory | string): string => {
  switch (salesCategoryFromApi) {
    case SalesCategory.WHEEL_SERVICES:
      return t`Wheel services`;
    case SalesCategory.WASH_SERVICES:
      return t`Wash services`;
    case SalesCategory.PACKAGES:
      return t`Packages`;
    default:
      return `Other`;
  }
};

export const serviceLabelToServiceEnum = (serviceFromApi: SalesCategory | string): SERVICE_CATEGORY_TYPE => {
  switch (serviceFromApi) {
    case SalesCategory.WHEEL_SERVICES:
      return SERVICE_CATEGORY_TYPE.WHEEL_SERVICES;
    case SalesCategory.WASH_SERVICES:
      return SERVICE_CATEGORY_TYPE.WASHING_SERVICES;
    case SalesCategory.PACKAGES:
      return SERVICE_CATEGORY_TYPE.PACKAGES;
    default:
      return SERVICE_CATEGORY_TYPE.PACKAGES;
  }
};

export enum SERVICE_CATEGORY_TYPE {
  WHEEL_SERVICES,
  WASHING_SERVICES,
  PACKAGES
}

export const getNumberOfSelectedSalesItems = ({
  selectedSalesItems,
  category,
  salesItems,
  licensePlateNumber
}: {
  selectedSalesItems: SelectedSalesItem[];
  category: string;
  licensePlateNumber: string;
  salesItems: ServerTypes.AvailableSalesItem[];
}) => {
  const salesItemsInCategory = salesItems.filter((item) => item.category?.slug === category);
  return salesItemsInCategory.filter((item) =>
    selectedSalesItems.find(
      (selectedItem) => selectedItem.licensePlateNumber === licensePlateNumber && selectedItem.id === item.id
    )
  ).length;
};
