import { Trans, t } from '@lingui/macro';
import { useMediaQuery } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiButton } from 'noddi-ui';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { useTireHotelContext } from '../context';
import useStepContent from '../hooks/useStepContent';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

const TireHotelBookingFlow = () => {
  const { currentUserGroupId } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { currentStep } = useTireHotelContext();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { pathname } = useLocation();
  const { selectSalesItemForMultipleCars } = useSelectSalesItem();
  const isCancelingBooking = pathname.includes('/cancel');
  const {
    bookingInputData: { selectedCars }
  } = useBookingContext();

  const { data, isPending } = noddiAsync.useGet({
    type: URLKeys.getSalesItemsForBooking,
    input: {
      group: 'tire-storage-renew'
    },
    queryConfig: {
      staleTime: Infinity,
      enabled: !isCancelingBooking
    }
  });

  const { data: membershipData, isPending: isMembershipsPending } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity
    }
  });

  const stepContent = useStepContent({
    setIsLoading,
    membershipData
  });

  useEffect(() => {
    if (!isPending && data && !isCancelingBooking) {
      // we know that this endpoint return only one sales item
      const renewTireStorageSalesItem = data?.[0];

      if (!renewTireStorageSalesItem) {
        return;
      }

      selectSalesItemForMultipleCars({
        salesItem: renewTireStorageSalesItem,
        licensePlateNumbers: selectedCars.map((car) => car.licensePlateNumber),
        isAddon: false,
        queryParams: {
          urlKey: URLKeys.getSalesItemsForBooking,
          queryInput: {
            group: 'tire-storage-renew'
          }
        }
      });
    }
  }, [isPending, data]);
  const currentStepContent = stepContent[currentStep];

  const onNextClicked = currentStepContent?.onNextClicked;
  const confirmText = currentStepContent?.confirmText;
  const back = currentStepContent?.back;
  const nextDisabled = currentStepContent?.nextDisabled;

  const onClose = () => {
    if (back) {
      back();
    } else {
      history.back();
    }
  };

  const hideBackButton = !isCancelingBooking && currentStep === TireStorageBookingSteps.address;

  const loadingBarProggressValue = useMemo(() => {
    if (currentStep === TireStorageBookingSteps.returnChoices) {
      return 0;
    }
    if (currentStep === TireStorageBookingSteps.address) {
      return isCancelingBooking ? 25 : 0;
    }
    if (currentStep === TireStorageBookingSteps.timePicker) {
      return isCancelingBooking ? 50 : 33;
    }
    if (currentStep === TireStorageBookingSteps.confirm) {
      return isCancelingBooking ? 75 : 66;
    }
    return 0;
  }, [currentStep]);

  if (isMembershipsPending) {
    return <LoadingScreen />;
  }

  return (
    <>
      <>{currentStepContent.renderComponent && currentStepContent.renderComponent()}</>
      <StyledBottomBarStack numberOfButtons={!hideBackButton ? 2 : 1} isMobile={isMobile}>
        <LinearProgressStyled value={loadingBarProggressValue} variant='determinate' sx={{ position: 'absolute' }} />

        {!hideBackButton && (
          <NoddiButton onClick={onClose} variant='secondary' disabled={isLoading}>
            {t`Back`}
          </NoddiButton>
        )}
        <NoddiButton
          onClick={async () => {
            await onNextClicked();
          }}
          variant='primary'
          disabled={isLoading || Boolean(nextDisabled?.())}
          loading={isLoading}
        >
          {confirmText ?? <Trans>Yes</Trans>}
        </NoddiButton>
      </StyledBottomBarStack>
    </>
  );
};

export default TireHotelBookingFlow;

export const StyledBottomBarStack = styled.div<{ numberOfButtons: number; isMobile: boolean }>`
  position: static; /* Initially set to static */
  display: flex;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};

  @media (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0px;
    position: fixed; /* Change to fixed on small screens (up to 600px wide) */
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Adjust z-index as needed */
    background-color: #ffffff; /* Optional: Background color */
    padding: 24px 0px; /* Optional: Padding */
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Box shadow for visual separation */
    display: flex;
    justify-content: center;

    ${(props) =>
      props.numberOfButtons === 1
        ? `
      button {
        width: 90%;
        }
    `
        : `
      button {
        width: 40%;
      }
      `}
  }
`;

const LinearProgressStyled = styled(LinearProgress)`
  display: none;

  @media (max-width: 600px) {
    display: block;
    height: 6px;
    position: absolute;
    width: 100%;
    top: 0px;
  }
`;
