import { HTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';

export type SelectableCardMode = 'select' | 'view';

const SelectableCardStyles = styled.div<SelectableCardProps>`
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 1rem;
  position: relative;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.04);
  border: 2px solid transparent;
  :hover {
    ${(props) =>
      props.mode === 'select' ? `cursor: pointer; box-shadow: 0 0 0 2px ${colors.primary.orange}` : 'none'};
  }

  ${(props) =>
    props.selected &&
    props.mode === 'select' &&
    `background-color: ${colors.secondary.coral}; border: 2px solid ${colors.primary.orange};`}
  ${(props) => props.disabled && `opacity: 0.5; cursor: not-allowed;`}
`;

export type SelectableCardProps = {
  selected?: boolean;
  mode: SelectableCardMode;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const SelectableCard = ({
  selected,
  mode,
  children,
  onClick,
  disabled,
  ...rest
}: PropsWithChildren<SelectableCardProps>) => {
  const overrideModeForDisabled = disabled ? 'view' : mode;

  return (
    <SelectableCardStyles selected={selected} mode={mode} onClick={onClick} disabled={disabled} {...rest}>
      {overrideModeForDisabled === 'select' ? (
        selected ? (
          <NoddiIcon name='CheckRounded' size='medium' className='absolute right-0 top-0 p-6' />
        ) : (
          <NoddiIcon name='Square' size='medium' className='absolute right-0 top-0 p-6' />
        )
      ) : null}

      {children}
    </SelectableCardStyles>
  );
};

export { SelectableCard };
