import { ReactNode } from 'react';

import { NoddiIconButton } from '../molecules/NoddiIconButton';
import { ChipVariants } from './types/chip';

interface NoddiChipProps {
  label: ReactNode;
  onClick?: () => void;
  onDelete?: () => void;
  variant: ChipVariants;
}

const NoddiChip = ({ label, onClick, onDelete, variant }: NoddiChipProps) => {
  const variants = {
    //TODO Add classes for closeIcons (Rachid)
    primary: {
      wrapperClassName: 'bg-primary-purple text-primary-white'
    },
    //Gray
    secondary: {
      wrapperClassName: ' bg-secondary-silverGray text-primary-black'
    },

    //Green
    tertiary: {
      wrapperClassName: ' bg-signal-success  text-primary-white'
    },
    //Red
    quaternary: {
      wrapperClassName: ' bg-signal-danger  text-primary-white'
    },
    //Orange
    quinary: {
      wrapperClassName: '  bg-primary-orange text-primary-black'
    }
  };

  return (
    <div
      className={`flex items-center justify-center gap-2 whitespace-nowrap rounded-full px-3 py-1 font-medium ${
        variants[variant].wrapperClassName
      } ${onClick && 'cursor-pointer'}`}
      onClick={onClick}
    >
      <span>{label}</span>
      {onDelete && (
        <div
          className='ml-2 cursor-pointer rounded-full'
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <NoddiIconButton variant='secondary' iconName='Cross' iconSize='small' />
        </div>
      )}
    </div>
  );
};

export { NoddiChip };
