import { Trans, t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  ApiErrorMessage,
  CouponCard,
  LoadingScreen,
  NoddiContainer,
  SMSLogin,
  UserRegistrationForm,
  colors,
  useIsMobile
} from 'noddi-ui';
import { useParams } from 'react-router-dom';

import tracking from '../../tracking';
import { getImageSrc } from '../../utils/resolveCompanyLogo';
import ActivateCampaign from './ActivateCampaign';

const Coupons = () => {
  const data = useParams<{ slug: string }>();
  const { isLoggedIn, canActivateCouponsForNewUsersOnly } = useAuthContext();
  const isMobile = useIsMobile();

  const safeSlug = data.slug?.toLocaleLowerCase();

  const {
    data: campaignData,
    isPending: isCampaignPending,
    error: campaignError
  } = noddiAsync.useGet({ type: URLKeys.getCampaignFromSlug, input: { slug: safeSlug } });

  if (isCampaignPending) {
    return <LoadingScreen />;
  }
  if (campaignError) {
    return <ApiErrorMessage error={campaignError} />;
  }

  const imageSize = isMobile ? 48 : 80;

  return (
    <NoddiContainer header={t`Your coupons`} maxWidth='sm' sx={{ marginTop: '2rem' }}>
      {isLoggedIn && campaignData.isForNewUserOnly && !canActivateCouponsForNewUsersOnly ? (
        <Stack>
          <Typography variant='h5' mt={2}>
            <Trans>Your user cannot activate coupons from this campaign</Trans>
          </Typography>
          <Typography variant='subtitle1' mt={2} style={{ color: colors.systemColors.grey }}>
            <Trans>This promotion is only for new users without existing coupons</Trans>
          </Typography>
        </Stack>
      ) : (
        <>
          <Typography variant='h1'>
            <span
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {getImageSrc(safeSlug) && (
                <img
                  style={{
                    height: imageSize,
                    width: 'auto',
                    marginRight: 8
                  }}
                  src={getImageSrc(safeSlug)}
                  alt='Campaign logo'
                />
              )}
              <Trans>Your benefits</Trans>
            </span>
          </Typography>

          {!isLoggedIn && (
            <>
              <Typography variant='h5' mt={3}>
                <Trans>Please log in to activate your coupons</Trans>
              </Typography>
              <Typography variant='subtitle1' mt={2} style={{ color: colors.systemColors.grey }}>
                <Trans>We will automatically create a user for you, if you do not have one already.</Trans>
              </Typography>
              <SMSLogin
                userRegistrationForm={(phoneNumber) => (
                  <UserRegistrationForm
                    phoneNumber={phoneNumber}
                    skipPasswordCreation
                    trackUserIdentity={(user) => {
                      tracking.trackUserIdentity(user);
                    }}
                  />
                )}
              />
            </>
          )}

          {isLoggedIn && <ActivateCampaign campaign={campaignData} />}
          {campaignData.couponGroup && (
            <>
              <Typography variant='h5' mt={2}>
                {campaignData.couponGroup.descriptionPublic}
              </Typography>
              {campaignData.couponGroup.couponTemplates.map((couponTemplate) => (
                <CouponCard
                  key={couponTemplate.id}
                  namePublic={couponTemplate.namePublic}
                  descriptionPublic={couponTemplate.descriptionPublic}
                  amount={couponTemplate.amount}
                  amountPercentage={couponTemplate.amountPercentage}
                  containerSx={{ marginTop: '22px' }}
                />
              ))}
            </>
          )}
        </>
      )}
    </NoddiContainer>
  );
};

export default Coupons;
