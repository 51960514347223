import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen, NoddiContainer } from 'noddi-ui';

import NewAppointmentLink from '../../components/Links/NewAppointment';
import CopyReferralLink from './CopyReferralLink';
import ReferredUsers from './ReferredUsers';

const Referrals = () => {
  const { isTokenLoginLoading, currentUserGroupId } = useAuthContext();

  const {
    isPending,
    data: referralData,
    isSuccess
  } = noddiAsync.useGet({
    type: URLKeys.getReferrals,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId
    }
  });

  if (isPending || isTokenLoginLoading) {
    return <LoadingScreen />;
  }
  if (!isSuccess) {
    return (
      <NoddiContainer
        header={t`Referrals`}
        description={t`Refer a friend and you will both receive benefits that you can use on services from Noddi. You get the benefits on your user when the person you have invited makes their first booking with us.`}
        headerNode={<NewAppointmentLink />}
      >
        <ErrorPage />
      </NoddiContainer>
    );
  }

  return (
    <NoddiContainer
      header={t`Referrals`}
      description={t`Refer a friend and you will both receive benefits that you can use on services from Noddi. You get the benefits on your user when the person you have invited makes their first booking with us.`}
      headerNode={<NewAppointmentLink />}
    >
      <Box
        sx={{
          py: '30px',
          pb: '30px',
          marginTop: '20px',
          marginBottom: '0px'
        }}
      >
        <CopyReferralLink referralCode={referralData.referralCode} />
        {referralData.referrals.length > 0 && <ReferredUsers referrals={referralData.referrals} />}
      </Box>
    </NoddiContainer>
  );
};

export default Referrals;
