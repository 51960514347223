import { Skeleton, useMediaQuery } from '@mui/material';
import useEmblaCarousel from 'embla-carousel-react';
import { URLKeys, getAllCachedDataFromUrlKey, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindowsByDateNew } from 'noddi-async/src/types';
import { DateFormats, addDays, differenceBetweenDates, format } from 'noddi-util';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsMobile } from '../../../hooks';
import { ApiErrorMessage } from '../../Elements/Errors';
import { NoddiCircularLoader } from '../../Elements/Loaders';
import BookingTimeWindowPickerDate, { OnSelectTimeWindowProps } from './BookingTimeWindowPickerDate';
import { SliderArrows, usePrevNextButtons } from './SliderArrows';
import { getUniqueTimeSlots, parseTimeWindowData } from './utils';

export const NUM_DAYS_TO_QUERY = 12;

interface BookingTimeWindowPickerProps extends OnSelectTimeWindowProps {
  serviceAreaId: number;
  salesItemIds: number[];
  initialFromDate: Date;
  selectedTimeWindowId: number | null | undefined;
  loadingNode?: ReactNode;
}

export const BookingTimeWindowPicker = ({
  serviceAreaId,
  salesItemIds,
  initialFromDate,
  loadingNode,
  selectedTimeWindowId,
  onAvailableSelect,
  onUnavailableSelect
}: BookingTimeWindowPickerProps) => {
  const isMobile = useIsMobile();
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const [fromDate, setFromDate] = useState<Date>(initialFromDate);
  const [selectedTimeWindow, setSelectedTimeWindow] = useState<{ date?: string; idx: number } | null>(null);
  const [slidesOnScreen, setSlidesOnScreen] = useState(6);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isMediumScreen = useMediaQuery('(max-width: 960px)');
  const isSmallScreen = useMediaQuery('(max-width: 560px)');

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.offsetWidth;

      let visibleSlides;

      if (isSmallScreen) {
        visibleSlides = 3;
      } else if (isMediumScreen) {
        // 17% is width of each slide on this breakpoint
        const slideSize = 0.17 * wrapperWidth;
        visibleSlides = Math.floor(wrapperWidth / slideSize);
      } else {
        // 140px is width of each slide on this breakpoint
        visibleSlides = Math.floor(wrapperWidth / 140);
      }

      setSlidesOnScreen(visibleSlides);
    }
  }, [wrapperRef?.current?.offsetWidth]);

  const { isPending: fetchingLastTimeWindowDate } = noddiAsync.useGet({
    type: URLKeys.getLatestCreatedBookingTimeWindowDate,
    queryConfig: { staleTime: Infinity }
  });

  const { error: timeWindowsError, isPending } = noddiAsync.useGet({
    type: URLKeys.getAvailableBookingTimeWindowsByDateNew,
    input: {
      serviceAreaId,
      salesItemIds,
      fromDate: format(fromDate, DateFormats.DASHED_DATE_ISO_8601),
      toDate: format(addDays(fromDate, NUM_DAYS_TO_QUERY), DateFormats.DASHED_DATE_ISO_8601)
    },
    queryConfig: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchInterval: 1000 * 60 * 4 // 4 minutes
    }
  });

  const allTimeWindowsByDate = getAllCachedDataFromUrlKey<AvailableBookingTimeWindowsByDateNew>({
    urlKey: URLKeys.getAvailableBookingTimeWindowsByDateNew
  });

  const flattenAllTimeWindowsByDate = allTimeWindowsByDate.reduce((acc, obj) => ({ ...acc, ...obj }), {});
  const uniqueTimeSlots = getUniqueTimeSlots(flattenAllTimeWindowsByDate);
  const parsedTimeWindowsData = useMemo(
    () => parseTimeWindowData(flattenAllTimeWindowsByDate),
    [flattenAllTimeWindowsByDate]
  );

  // Handle the selected time window logic
  useEffect(() => {
    if (!selectedTimeWindowId || !parsedTimeWindowsData.length) {
      return;
    }

    const selectedDateObj = parsedTimeWindowsData.find((date) =>
      date.timeWindows.some((timeWindow) => timeWindow?.id === selectedTimeWindowId)
    );

    if (selectedDateObj) {
      const timeWindowIdx = selectedDateObj.timeWindows.findIndex(
        (timeWindow) => timeWindow?.id === selectedTimeWindowId
      );
      setSelectedTimeWindow((prev) =>
        prev && prev.date === selectedDateObj.date && prev.idx === timeWindowIdx
          ? prev
          : { date: selectedDateObj.date, idx: timeWindowIdx }
      );
    }
  }, [selectedTimeWindowId, parsedTimeWindowsData]);

  useEffect(() => {
    // don't need to refetch if we're on first slide. We can assume that the data is there already
    if (currentSlideNumber === 0) {
      return;
    }

    // Get first date object being displayed
    const fistObject = parsedTimeWindowsData[currentSlideNumber * slidesOnScreen];
    if (!fistObject) {
      return;
    }

    // Get the last queried date object
    const lastObject = parsedTimeWindowsData[allTimeWindowsByDate.length - 1];
    if (!lastObject) {
      return;
    }

    // Get the number of days between the first date object and the last queried date object
    const daysDifference = differenceBetweenDates(lastObject.date, fistObject.date, 'days');

    // If the difference is greater than the number of days to query, then we don't need to query again
    if (daysDifference > NUM_DAYS_TO_QUERY) {
      return;
    }

    // Otherwise, we need to query again
    setFromDate(addDays(fromDate, NUM_DAYS_TO_QUERY) ?? new Date());
  }, [currentSlideNumber]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    slidesToScroll: slidesOnScreen,
    containScroll: 'keepSnaps',
    align: 'start'
  });

  const { prevBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  const onSlideChange = useCallback(() => {
    if (emblaApi) {
      setCurrentSlideNumber(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSlideChange);
    }
  }, [emblaApi, onSlideChange]);

  if ((isPending || fetchingLastTimeWindowDate) && allTimeWindowsByDate.length === 0) {
    return loadingNode || <NoddiCircularLoader />;
  }
  if (timeWindowsError) {
    return <ApiErrorMessage error={timeWindowsError} />;
  }

  const prevDisabledClass = prevBtnDisabled && isMobile ? 'flex justify-end' : '';

  return (
    <>
      <div className={`mb-4 ${prevDisabledClass}`}>
        <SliderArrows
          isMobile={isMobile}
          slider={emblaApi}
          prevBtnDisabled={prevBtnDisabled}
          onPrevButtonClick={onPrevButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      </div>

      <div className='z-50 flex overflow-auto overflow-x-hidden overflow-y-visible pb-3' ref={wrapperRef}>
        <div className='flex w-fit min-w-13 flex-col gap-7 pt-22 sm:gap-12'>
          {uniqueTimeSlots.map((slot) => (
            <div key={slot}>
              {isPending ? (
                <div className='flex h-11 min-h-11 w-[55px] items-center'>
                  <Skeleton variant='rounded' width={55} height={44} style={{ minHeight: '44px' }} />
                </div>
              ) : (
                <div className='flex h-13 min-h-13 w-[55px] items-center'>
                  <p>{slot}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='embla ml-1 w-full overflow-x-clip sm:ml-9' ref={emblaRef}>
          <div className='embla__container'>
            {parsedTimeWindowsData.map((timeWindowsByDate, index) => (
              <div key={index} className='embla__slide'>
                <BookingTimeWindowPickerDate
                  selectedTimeWindowDate={selectedTimeWindow?.date}
                  selectedTimeWindowId={selectedTimeWindowId}
                  timeWindowsByDate={timeWindowsByDate}
                  onAvailableSelect={onAvailableSelect}
                  onUnavailableSelect={onUnavailableSelect}
                  isLoading={isPending}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
