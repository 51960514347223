import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';

import { useCouponStore } from '../stores/CouponStore';

const useActivateCoupon = () => {
  const { coupons: activeCoupons, addCouponId, deSelectCoupon } = useCouponStore();
  const { currentUserGroupId } = useAuthContext();

  const { data: couponsFromApi } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCoupons,
    input: {
      id: currentUserGroupId as number,
      includeUsedOnBookingIds: []
    },
    queryConfig: {
      enabled: !!currentUserGroupId
    }
  });

  const deselectCouponsForSalesItem = (salesItemId: number) => {
    const relevantActiveCoupons = activeCoupons.filter((coupon) => coupon.validForSalesItemIds.includes(salesItemId));

    // handles the case where the user has selected a coupon for a sales item, but then deselected the sales item.
    for (const activeCoupon of relevantActiveCoupons) {
      if (activeCoupon.usedOnSalesItemId && activeCoupon.usedOnSalesItemId === salesItemId) {
        deSelectCoupon(activeCoupon.id);
      }
    }
  };

  const activateCouponForSalesItem = ({
    salesItemId,
    licensePlateNumber
  }: {
    salesItemId: number;
    licensePlateNumber: string;
  }) => {
    const relevantCoupons = couponsFromApi?.filter((coupon) => coupon.validForSalesItemIds.includes(salesItemId));

    if (!relevantCoupons) {
      return;
    }

    for (const coupon of relevantCoupons) {
      // ensure that the coupon in not already active for another sales item

      if (
        activeCoupons.some(
          (activeCoupon) => activeCoupon.id === coupon.id || activeCoupon.useOnLicensePlateNumber === licensePlateNumber
        )
      ) {
        continue;
      }

      addCouponId({
        id: coupon.id,
        amount: coupon.amount,
        amountPercentage: coupon.amountPercentage,
        namePublic: coupon.namePublic,
        validForSalesItemIds: coupon.validForSalesItemIds,
        usedOnSalesItemId: salesItemId,
        useOnLicensePlateNumber: licensePlateNumber
      });
    }
  };

  return { activateCouponForSalesItem, deselectCouponsForSalesItem, couponsFromApi };
};

export default useActivateCoupon;
