import { IconSvgProps } from './svgtype';

const Home = ({ color, ...props }: IconSvgProps) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke={color}
      strokeWidth={2}
      d='M5.333 16.218c0-2.441 0-3.662.554-4.674.554-1.011 1.566-1.64 3.59-2.895l2.133-1.324c2.139-1.328 3.208-1.992 4.39-1.992 1.182 0 2.251.664 4.39 1.992l2.134 1.324c2.023 1.256 3.035 1.884 3.589 2.895.554 1.012.554 2.233.554 4.674v1.622c0 4.16 0 6.241-1.25 7.534-1.25 1.293-3.261 1.293-7.284 1.293h-4.266c-4.023 0-6.034 0-7.284-1.293s-1.25-3.373-1.25-7.534v-1.622Z'
    />
    <path stroke={color} strokeLinecap='round' strokeWidth={2} d='M19.2 22.4h-6.4' />
  </svg>
);

export { Home };
