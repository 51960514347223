import { t } from '@lingui/macro';
import _ from 'lodash';

import { IconName } from '../../../atoms/types/icon';

type ItemProps = {
  name: string;
};

export const getServiceCategoryTranslations = ({ serviceCategoryName }: { serviceCategoryName: string }): ItemProps => {
  if (serviceCategoryName === 'Wheel change') {
    return {
      name: t`Wheel change`
    };
  } else if (serviceCategoryName === 'Car wash') {
    return {
      name: t`Car wash`
    };
  } else if (serviceCategoryName === 'Wheel storage') {
    return {
      name: t`Wheel storage`
    };
  } else {
    return {
      name: t`Unknown`
    };
  }
};

export const getServiceCategoryIcon = ({ serviceCategoryName }: { serviceCategoryName: string }): IconName => {
  if (serviceCategoryName === 'Wheel change') {
    return 'WheelChange';
  } else if (serviceCategoryName === 'Car wash') {
    return 'Drop';
  } else if (serviceCategoryName === 'Wheel storage') {
    return 'Home';
  } else {
    return 'Wheel';
  }
};

export const translateServicesLabels = (serviceFromApi: string): string => {
  switch (serviceFromApi) {
    case 'Dekkskift':
      return t`Wheel change`;
    case 'Dekkhotell':
      return t`Wheel storage`;
    case 'Innvendig vask':
      return t`Inside wash`;
    case 'Utvendig vask':
      return t`Outside wash`;
    case 'Klargjøring':
      return t`Preparation`;
    case 'Tilleggstjenester':
      return t`Additional services`;
    case 'Pakker':
      return t`Packages`;
    default:
      return serviceFromApi;
  }
};
