import { t } from '@lingui/macro';
import { NoddiAsyncError, mapErrorCodeToUiMessage } from 'noddi-ui';

import { useToast } from '../contexts/ToastProvider';

const useNoddiToast = () => {
  const { addToast } = useToast();

  const success = (heading: string, description?: string) => addToast('success', heading, description);
  const error = (heading: string, description?: string) => addToast('error', heading, description);
  const warning = (heading: string, description?: string) => addToast('warning', heading, description);
  const successfullyCreated = () => success(t`Successfully created!`);
  const successfullyUpdated = () => success(t`Successfully updated!`);
  const asyncError = (e: NoddiAsyncError) => error(mapErrorCodeToUiMessage({ error: e }));

  const noddiToast = { success, error, warning, successfullyCreated, successfullyUpdated, asyncError };

  return { noddiToast };
};

export { useNoddiToast };
