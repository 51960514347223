import { type VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { cn } from '../helpers/utilts';
import { NoddiIconButton } from './NoddiIconButton';

const toastVariants = cva('flex animate-toastInOut justify-start gap-5 rounded-lg border-l-8 !p-5 text-primary-black', {
  variants: {
    variant: {
      success: 'border-l-signal-success bg-signal-success50',
      error: 'border-l-signal-warning bg-signal-warning50',
      warning: 'border-l-signal-danger bg-signal-danger50'
    }
  },
  defaultVariants: {
    variant: 'success'
  }
});

export type NoddiFeedbackBoxProps = VariantProps<typeof toastVariants> & {
  heading: string;
  description?: string;
  onClose?: () => void;
};

const NoddiFeedbackBox = ({ variant, heading, description, onClose }: NoddiFeedbackBoxProps) => {
  const toastClassName = cn(toastVariants({ variant }));
  const hasDescription = !!description;
  return (
    <div className={toastClassName}>
      <div className='flex flex-col items-start gap-2'>
        <div className={cn(' text-5.5', hasDescription && 'font-bold')}>{heading}</div>
        {description && <div className='text-5.5'>{description}</div>}
      </div>

      {onClose && (
        <div
          className='relative -my-2 ml-auto cursor-pointer '
          onClick={() => {
            onClose();
          }}
        >
          <NoddiIconButton iconName='Cross' iconSize='large' variant='ghost' />
        </div>
      )}
    </div>
  );
};

interface ToastsWrapperProps {
  children: ReactNode;
}
const NoddiFeedbackWrapper = ({ children }: ToastsWrapperProps) => {
  return <div className='fixed right-5 top-5 z-[1000px]'>{children}</div>;
};
export { NoddiFeedbackBox, NoddiFeedbackWrapper };
